import React , {useState,useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination]);

const InstaPost = ({   instaUrl, instaImageUrl, }) => {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Hàm xử lý thay đổi kích thước cửa sổ
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    // Đăng ký sự kiện xử lý thay đổi kích thước cửa sổ
    window.addEventListener('resize', handleResize);

    // Hủy đăng ký sự kiện khi component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Sử dụng mảng rỗng để chỉ chạy useEffect sau lần render đầu tiên

  return (
    <React.Fragment>
      {instaUrl && instaUrl?.length > 0 && instaImageUrl?.length > 0 && (
        <section className="section  section-product-list homepage">
          <div className="container-fluid">
            <h3 className="title-head">{`Stay Social!`}</h3>

            {
              <div className="tab-content section-content">
                <Swiper
                  id="sliderHomeRec"
                  tag="section"
                  speed={1000}
                  autoplay={{
                    delay: 3001,
                    disableOnInteraction: false,
                  }}
                  style={{marginLeft:0, marginRight:0, width : "100%"}}
                  spaceBetween={0}
                  slidesPerView={windowWidth<1550?3:(windowWidth<1750?4:5)}
                  loop
                  initialSlide={0}
                >
                  {(instaImageUrl
                    ? instaImageUrl.length <= 4
                      ? Array(Math.ceil(5 / instaImageUrl.length))
                          .fill(instaImageUrl)
                          .flat()
                      : instaImageUrl
                    : []
                  ).map((ele, index) => {
                    return (
                      <SwiperSlide key={index} tag="li" className="recSlider">
  <div
    className="insta_mar"
    style={{
      cursor: "pointer",
      borderRadius: "10px",
      overflow: "hidden", // Tránh hiển thị phần bị vượt ra nếu có
    }}
  >
    <img
      style={{
        cursor: "pointer",
        padding: 0,
        borderRadius: "10px",
        width: "100%", // 100% chiều rộng của div cha
        height: "100%", // 100% chiều cao của div cha
        objectFit: "cover", // Đảm bảo ảnh không bị méo khi hiển thị
        transition: "transform 0.3s",
      }}
      onClick={() => window.open(instaUrl[index], '_blank')}
      className="recSliderImg"
      alt= "img"
      src={ele ? `${cloudFrontURL}/${ele}` : ""}
    />
  </div>
</SwiperSlide>

                    );
                  })}
                </Swiper>
              </div>
            }
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default InstaPost;
