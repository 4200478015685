import React, { useEffect, useState } from "react";
import Api from "../components/api/apis";
import { Icon } from '@iconify/react';

function Stores({title}) {
  document.title = title
  const [storeList, setStoreList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityListFilter, setCityListFilter] = useState([]);
  const [mapSrc, setMapSrc] = useState('')
  const [selectChange, setSelectChange] = useState('0')

  // Biểu thức chính quy kiểm tra định dạng iframe
  const iframeRegex = /<iframe[^>]*>[^<]*<\/iframe>/;

  // Kiểm tra nếu coordinate khớp với định dạng iframe
  const isIframe = iframeRegex.test(mapSrc);

  useEffect(() => {
    let set = true;

    let cList = [];

    const fetchDataCt = async () => {
      try {
        cList = [...(await Api.getCityList())];
        if (set) {
          setCityList(cList);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataCt();

    let storeListTemp = [];

    const fetchDateStore = async () => {
      try {
        storeListTemp = [...(await Api.getStoreList())];
        if (set) {
          setStoreList(storeListTemp);
          setMapSrc(storeListTemp[0]?.coordinate??'')
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDateStore();


    return () => {
      set = false;
    };
  }, []);

  useEffect(() => {
    const processedList = cityList.map(city => {
      city.name = city.name.replace("Tỉnh ", "").replace("Thành phố ", "").trim();
      return city;
    });
    // Lọc các object có code = 1 hoặc 79
    const filteredList = processedList.filter(city => city.code === 1 || city.code === 79);

    // Lọc các object có code khác 1 và 79 và sắp xếp theo trường name
    const otherCities = processedList.filter(city => city.code !== 1 && city.code !== 79);
    otherCities.sort((a, b) => a.name.localeCompare(b.name));

    // Kết hợp lại thành một mảng mới
    const finalList = [...filteredList, ...otherCities];
    setCityListFilter(finalList)
  }, [cityList]);

  return (<React.Fragment>
    <div className="storesPage" style={{marginBottom: '70px'}}>
          <div className="container">
            <div className="wrapper-row pd-page">
              <section id="page-wrapper">
                <div id="storemap-page" style={{ marginBottom: "60px" }}>
                  <h1
                    style={{
                      marginTop: "32px",
                      marginBottom: "24px",
                      fontSize: "2.5rem",
                    }}
                    className="text-center"
                  >
                    Hệ thống cửa hàng
                  </h1>
                </div>
                <div className="content-page">
                  <div className="map-direction row">
                    <div className="col-lg-8 col-md-7 col-xs-12 col-sm-12">
                      <div id="map">
                        {isIframe&&<div
                            className="map_container"
                            style={{
                                width:"100%",
                                height:"100%"
                            }}
                        dangerouslySetInnerHTML={{ __html: mapSrc }}></div>}
                        
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-5 col-xs-12 col-sm-12">
                      <div className="list-store">
                        <div className='title-store'>
                            <span>Chọn khu vực cửa hàng: </span>
                        </div>
                        <div className='filter_x'>
                            <select className='filter_value_warrd'
                                style={{padding: '6px 12px', width: '96%', marginBottom: '8px'}}
                                onChange = {(e) => {setSelectChange(e.target.value) }}
                            >
                                <option id = 'allWard' value = '0'>Chọn tỉnh/ thành phố</option>
                                {cityListFilter && cityListFilter.map((ele, index) => {
                                    return (
                                        <option key = {index} id = {`ward${index}`} value = {ele._id}>
                                            {ele.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <ul className='no-bullets'>
                            {storeList.map((ele, index) => {
                                return(
                                    <div key = {index}>
                                        {(ele.city === selectChange || selectChange === '0') && 
                                        <li onClick={() => {setMapSrc(ele.coordinate)}}>
                                            <p>
                                                <b style={{textTransform: 'uppercase'}}>{ele.name}</b>
                                            </p>
                                            <p>
                                                <Icon icon="bx:map"style={{marginRight: '5px'}} />
                                                {ele.address}
                                            </p>

                                            <p>
                                                <Icon icon="akar-icons:phone"style={{marginRight: '5px'}} />
                                                {ele.phone}
                                            </p>
                                        </li>}
                                    </div>
                                )
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

  </React.Fragment>);
}

export default Stores;
