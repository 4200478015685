import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Autoplay } from "swiper";
import helper from "../../helper";

SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination]);

const NewAndPopular = ({ newAndPopular, discountList }) => {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [state, setState] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Hàm xử lý thay đổi kích thước cửa sổ
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Đăng ký sự kiện xử lý thay đổi kích thước cửa sổ
    window.addEventListener("resize", handleResize);

    // Hủy đăng ký sự kiện khi component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Sử dụng mảng rỗng để chỉ chạy useEffect sau lần render đầu tiên

  return (
    <React.Fragment>
      {newAndPopular?.new.length > 0 && newAndPopular?.popular.length > 0 && (
        <section className="section notForMobilex section-product-list homepage">
          <div className="container-fluid">
            <h3 className="title-head">Shop Products</h3>
            <div className="section-head">
              <div className="content-asset">
                <ul className="nav nav-tabs" role="tablist">
                  <li
                    onClick={() => {
                      setState(1);
                    }}
                  >
                    {" "}
                    <a
                      className={`home-products-tab white-button ${
                        state === 1 ? `active show` : ``
                      }`}
                      data-toggle="tab"
                      data-products="home-products-m"
                      href="#home-products-m"
                    >
                      Yêu thích nhất
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setState(2);
                    }}
                  >
                    {" "}
                    <a
                      className={`home-products-tab white-button ${
                        state === 2 ? `active show` : ``
                      }`}
                      data-products="home-products-w"
                      data-toggle="tab"
                      href="#home-products-w"
                    >
                      Mới nhất
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {
              (newAndPopular?.popular ?? []).length>0 && (newAndPopular?.new ?? []).length>0 && <div className="tab-content section-content">
                <Swiper
                  id="sliderHomeRec"
                  tag="section"
                  speed={1000}
                  autoplay={{
                    delay: 4001,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={0}
                  slidesPerView={windowWidth<500?2:(windowWidth<768?3:(windowWidth<1450?4:5))}
                  // pagination = {{
                  //     clickable: true,
                  // }}
                  loop
                  initialSlide={1}
                  // style={{
                  //     minHeight: '400px',
                  //     maxWidth: '80%'
                  // }}
                >
                  {(state === 1
                    ? newAndPopular?.popular
                    : newAndPopular?.new
                  ).map((ele, index) => {
                    return (
                      <SwiperSlide key={index} tag="li" className="recSlider">
                        <div
                          className="sliderContainer"
                          style={{ cursor: "auto" }}
                        >
                          <div className="imgRecContainer">
                            <a href={`/p/${ele.SEOLink}`}>
                              <img
                                className="recSliderImg"
                                alt="img"
                                src={
                                  ele && ele.img
                                    ? ele.img[0]
                                      ? `${cloudFrontURL}/${ele.img[0]}`
                                      : ""
                                    : ""
                                }
                              />
                            </a>
                          </div>
                          <div
                            className="RecproductInfo"
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            {/* <div className='rec-brands'>Labels here</div> */}

                            <a
                              href={`/p/${ele.SEOLink}`}
                              title={ele && ele.name ? ele.name : ""}
                            >
                              <h4 className="oneLineText">
                                {(ele.name ?? "").toUpperCase()}
                              </h4>
                            </a>
                            {discountList[ele.sku] ? (
                              <div>
                                <div
                                  className="truePrice"
                                  style={{ color: "red" }}
                                >
                                  {helper.moneyFormat(
                                    discountList[ele.sku] ?? 0
                                  )}
                                </div>
                                <div
                                  style={{
                                    textDecoration: "line-through",
                                    marginTop: "5px",
                                  }}
                                >
                                  {helper.moneyFormat(ele.price ?? 0)}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="truePrice"
                                style={{ color: "red" }}
                              >
                                {helper.moneyFormat(ele.price ?? 0)}
                              </div>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            }
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default NewAndPopular;
