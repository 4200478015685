import React from "react";
import AT1 from "../../assets/brand/about-us-new.jpg";
import AT2 from "../../assets/brand/about-us-new-1.webp";
import AT3 from "../../assets/brand/about-us-new-2.jpg";
import AT4 from "../../assets/brand/about-us-new-4.webp";

function AmericanTourister({ title }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  document.title = title;
  return (
    // <React.Fragment>
    //   <div id="samsoniteBrand">
    //     <div id="pageDefault">
    //       <picture>
    //         <img
    //           alt=""
    //           src={AT1}
    //           width="100%"
    //         />
    //       </picture>
    //     </div>
    //     <div
    //       className = "pageAbout-us page-layout"
    //       style={{marginTop: "60px", marginBottom: "60px", fontSize: "15px", lineHeight: "1.6"}}
    //     >
    //       <div className = "container">
    //         <div
    //           className = "row wrapper-row pd-page"
    //           style={{justifyContent: "center"}}
    //         >
    //           <div className = "col-md-8 col-sm-12 col-12">
    //             <div className = "page-wrapper">
    //               <div className = "wrapbox-content-page">
    //                 <div className = "content-page ">
    //                     <h2>
    //                         GIÁ TRỊ THƯƠNG HIỆU
    //                     </h2>
    //                     <p>
    //                         Thương hiệu American Tourister là tổng hợp của nhiều yếu tố du lịch bao gồm cả phong cách và sự tự tin. Vì vậy, American Tourister luôn cam kết rằng tất cả sản phẩm đều đảm bảo yếu tố bền, nhẹ, thời trang, đa chức năng và hiệu quả về chi phí. Đó là lý do American Tourister là thương hiệu đáng tin cậy trên toàn thế giới trong hơn 80 năm và bây giờ đã có mặt ở hơn 118 quốc gia!
    //                     </p>
    //                     <p>&nbsp;</p>
    //                     <h2>AMERICANTOURISTER & CHẶNG ĐƯỜNG ĐÃ QUA</h2>
    //                     <div>
    //                         <p style = {{textAlign: "center"}} >
    //                             <img  id = "americanTouristerImg1" alt = "" style={{margin: "10px auto"}} src={AT2}/>
    //                         </p>
    //                         <p><strong>Năm 1945,</strong> du lịch bằng hàng không bắt đầu nở rộ, nhu cầu về một chiếc vali có trọng lượng nhẹ để đáp ứng cho việc di chuyển bằng đường hàng không trở nên cao hơn. American Tourister đã ngay lập tức cho ra đời chiếc cặp xách tay nhẹ hơn, dễ dàng đóng gói hơn. Điều này đánh dấu một bước khởi đầu cho việc đáp ứng nhanh và tối ưu nhu cầu của du khách.</p>
    //                         <p><strong>Những năm 50,</strong> American Tourister tìm ra công ty thử nghiệm vật liệu mới để sản xuất mẫu Vali cứng đầu tiên trên thế giới.</p>
    //                         <p><strong>Những năm 60,</strong> du lịch quốc tế tăng vọt, American Tourister là công ty đầu tiên trang bị túi xách cho phi hành đoàn trong các chuyến bay thử nghiệm của các hãng hàng không lớn.</p>
    //                         <p><strong>Những năm 80 và 90,</strong> dòng vali vải ra đời với nhiều tính năng đáp ứng nhu cầu của thời đại. Đồng thời, độ bền của chiếc vali nhựa được kiểm chứng qua hình tượng chú khỉ Gorilla giẫm lên chiếc vali. Những bước phát triển mới về tính năng đã vượt qua sự mong đợi của thị trường và vượt qua cả những nhà phê bình khó tính nhất một cách đầy thuyết phục.</p>
    //                         <p><strong>Năm 1993,</strong> American Tourister chính thức gia nhập vào gia đình Samsonite, được thừa hưởng trọn vẹn những tinh hoa công nghệ tiên tiến nhất. American Tourister cùng Samsonite hoàn thiện một lí tưởng chung, đó là mang đến sự tối ưu và tiện lợi nhất cho người du lịch.</p>
    //                         <p>&nbsp;</p>
    //                         <h2>AMERICAN TOURISTER - CHIẾC VALI VỚI GIÁ 1 ĐÔ LA</h2>
    //                         <div>&nbsp;</div>

    //                     </div>
    //                     <div className = "row" id = "americanTouristerRow">
    //                         <div className = "col-md-4">
    //                             <img alt = "" id = "americanTouristerImg2" style={{padding: "10px"}} src={ATlogo}/>
    //                         </div>
    //                         <div className = "col-md-8">
    //                             <p>Câu chuyện bắt đầu vào năm 1933, khi Sol Koffler quyết định đặt toàn bộ số tiền tiết kiệm của mình để bắt đầu một công ty Vali tại Providence, Đảo Rhode, Hoa Kỳ. Ước mơ của ông là "Một va li có thể bán với giá 1 USD".
    //                             </p>
    //                         </div>
    //                     </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </React.Fragment>
    <React.Fragment>
      <div className="pageAbout-us page-layout">
        <div className="container">
          <div style={{ height: "10px" }}></div>
          <div className="row wrapper-row pd-page">
            <div className="page-info">
              <a href="/">
                <img src={AT1} alt="mainImg"></img>
              </a>
              <div className="container">
                <section className="brand-page clearfix">
                  <h1 className="page-title text-center">American Tourister</h1>
                  <div className="brand-info">
                    <div className="location">
                      CHIẾC VALI KÉO THƯƠNG HIỆU MỸ VỚI GIÁ 1 ĐÔ LA
                    </div>
                  </div>
                  <div className="content">
                    <p style={{ textAlign: "justify" }}>
                      Câu chuyện bắt đầu vào năm 1933, khi Sol Koffler quyết
                      định đặt toàn bộ số tiền tiết kiệm của mình để bắt đầu một
                      công ty <strong>vali</strong> tại Providence, Đảo Rhode,
                      Hoa Kỳ. Ước mơ của ông là "Một va li có thể bán với giá 1
                      USD".
                    </p>
                    <h4
                      style={{
                        fontSize: "24px",
                        marginTop: "30px",
                        fontWeight: "700",
                      }}
                    >
                      VALI KÉO AMERICAN TOURISTER &amp; CHẶNG ĐƯỜNG ĐÃ QUA
                    </h4>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <p style={{ textAlign: "justify" }}>
                          - <em>Năm 1945</em> du lịch bằng hàng không bắt đầu nở
                          rộ, một chiếc <strong>vali </strong>có trọng lượng nhẹ
                          hơn để đáp ứng cho việc di chuyển đường hàng không trở
                          thành nhu cầu cao.{" "}
                          <strong>American Tourister </strong>đã ngay lập tức
                          cho ra đời chiếc <strong>cặp xách</strong> tay nhẹ
                          hơn, dễ dàng đóng gói hơn. Đánh dấu một bước khởi đầu
                          cho việc đáp ứng nhanh và tối ưu nhu cầu của du
                          khách.&nbsp;
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          - <em>Những năm 50</em>, tìm ra công ty thử nghiệm vật
                          liệu mới để tạo ra mẫu <strong>Vali</strong> cứng đầu
                          tiên trên thế giới.&nbsp;
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          - <em>Những năm 60</em>, Du lịch quốc tế tăng vọt,{" "}
                          <strong>American Tourister</strong> là công ty đầu
                          tiên trang bị<strong> túi xách</strong> cho phi hành
                          đoàn trong các chuyến bay thử nghiệm của các hãng hàng
                          không lớn.&nbsp;
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          - <em>Những năm 80 và 90</em>,{" "}
                          <strong>Dòng vali vải</strong> hoàn thiện ra đời với
                          nhiều tính năng đáp ứng nhu cầu của thời đại. Đồng
                          thời, độ bền của chiếc <strong>Vali nhựa </strong>được
                          kiểm chứng qua hình tượng chú khỉ Gorilla dẫm lên
                          chiếc <strong>vali</strong>. Những bước phát triển mới
                          về tính năng đã vượt qua sự mong đợi của thị trường và
                          vượt qua cả những nhà phê bình khó tính nhất đầy
                          thuyết phục.&nbsp;
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          - <em>Năm 1993</em>,{" "}
                          <strong>American Tourister</strong> chính thức gia
                          nhập vào gia đình <strong>Samsonite</strong>, được
                          thừa hưởng trọn vẹn những tinh hoa, những công nghệ
                          tiên tiến nhất. Cùng với <strong>Samsonite </strong>
                          hoàn thiện một lí tưởng chung đó là mang đến sự tối ưu
                          và tiện lợi nhất cho người du lịch.
                        </p>
                      </div>
                      <div className="col-12 col-md-3">
                        <img src={AT2} alt="Vali kéo Maxivo" />
                      </div>
                      <div className="col-12 col-md-3">
                        <img src={AT3} alt="Vali kéo Squasem" />
                      </div>
                    </div>
                    <a href="/" target="_blank">
                      <img src={AT4} alt="Rollio Collection" width="100%" />
                    </a>
                    <div className="row" style={{ marginTop: "30px" }}>
                      <div className="col-12 col-md-6">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/vdKS-XPY9XU?enablejsapi=1&amp;origin=https%3A%2F%2Fhol.com.vn"
                          frameborder="0"
                          gesture="media"
                          allow="encrypted-media"
                          data-gtm-yt-inspected-12137313_73="true"
                          id="732114645"
                          data-gtm-yt-inspected-6="true"
                          title="Vali American Tourister Đi Đến Đâu Vui Đến Đó - Find Fun Everywhere With American Tourister"
                        ></iframe>
                      </div>
                      <div className="col-12 col-md-6">
                        <h4
                          style={{
                            fontSize: "24px",
                            marginTop: "30px",
                            fontWeight: "700",
                          }}
                        >
                          GIÁ TRỊ THƯƠNG HIỆU AMERICAN TOURISTER
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Thương hiệu <strong>vali kéo</strong>{" "}
                          <strong>American Tourister </strong>là sự tổ hợp của
                          rất nhiều yếu tố du lịch bao gồm cả phong cách và sự
                          tự tin. Vì vậy, <strong>American Tourister</strong>{" "}
                          luôn cam kết rằng tất cả các sản phẩm đều đảm bảo yếu
                          tố bền, nhẹ, thời trang, đa chức năng và hiệu quả về
                          chi phí. Đó là lý do tại sao{" "}
                          <strong>American Tourister </strong>là thương hiệu
                          đáng tin cậy trên toàn thế giới trong hơn{" "}
                          <strong>91 năm</strong> và bây giờ đã có mặt ở hơn{" "}
                          <strong>118 quốc gia!</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AmericanTourister;
