import React from "react";
import "../../styles/Support/support.css";

function OrderMethod({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultOrder">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor:
              "black",
          }}
        >
          <h1>Phương thức đặt hàng</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      Đặt hàng online tại website:{" "}
                      <strong>americantourister.vn</strong>
                      <br />
                      Đặt hàng thông qua tổng đài miễn phí:{" "}
                      <strong>1800 6360</strong>
                      <br />
                      Đặt hàng trực tiếp trên Facebook:{" "}
                      <a href="https://vi-vn.facebook.com/Samsonite.Vietnam">
                        Samsonite Vietnam
                      </a>
                      ,{" "}
                      <a href="https://www.facebook.com/Houseofsamsonite.vn/">
                        House Of Samsonite Vietnam
                      </a>
                      ,{" "}
                      <a href="https://www.facebook.com/AmericanTourister.Vietnam/">
                        American Tourister Vietnam
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrderMethod;
