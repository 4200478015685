import React from "react";
import "../../styles/Support/support.css";

function TermsConditions({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultTerms">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor:
              "black",
          }}
        >
          <h1>Điều khoản và điều kiện</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <h3>QUY ĐỊNH VỀ VIỆC SỬ DỤNG TRANG WEB</h3>
                      <p>
                        Thông qua Trang Web, chúng tôi đồng ý và tôn trọng việc
                        Khách Hàng tìm kiếm, tham khảo, hiển thị nội dung thông
                        tin có trên Trang Web trên màn hình máy tính, việc mua
                        sắm trực tuyến của Khách Hàng tại mọi thời điểm với điều
                        kiện là các hoạt động đó của Quý Khách Hàng phải phù hợp
                        với Điều Khoản Và Điều Kiện và phù hợp với quy định của
                        pháp luật Việt Nam.
                      </p>
                      <p>
                        Quý Khách vui lòng không sử dụng Trang Web của chúng tôi
                        cho bất kỳ mục đích nào dưới đây:
                      </p>
                      <ul>
                        <li>
                          Đưa ra những nhận xét, đánh giá có ý xúc phạm, quấy
                          rối, bôi nhọ, đe dọa, làm phiền hoặc có bất cứ hành vi
                          nào thiếu văn hóa đối với người khác.
                        </li>
                        <li>
                          Đưa ra những nhận xét mang tính chính trị (ví dụ như
                          tuyên truyền, chống phá, xuyên tạc Nhà nước Cộng hòa
                          xã hội chủ nghĩa Việt Nam) hoặc mang tính kỳ thị tôn
                          giáo, giới tính, sắc tộc, giai cấp.
                        </li>
                        <li>
                          Tuyên truyền văn hoá phẩm đồi truỵ, có nội dung phản
                          cảm hoặc trái quy định pháp luật.
                        </li>
                        <li>
                          Phổ biến các tài liệu, khuyến khích người khác thực
                          hiện các hành vi vi phạm pháp luật.
                        </li>
                        <li>
                          Can thiệp vào quyền sử dụng hợp pháp Tài Khoản của
                          Khách Hàng khác tại Trang Web.
                        </li>
                        <li>
                          Thực hiện bất kỳ hành vi phạm pháp luật nào liên quan
                          đến việc sử dụng internet, mạng viễn thông công cộng
                          và/hoặc quyền sở hữu trí tuệ của bên thứ ba nói riêng
                          và/hoặc bất kỳ hành vi vi phạm pháp luật nào khác.
                        </li>
                        <li>
                          Thực hiện các hành vi hoặc tạo ra thông tin giả mạo,
                          cố ý tạo sự nhầm lẫn giữa mình với Khách Hàng khác
                          hoặc mạo danh Ban quản trị của Trang Web.
                        </li>
                        <li>
                          Can thiệp, làm gián đoạn đường truyền internet hoặc
                          các trang thông tin, trang web khác kết nối với Trang
                          Web.
                        </li>
                        <li>
                          Thực hiện bất kỳ hành vi nào bị cấm theo quy định của
                          pháp luật Việt Nam.
                        </li>
                        <li>
                          Nếu phát hiện bất kỳ hành vi nào nêu trên, chúng tôi
                          có quyền ngay lập tức khoá hoặc huỷ bỏ Tài Khoản sử
                          dụng của Quý Khách và áp dụng các biện pháp cần thiết
                          khác theo quy định của pháp luật Việt Nam.
                        </li>
                        <li>
                          Quý Khách Hàng sử dụng các trang thông tin điện tử kết
                          nối với Trang Web của chúng tôi (nếu có) trên các mạng
                          xã hội (như Facebook, Twitter v.v...) phù hợp với các
                          điều khoản và điều kiện sử dụng của chúng tôi tại các
                          trang thông tin điện tử, mạng xã hội đó và pháp luật
                          Việt Nam.
                        </li>
                      </ul>
                      <h3>GIÁ CẢ, TỪ CHỐI ĐƠN HÀNG</h3>
                      <p>
                        Chúng tôi cam kết chỉ hiển thị các hình ảnh thật của Sản
                        Phẩm/ Hàng Hóa và cố gắng cung cấp Hàng Hoá/ Sản Phẩm
                        chính hãng cho tất cả các Khách Hàng với chất lượng và
                        giá cả hợp lý nhất.
                      </p>
                      <p>
                        Tại thời điểm hoàn tất việc đặt hàng, Quý Khách sẽ được
                        thông báo trên màn hình máy tính về tất cả chi phí mà
                        Quý Khách phải thanh toán (bao gồm giá bán sản phẩm,
                        thuế VAT, phí đóng gói và giao hàng và/ hoặc các phí
                        khác, nếu có).
                      </p>
                      <p>
                        Tại Trang Web, phần giá màu xám là mức giá bán của Sản
                        Phẩm/ Hàng Hóa do nhà cung cấp/ nhà phân phối khuyến
                        nghị. Phần giá có màu đỏ là giá bán của Sản Phẩm/ Hàng
                        Hóa trên Trang Web.
                      </p>
                      <p>
                        Chúng tôi luôn cố gắng để đảm bảo tính chính xác của
                        việc hiển thị giá cả trên Trang Web. Tuy nhiên, giá trị
                        Đơn Hàng của Quý Khách Hàng sẽ được chúng tôi xác nhận
                        lại như là một phần của thủ tục chấp nhận Đơn Hàng. Nếu
                        giá trị của Đơn Hàng thay đổi trước khi chúng tôi chấp
                        nhận Đơn Hàng của Quý Khách Hàng, chúng tôi sẽ liên lạc
                        với Quý Khách Hàng để xác nhận rằng Quý Khách Hàng vẫn
                        muốn tiếp tục mua hàng ở mức giá đã thay đổi.
                      </p>
                      <p>
                        Trong trường hợp thật sự cần thiết (như trường hợp Bất
                        Khả Kháng, sản phẩm không sẵn có vì bất kỳ lý do gì hoặc
                        phù hợp với quy định của pháp luật), chúng tôi có quyền
                        từ chối Đơn Hàng của Quý Khách Hàng.
                      </p>
                      <h3>THANH TOÁN</h3>
                      <p>
                        Chúng tôi hỗ trợ các phương thức thanh toán linh hoạt và
                        an toàn cho tất cả các Khách Hàng mua sắm tại Trang Web
                        bằng các hình thức sau:
                      </p>
                      <p>
                        <strong>
                          Thanh toán bằng tiền mặt khi nhận hàng (COD):
                        </strong>
                      </p>
                      <p>
                        Sau khi Quý Khách kiểm tra và nhận Hàng Hóa của Đơn Hàng
                        đã đặt, Quý Khách Hàng sẽ thanh toán tiền hàng đã mua
                        bằng tiền mặt cho chúng tôi. Chúng tôi chỉ chấp nhận
                        thanh toán bằng tiền mặt khi nhận hàng đối với các Đơn
                        Hàng có tổng giá trị dưới mười (10) triệu đồng. Các Đơn
                        Hàng có giá trị từ mười (10) triệu đồng sẽ phải thanh
                        toán trước bằng hình thức chuyển khoản hoặc thẻ ngân
                        hàng (Thẻ ATM/Thẻ Tín dụng/Thẻ Ghi nợ).
                      </p>
                      <p>
                        <em>
                          <strong>(*) Lưu ý:</strong>
                        </em>{" "}
                        &nbsp;Không hỗ trợ phương thức Thanh toán bằng tiền mặt
                        khi nhận hàng (COD) đối với đơn hàng có sản phẩm bắt
                        buộc lưu chuyển trực tiếp từ cửa hàng để giao hàng, hoặc
                        đơn hàng có từ 3 kiện hàng cùng size. Quý khách vui lòng
                        chọn hình thức thanh toán trước bằng hình thức chuyển
                        khoản. Nhân viên hỗ trợ đơn hàng sẽ liên hệ xác nhận
                        thông tin đơn hàng cho quý khách.
                      </p>
                      <p>
                        <strong>Thanh toán bẳng thẻ ATM nội địa:</strong>
                      </p>
                      <p>
                        Trong quá trình đặt hàng, quý khách chọn hình thức Thanh
                        toán bằng thẻ ATM nội địa, quý khách sẽ được chuyển đến
                        Cổng thanh toán trực tuyến bảo mật Onepay để thanh toán.
                        Thẻ ATM của quý khách bắt buộc phải sử dụng Internet
                        Banking để thực hiện phương thức thanh toán này.
                      </p>
                      <p>
                        <strong>Thanh toán bằng Visa/Master card:</strong>
                      </p>
                      <p>
                        Trong quá trình đặt hàng, quý khách chọn hình thức Thanh
                        toán bằng thẻ Visa/Master card quý khách sẽ được chuyển
                        đến Cổng thanh toán trực tuyến bảo mật Onepay để thanh
                        toán.
                      </p>
                      <p>
                        <strong>Thanh toán bằng hình thức chuyển khoản:</strong>
                      </p>
                      <p>
                        Trong quá trình đặt hàng, quý khách chọn hình thức thanh
                        toán chuyển khoản trong phần Phương thức thanh toán.
                      </p>
                      <p>
                        Điều kiện để chọn hình thức thanh toán chuyển khoản là
                        thẻ ATM của quý khách đã được đăng ký sử dụng internet
                        banking.
                      </p>
                      <p>
                        <em>
                          <strong>
                            * Quý&nbsp;khách chuyển khoản vào tài khoản:
                          </strong>
                        </em>
                      </p>
                      <blockquote>
                        <p>- TÊN TÀI KHOẢN: CÔNG TY TNHH BANYAN VIỆT NAM</p>
                        <p>- SỐ TÀI KHOẢN: 310.205.6497</p>
                        <p>
                          - NGÂN HÀNG TMCP ĐẦU TƯ VÀ PHÁT TRIỂN VIỆT NAM (BIDV)
                          - CHI NHÁNH: HỒ CHÍ MINH
                        </p>
                      </blockquote>
                      <p>

                      Hoặc
                      </p>
                      
                      <blockquote>
                        <p>- TÊN TÀI KHOẢN: CÔNG TY TNHH BANYAN VIỆT NAM</p>
                        <p>- SỐ TÀI KHOẢN: 0071001228757</p>
                        <p>
                          -  NGÂN HÀNG TMCP NGOẠI THƯƠNG VIỆT NAM (VIETCOMBANK) 
                          -  CHI NHÁNH: HỒ CHÍ MINH
                        </p>
                      </blockquote>
                      <p>
                        <em>* Cú pháp ghi chú:</em>{" "}
                        <strong>AT_[Mã đơn hàng]</strong>
                      </p>
                      <p>
                        Ví dụ: Quý khách thanh toán chuyển khoản cho đơn hàng
                        19xxxxxxx đặt hàng trên website americantourister.vn,
                        cú pháp ghi chú khi chuyển khoản là{" "}
                        <strong>AT_19xxxxxxx</strong>
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TermsConditions;
