import React, { useEffect, useState } from "react";
import "../../styles/Support/support.css";
import Api from "../../components/api/apis";

function Posts({ title }) {
  document.title = title;

  const [newsList, setNewsList] = useState([]);
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;

  useEffect(() => {
    let fetchData = async () => {
      try {
        const newsRes = await Api.getNews();
        setNewsList(newsRes.newsList || []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {};
  }, []);

  return (
    <React.Fragment>
      <div className="container discover posts">
        <div className="discover-label">DISCOVER THE LATEST</div>
        <div className="discover-content">
          {newsList &&
            newsList.length > 0 &&
            newsList.map((ele, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="discover-card-box">
                    <a
                      className="discover-card"
                      href={`/post/${ele?.seoLink ?? ""}`}
                      title={`${ele?.name ?? ""}`}
                    >
                      <div className="card-image">
                        <img
                          src={
                            ele.mainImage
                              ? `${cloudFrontURL}/${ele.mainImage}`
                              : ""
                          }
                          alt="post"
                          title={`${ele?.name ?? ""}`}
                        />
                      </div>
                      <div className="card-text mt-3 text-center">
                        <p
                          className="card-focus-text font-weight-bold ellipsis"
                          style={{ WebkitBoxOrient: "vertical" }}
                        >
                          {`${ele?.name ?? ""}`}
                        </p>
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Posts;
