import React, { useEffect } from "react";

const PreloadImages = ({ imageUrls, dependency  }) => {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  useEffect(() => {
    // Tạo và thêm các thẻ <link> vào phần <head> của trang HTML
    Array.isArray(imageUrls) && imageUrls.length > 0 && imageUrls.forEach((url) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = cloudFrontURL+'/'+url;
      document.head.appendChild(link);
    });

    // Xóa các thẻ <link> sau khi đã tải trước xong để tránh gây rối trong DOM
    return () => {
      document.head
        .querySelectorAll('link[rel="preload"][as="image"]')
        .forEach((link) => {
          document.head.removeChild(link);
        });
    };
  }, [imageUrls, dependency ]);

  return null; // Không render bất kỳ nội dung nào
};

export default PreloadImages;
