import React, { useEffect, useState } from "react";
import helper from "../../helper";
import Helper from "../../helper/helper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Autoplay } from "swiper";
import PreloadImages from "../../utils/PreloadImages";

SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination]);

function ProductContainer({
  product,
  setCartChange,
  initColor,
  initSize,
  discountList,
  id,
  cartEvent,
  setCartEvent,
}) {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [color, setColor] = useState(Number(initColor));
  const [size, setSize] = useState(Number(initSize));
  const [curProduct, setCurProduct] = useState({});
  const [availableColor, setAvailableColor] = useState([]);
  const [indexColor, setIndexColor] = useState([]);
  const getCategory = () => {
    if (!product) {
      return [];
    } else {
      if (product.sizeDiff === false) {
        switch (product.productType) {
          case 1:
            return ["Vali"];

          case 2:
            return ["Balo"];

          case 3:
            return ["Túi xách"];

          case 4:
            return ["Phụ kiện"];

          default:
            return [];
        }
      } else if (product.sizeDiff === true) {
        let rs = product.sizeArray
          .map((ele, index) => (ele ? product.sizeArrayLen[index] ?? "" : ele))
          .filter((value) => value !== false);

        return rs;
      }
    }
  };

  useEffect(() => {
    let a = availableColor.reduce((count, currentValue) => {
      return count + (currentValue === 1 ? 1 : 0);
    }, 0);
  }, [indexColor, availableColor]);

  useEffect(() => {
    let arr = new Array(product.colorArrayUrl.length).fill(0);
    product &&
      product.productList &&
      product.productList[size] &&
      product.productList[size].map((ele, index) => {
        if (ele["show"] === true) {
          arr[index] = 1;
        }
      });
    setAvailableColor([...arr]);
  }, [product, size]);

  useEffect(() => {
    const length = availableColor.reduce((count, currentValue) => {
      return count + (currentValue === 1 ? 1 : 0);
    }, 0);
    const result = new Array(5).fill(0);

    // Kiểm tra xem có đủ phần tử không
    if (length > 5) {
      // Kiểm tra từ vị trí index0 trở đi
      let count = 1;
      result[2] = color;
      for (let i = 1; i <= availableColor.length; i++) {
        if (
          count <= 2 &&
          availableColor[(color + i) % availableColor.length] === 1
        ) {
          result[2 + count] = (color + i) % availableColor.length;
          count++;
        }
      }
      count = 1;
      for (let i = 1; i <= availableColor.length; i++) {
        if (
          count <= 2 &&
          availableColor[
            (color - i + availableColor.length) % availableColor.length
          ] === 1
        ) {
          result[2 - count] =
            (color - i + availableColor.length) % availableColor.length;
          count++;
        }
      }

      setIndexColor(result);
    }
  }, [availableColor, color]);

  useEffect(() => {
    // console.log( product , discountList)
  }, [product]);

  const preloadImage = (url) => {
    const img = new Image();
    img.src = cloudFrontURL + `/` + url;
  };

  function getIndexOfTrue(array, i) {
    let count = 0;
    for (let index = 0; index < array.length; index++) {
        if (array[index] === true) {
            count++;
            if (count === i) {
                return index;
            }
        }
    }
    return 0;
  }

  useEffect(() => {
    if (product && product.productList) {
      setCurProduct(
        product &&
          product.productList &&
          product.productList[Number(size)] &&
          product.productList[Number(size)][Number(color)]
          ? product.productList[Number(size)][Number(color)]
          : {}
      );
    }
    if (
      Helper.checkShowProduct(
        size,
        color,
        product.productList,
        product.sizeArray,
        product.sizeDiff
      )
    ) {
    } else {
      const { newSize, newColor } = Helper.getNewProduct(
        size,
        color,
        product.productList,
        product.sizeArray,
        product.sizeDiff
      );
      setColor(Number(newColor));
      setSize(Number(newSize));
    }
  }, [product, color, size]);

  useEffect(() => {
    for (let i in product?.productImgListUrl) {
      for (let j in product?.productImgListUrl[i]) {
        preloadImage(product?.productImgListUrl[i][j][0] || "");
      }
    }
  }, [product]);

  return (
    <React.Fragment>
      {/* product container */}

      {(product?._id ?? "") !== "" && (
        <PreloadImages
          imageUrls={(product?.combine ?? []).reduce((pre, cur) => {
            return (cur?.show ?? false) === true
              ? [...pre, cur?.imgList[0] ?? ""]
              : pre;
          }, [])}
          dependency={product}
        />
      )}
      <div
        className="col-6 col-md-4 product-tile-col odd"
        id={id}
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/Product"
      >
        <div className="product">
          <div className="product-tile">
            <div className="image-container">
              <a
                href={`/p/${
                  product && product.SEOLink ? product.SEOLink : "undefined"
                }?color=${color}&size=${size}`}
              >
                <img
                  itemProp="image"
                  className="tile-image lazy-loaded"
                  src={
                    product &&
                    product.productImgListUrl &&
                    product.productImgListUrl[size] &&
                    product.productImgListUrl[size][color] &&
                    product.productImgListUrl[size][color][0]
                      ? `${cloudFrontURL}/${product.productImgListUrl[size][color][0]}`
                      : ""
                  }
                  alt="cont"
                  title=""
                />
              </a>
            </div>
            <div className="tile-body">
              <div className="tile-body-field-group">
                <div className="swatch-row">
                  <div className="color-attribute">
                    <div className="attribute">
                      <div className="color-quantity">
                      <span>{`${(product?.combine ?? []).reduce(
                                  (acc, obj) =>
                                    (obj?.show ?? false) === true &&
                                    ((product.sizeDiff === true &&
                                      (obj?.size ?? 0) === getIndexOfTrue(product.sizeArray ?? [],size+1)) ||
                                      product.sizeDiff === false)
                                      ? acc + 1
                                      : acc,
                                  0
                                )} màu`}</span>
                      </div>
                      <div className="owl-carousel color-swatchs">
                        {product.colorArrayUrl &&
                          availableColor &&
                          availableColor.reduce((count, currentValue) => {
                            return count + (currentValue === 1 ? 1 : 0);
                          }, 0) <= 5 &&
                          product.colorArrayUrl.map((ele, index) => {
                            return (
                              product &&
                              product.productList &&
                              product.productList[size] &&
                              product.productList[size][color] &&
                              product.productList[size][index]["show"] ===
                                true && (
                                <div key={index}>
                                  <div
                                    className={
                                      color === index
                                        ? "attr-item watch-item selected"
                                        : "attr-item watch-item"
                                    }
                                  >
                                    <a
                                      href="/"
                                      className="attr-selector"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setColor(index);
                                      }}
                                    >
                                      <span
                                        className="color-value swatch-circle swatch-value "
                                        title={`${
                                          product?.colorArrayDesc[index] ?? ""
                                        }`}
                                        style={{
                                          backgroundImage: `url(${cloudFrontURL}/${ele})`,
                                          backgroundSize: "100%",
                                        }}
                                      ></span>
                                    </a>
                                  </div>
                                </div>
                              )
                            );
                          })}
                        {product.colorArrayUrl &&
                          availableColor &&
                          availableColor.reduce((count, currentValue) => {
                            return count + (currentValue === 1 ? 1 : 0);
                          }, 0) > 5 &&
                          indexColor.map((ele, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  color === ele
                                    ? "attr-item watch-item selected"
                                    : "attr-item watch-item"
                                }
                              >
                                <a
                                  href="/"
                                  className="attr-selector"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setColor(ele);
                                  }}
                                >
                                  <span
                                    className="color-value swatch-circle swatch-value "
                                    title={`${
                                      product?.colorArrayDesc[ele] ?? ""
                                    }`}
                                    style={{
                                      backgroundImage: `url(${cloudFrontURL}/${product.colorArrayUrl[ele]})`,
                                      backgroundSize: "100%",
                                    }}
                                  ></span>
                                </a>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                {product && (
                  <div className="tile-attribute" data-attr="productSize">
                    <div className="attribute ">
                      <div className="owl-carousel size-selection">
                        {product.sizeArrayDesc &&
                          getCategory().map((ele, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  index === size
                                    ? "attr-item size-item selected"
                                    : "attr-item size-item"
                                }
                              >
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSize(index);
                                  }}
                                  className="attr-selector product-size"
                                  style={{ minHeight: "23px" }}
                                >
                                  {`${ele}`}
                                </a>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
                <div className="product-collection" style={{ height: "24px" }}>
                  <h4>
                    <a
                      itemProp="name"
                      className="link"
                      href={`/p/${
                        product && product.SEOLink
                          ? product.SEOLink
                          : "undefined"
                      }?color=${color}&size=${size}`}
                      title={product && product.name ? product.name : ""}
                      style={{
                        fontWeight: "800",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "inline-block",
                        width: "100%",
                        padding: "0 5px",
                      }}
                    >
                      {product && product.name ? product.name : ""}
                    </a>
                  </h4>
                </div>
                <div className="pdp-link" style={{ height: "15.5972px" }}>
                  <a
                    className="link"
                    href={`/p/${
                      product && product.SEOLink ? product.SEOLink : "undefined"
                    }?color=${color}&size=${size}`}
                    style={{ fontWeight: "800" }}
                  >
                    {product &&
                      product.sizeDiff === true &&
                      product.sizeArrayDesc.filter(
                        (e, i) => product.sizeArray[i] === true
                      )[size]}
                    {product &&
                      product.sizeDiff === false &&
                      (product.nonSizeDesc ?? "")}
                  </a>
                </div>
                <div className="price">
                  <div className="price">
                    <span className="price-inner">
                      {!discountList[curProduct && curProduct.sku] && (
                        <span className="sales">
                          <span className="value">
                            {helper.moneyFormat(
                              curProduct && curProduct.price
                                ? curProduct.price
                                : 0
                            )}
                          </span>
                        </span>
                      )}

                      {/* {info.discount===0&&
                        <span className="sales">
                            <span className="value">{helper.moneyFormat(info.price)}</span>
                        </span>
                        } */}

                      {discountList[curProduct && curProduct.sku] && (
                        <div>
                          <span className="sales promotion-price">
                            <span className="value">
                              {helper.moneyFormat(
                                discountList[curProduct && curProduct.sku]
                                  ? discountList[curProduct && curProduct.sku]
                                  : curProduct && curProduct.price
                                  ? curProduct.price
                                  : 0
                              )}
                            </span>
                          </span>
                          <span className="strike-through list">
                            <span className="value">
                              {helper.moneyFormat(
                                curProduct && curProduct.price
                                  ? curProduct.price
                                  : 0
                              )}
                            </span>
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="tile-bottom">
              <div className="tile-actions-color-attributes">
                <div className="tile-actions">
                  <div className="tile-go-detail">
                    <a
                      className="btn btn-default btn-go-details"
                      href={`/p/${
                        product && product.SEOLink
                          ? product.SEOLink
                          : "undefined"
                      }?color=${color}&size=${size}`}
                      data-pid="at-132684-0658"
                      pid="at-132684-1041"
                      style={{ fontWeight: 800 }}
                    >
                      Xem chi tiết
                    </a>
                  </div>
                  <div className="tile-add-to-cart">
                    <div className="row cart-and-ipay">
                      <div className="col-sm-12">
                        <button
                          disabled={curProduct && curProduct.remain <= 0}
                          className={`add-to-cart btn btn-primary `}
                          data-pid="at-132684-0658"
                          pid="at-132684-1041"
                          style={{ fontWeight: 800 }}
                          onClick={() => {
                            let sku = curProduct.sku ?? "";
                            let seo = product.SEOLink ?? "";
                            if (
                              localStorage &&
                              localStorage.cartAT &&
                              sku !== "" &&
                              seo !== ""
                            ) {
                              if (
                                !Object.keys(
                                  JSON.parse(localStorage.cartAT)
                                ).includes(seo)
                              ) {
                                localStorage &&
                                  localStorage.setItem(
                                    "cartAT",
                                    JSON.stringify({
                                      ...JSON.parse(localStorage.cartAT),
                                      [seo]: { [sku]: 1 },
                                    })
                                  );
                                setCartChange((prev) => {
                                  return prev + 1;
                                });
                                setCartEvent(false);
                              } else {
                                localStorage &&
                                  localStorage.setItem(
                                    "cartAT",
                                    JSON.stringify({
                                      ...JSON.parse(localStorage.cartAT),
                                      [seo]: {
                                        ...JSON.parse(localStorage.cartAT)[seo],
                                        [sku]: JSON.parse(localStorage.cartAT)[
                                          seo
                                        ][sku]
                                          ? JSON.parse(localStorage.cartAT)[
                                              seo
                                            ][sku] + 1
                                          : 1,
                                      },
                                    })
                                  );
                                setCartChange((prev) => {
                                  return prev + 1;
                                });
                                setCartEvent(false);
                              }
                            } else {
                              localStorage &&
                                localStorage.setItem(
                                  "cartAT",
                                  JSON.stringify({ [seo]: { [sku]: 1 } })
                                );
                              setCartChange((prev) => {
                                return prev + 1;
                              });
                              setCartEvent(false);
                            }
                          }}
                        >
                          {curProduct &&
                          curProduct.remain &&
                          curProduct.remain > 0
                            ? "Thêm vào giỏ"
                            : "Hết Hàng"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="tile-footer">
              <div className="product-comparition">
                <span
                  data-pid="at-132684-0658"
                  className="checkbox checked"
                  pid="at-132684-1041"
                >
                  <input
                    type="hidden"
                    id="at-132684-0658"
                    className="checkbox-comparition"
                    value="false"
                  />
                  Compare
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductContainer;
