import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../styles/cart.css"; // Import CSS của toàn bộ ứng dụng
import Api from "../components/api/apis";
import { decode } from "html-entities";

const Post = () => {
  const param = useParams();

  const [news, setNews] = useState({});
  const [loading, setLoading] = useState(true);
  const [extractedCss, setExtractedCss] = useState("");

  useEffect(() => {
    let set = true;
    setLoading(true);
    const fetchDataNews = async () => {
      try {
        const rs = await Api.getNews(decodeURIComponent(param?.name ?? ""));
        if (set) {
          setLoading(false);
          setNews(rs?.news ?? {});
          document.title = rs?.news?.name ?? ""
          extractAndSetCss(decode((rs?.news?.content ?? "").replace(/<\s*br\s*\/?\s*>/gi, '').replace(/<p[^>]*>/gi, '').replace(/<\/p>/g, '')));

        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataNews();

    return () => {
      set = false;
    };
  }, [param.name]);

  const extractAndSetCss = (htmlContent) => {
    const styleRegex = /<style ([\s\S]*?)<\/style>/gi; // Regex để tìm thẻ <style>
    const match = styleRegex.exec(htmlContent);
    const cssString = match ? match[1] : ''; // Trích xuất nội dung trong thẻ <style>
  
    // Loại bỏ các thẻ HTML và dấu cách không mong muốn
    const cleanedCssString = cssString
      .replace(/<\/?p>/g, '') // Loại bỏ thẻ <p> và </p>
      .replace(/<br\s*\/?>/g, '') // Loại bỏ thẻ <br> và <br/>
      .replace(/\s+/g, ' ') // Thay thế nhiều dấu cách bằng một dấu cách duy nhất
      .trim(); // Loại bỏ dấu cách ở đầu và cuối chuỗi
    setExtractedCss(cleanedCssString); // Thiết lập CSS đã trích xuất
  };
  

  useEffect(() => {
    if (news._id) {
      applyCssToElements(); // Áp dụng CSS cho các phần tử khi nội dung được tải
    }
  }, [news]);

  // Hàm áp dụng CSS cho các phần tử
  const applyCssToElements = () => {
    const elements = document.querySelectorAll('*');
    elements.forEach(element => {
      const tagName = element.tagName.toLowerCase();
      const elementStyle = getElementStyle(tagName);
      Object.assign(element.style, elementStyle);
    });
  };

  // Hàm lấy quy tắc CSS cho một thẻ cụ thể
  const getElementStyle = (tagName) => {
    const elementStyle = {};
    const cssRules = extractMediaQueryRules(tagName);
    cssRules.forEach(rule => {
      const css = rule.css.trim();
      const properties = css.split(';');
      properties.forEach(property => {
        const [key, value] = property.split(':').map(prop => prop.trim());
        elementStyle[key] = value;
      });
    });
    return elementStyle;
  };

  // Hàm trích xuất các quy tắc CSS từ media queries cho một thẻ cụ thể
  const extractMediaQueryRules = (tagName) => {
    const mediaQueryRegex = /@media[^{]+{([\s\S]*?)}/gi;
    const matches = extractedCss.match(mediaQueryRegex);
    const rules = [];
    if (matches) {
      matches.forEach(match => {
        const cssRegex = new RegExp(`${tagName}[^{]+{([^}]+)}`);
        const ruleMatch = cssRegex.exec(match);
        if (ruleMatch) {
          rules.push({ tagName, css: ruleMatch[1] });
        }
      });
    }
    return rules;
  };

  return (
    <div>
      {news._id ? (
        <div style={{ minHeight: "500px" }}>
          {/* Nội dung HTML sẽ được hiển thị ở đây */}
          <div dangerouslySetInnerHTML={{ __html: decode((news?.content ?? "").replace(/<\s*br\s*\/?\s*>/gi, '').replace(/<p[^>]*>/gi, '').replace(/<\/p>/g, '')) }} />

          {/* Thẻ style để áp dụng CSS internal */}
          <style>{extractedCss}</style>
        </div>
      ) : (
        <div style={{ minHeight: "500px" }}>
          <div style={{ margin: "100px", fontSize: 30 }}>
            {loading === false ? "Tin không tồn tại" : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default Post;
