import React from "react";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "../styles/cart.css";
import helper from "../helper";
import Api from "../components/api/apis";
import { Helmet } from "react-helmet";

import apis from "../components/api/apis";

const GoogleAnalyticsScript = ({ invoice }) => {
  useEffect(() => {
    const scriptId = "google-analytics-script-purchase";

    if (invoice && (invoice?._id ?? "") !== "") {
      // Xóa script cũ nếu đã tồn tại
      const oldScript = document.getElementById(scriptId);
      if (!oldScript) {
        // Tạo một thẻ script mới
        const script = document.createElement("script");
        script.id = scriptId;
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;

        // Thiết lập nội dung của script
        script.innerHTML = `
      gtag("event", "purchaseWeb", {
        transaction_id: "${invoice?.code ?? "none"}",
        value: ${invoice?.finalPrice ?? 0},
        emailEC: "${invoice?.email ?? ""}",
        phoneEC: "${invoice?.phone ?? ""}",
      });
    `;

        // Thêm script mới vào body
        document.head.appendChild(script);
      }
    }
  }, [invoice._id]);

  return null;
};

const InvoiceInfo = ({ title }) => {
  document.title = title;
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;

  const param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // tam tinh
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");

  const [invoice, setInvoice] = useState({});
  const [trigger, setTrigger] = useState(true);

  useEffect(() => {
    let set = true;
    if (param.id) {
      let rs = {};
      const fetchData = async () => {
        try {
          rs = { ...(await Api.getOneInvoice(param.id)) };
          if (set) {
            if (rs.success === false) {
              window.open("/", "_self");
            } else {
              setInvoice(rs.invoice ?? {});
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }

    return () => {
      set = false;
    };
  }, [param.id, trigger]);

  useEffect(() => {
    let set = true;

    let cList = [];
    const fetchDataCt = async () => {
      try {
        cList = [...(await Api.getCityList())];
        if (set) {
          setCityList(cList);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataCt();

    return () => {
      set = false;
    };
  }, []);

  useEffect(() => {
    const paramsObject = Object.fromEntries(searchParams.entries());
    if (invoice && invoice._id) {
      if (
        paramsObject.vpc_SecureHash &&
        invoice._id &&
        invoice.paymentMethod === 3
      ) {
        fetch(`${apiURL}/onepay/paylink`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paramsObject,
          }),
        })
          .then((res) => {
            if (res.ok) return res.json();
            throw new Error("Something went wrong");
          })
          .then((result) => {
            if (result.success !== true) {
            }
            setTrigger((prev) => {
              return !prev;
            });
          });
      }
      if (paramsObject.checksum && invoice._id && invoice.paymentMethod === 4) {
        fetch(`${apiURL}/payoo/paylink`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paramsObject,
          }),
        })
          .then((res) => {
            if (res.ok) return res.json();
            throw new Error("Something went wrong");
          })
          .then((result) => {
            if (result.success !== true) {
            }
            setTrigger((prev) => {
              return !prev;
            });
          });
      }
    }
    // setSearchParams({});
  }, [searchParams, invoice._id]);

  useEffect(() => {
    if ((invoice?._id ?? "") !== "" && (invoice?.fbqCall ?? false) === false) {
      fetch(`${apiURL}/invoice/${invoice._id}`, {
        method: "POST",
      })
        .then((res) => {
          if (res.ok) return res.json();
        })
        .then((result) => {})
        .catch((e) => {
          console.log(e);
        });
    }
  }, [invoice, trigger]);

  useEffect(() => {
    if (invoice && invoice._id && cityList.length > 0) {
      for (let i in cityList) {
        if (cityList[i].code === (invoice.cityCode ?? "")) {
          setCity(cityList[i].name ?? "");
          for (let j of cityList[i].districts ?? []) {
            if ((j.code ?? "") === (invoice.districtCode ?? "")) {
              setDistrict(j.name ?? "");
            }
          }
        }
      }
    }
  }, [invoice, cityList]);

  return (
    <React.Fragment>
      <Helmet>
        {/* Meta Pixel Code */}
        {invoice &&
          invoice._id &&
          invoice._id !== "" &&
          (invoice?.fbqCall ?? false) === false && (
            <script type="text/javascript" defer>
              {`
        try {
          !function(f,b,e,v,n,t,s) {
            if(f.fbq) return; 
            n = f.fbq = function() {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  
          fbq('init', '437041775344814');
          fbq('track', 'Purchase', {
            content_ids: [${invoice.productList.reduce((pre, cur) => {
              return [...pre, "'" + String(cur?.sku ?? "none") + "'"];
            }, [])}],
            content_type : 'product_group',
            currency: 'VND',
            eventID: '${invoice?.code ?? "none"}',
            num_items: ${invoice.productList.reduce((pre, cur) => {
              return pre + (cur?.quantity ?? 0);
            }, 0)},
            value: ${invoice?.finalPrice ?? 0} ,
          });
        } catch (error) {
          console.error('Script error:', error);
        }
      `}
            </script>
          )}
        {invoice &&
          invoice._id &&
          invoice._id !== "" &&
          (invoice?.fbqCall ?? false) === false && (
            // true &&
            <script type="text/javascript" defer>
              {`
        try {
          gtag("event", "purchase", {
            transaction_id: "${invoice?.code ?? "none"}",
            value: ${invoice?.finalPrice ?? 0},
            shipping: ${invoice?.deliverFee ?? 0},
            currency: "VND",
            coupon: "${invoice?.voucher?.code ?? ""}",
            items: [
              ${((invoice?.productList ?? []).length > 0
                ? invoice?.productList ?? []
                : []
              ).reduce((pre, cur) => {
                return [
                  ...pre,
                  JSON.stringify({
                    item_id: cur?.sku ?? "",
                    item_name: cur?.name ?? "",
                    item_variant: cur?.colorArrayDesc ?? "",
                    price: (invoice?.pList ?? {})[cur?.sku ?? "none"]
                      ? (invoice?.pList ?? {})[cur?.sku ?? "none"]
                      : cur?.price ?? 0,
                    quantity: cur?.quantity ?? 0,
                  }),
                ];
              }, [])}
             ]
        });
        } catch (error) {
          console.error('Script error:', error);
        }
      `}
            </script>
          )}

        {/* End Meta Pixel Code */}
      </Helmet>
      {!(invoice && (invoice?._id ?? "") !== "") && (
        <div style={{ height: "500px" }}></div>
      )}
      {invoice && (invoice?._id ?? "") !== "" && (
        <div className="cart-content">
          <GoogleAnalyticsScript invoice={invoice} />
          <div className="container cart-container title">
            <div className="row">
              <div className="col-12">
                <h1
                  className="page-title text-uppercase"
                  style={{ marginBottom: 10 }}
                >
                  Đặt hàng thành công
                </h1>
                <h4>{`Mã đơn hàng: ${invoice?.code ?? ""}`}</h4>
                <h4>
                  {`Cảm ơn bạn đã mua hàng, chúng tôi đã gởi thông tin mua hàng đến email của bạn.`}
                </h4>
              </div>
            </div>
          </div>
          <div className="container cart-container cart cart-page">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-sm-12 col-md-12"></div>
                  {invoice && invoice._id && (
                    <div className="col-sm-12 col-md-12 totals">
                      <div className="row">
                        <div className="col-md-6 order-md-2">
                          <div className="row row cart-promo-code">
                            {(invoice.productList ?? {}).map((ele, index) => {
                              return (
                                <div key={index} className="card product-info">
                                  <div className="line-item-header">
                                    <div></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row">
                                        <div className="col-lg-12 d-flex flex-row">
                                          <div className="item-image">
                                            <a
                                              href="/"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <img
                                                className="product-image"
                                                src={`${
                                                  ele.img
                                                    ? `${cloudFrontURL}/${ele.img}`
                                                    : ""
                                                }`}
                                                alt="img"
                                                title=" "
                                              />
                                            </a>
                                          </div>
                                          <div className="item-attributes d-flex flex-column">
                                            <p className="line-item-attributes collection-name text-uppercase">
                                              <a
                                                href={`/p/${ele.SEOLink}`}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                                {ele && ele.series
                                                  ? ele.series
                                                  : ""}
                                              </a>
                                            </p>
                                            <p className="line-item-attributes product-name font-weight-bold text-uppercase">
                                              <a href={`/p/${ele.SEOLink}`}>
                                                {ele && ele.name
                                                  ? ele.name
                                                  : ""}
                                              </a>
                                            </p>
                                            <p className="line-item-attributes text-uppercase product-color">
                                              {`${
                                                ele && ele.size ? ele.size : ""
                                              } / ${ele?.colorArrayDesc ?? ``}`}
                                            </p>

                                            <p>{`Số lượng: ${ele.quantity}`}</p>
                                          </div>
                                        </div>
                                        {!invoice.pList[ele.sku] && (
                                          <div className="regular-retail-price col-lg-12 text-right">
                                            <span className="promotion-price">
                                              {ele && ele.price
                                                ? helper.moneyFormat(
                                                    ele.price * ele.quantity
                                                  )
                                                : helper.moneyFormat(0)}
                                            </span>
                                          </div>
                                        )}
                                        {invoice.pList[ele.sku] && (
                                          <div className="regular-retail-price col-lg-12 text-right">
                                            <del className="promotion-price">
                                              {ele && ele.price
                                                ? helper.moneyFormat(
                                                    ele.price * ele.quantity
                                                  )
                                                : helper.moneyFormat(0)}
                                            </del>
                                          </div>
                                        )}
                                        {invoice.pList[ele.sku] && (
                                          <div className="col-lg-12 product-card-footer">
                                            <div className="container-applied-promotion">
                                              <div className="line-item-promo">
                                                <div className="line-item-final-price">
                                                  {helper.moneyFormat(
                                                    (invoice.pList[ele.sku]
                                                      ? invoice.pList[ele.sku]
                                                      : ele.price) *
                                                      ele.quantity
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {invoice &&
                              invoice.GWP &&
                              invoice.GWP.length > 0 &&
                              invoice.GWP.map((ele, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="card product-info"
                                  >
                                    <div className="line-item-header">
                                      <div></div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="row">
                                          <div className="col-lg-12 d-flex flex-row">
                                            <div className="item-image">
                                              <a
                                                href="/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <img
                                                  className="product-image"
                                                  src={`${
                                                    ele.img
                                                      ? `${cloudFrontURL}/${ele.img}`
                                                      : ""
                                                  }`}
                                                  alt="img"
                                                  title=" "
                                                />
                                              </a>
                                            </div>
                                            <div className="item-attributes d-flex flex-column">
                                              <p className="line-item-attributes collection-name text-uppercase">
                                                <a
                                                  href={`/p/${ele.SEOLink}`}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  {ele && ele.series
                                                    ? ele.series
                                                    : ""}
                                                </a>
                                              </p>
                                              <p className="line-item-attributes product-name font-weight-bold text-uppercase">
                                                <a href={`/p/${ele.SEOLink}`}>
                                                  {ele && ele.name
                                                    ? ele.name
                                                    : ""}
                                                </a>
                                              </p>
                                              <p className="line-item-attributes text-uppercase product-color">
                                                {`${
                                                  ele && ele.size
                                                    ? ele.size
                                                    : ""
                                                } / ${
                                                  ele?.colorArrayDesc ?? ``
                                                }`}
                                              </p>

                                              <p>{`Số lượng: ${
                                                ele?.quantity ?? 0
                                              }`}</p>
                                            </div>
                                          </div>
                                          <div className="regular-retail-price col-lg-12 text-right">
                                            <span className="promotion-price">
                                              Quà tặng
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            <div className="sub-total-block">
                              <span>Tạm tính</span>
                              <span>
                                {helper.moneyFormat(
                                  invoice.afterDiscountPrice ?? 0
                                )}
                              </span>
                            </div>
                            <div className="sub-total-block">
                              <span>Giảm chương trình</span>
                              <span>
                                -
                                {helper.moneyFormat(
                                  invoice.conditionDiscountValue ?? 0
                                )}
                              </span>
                            </div>

                            <div className="shipping-method">
                              <div className="col-12">
                                <div className="shipping-information">
                                  <div>
                                    Mã giảm giá: -
                                    {helper.moneyFormat(
                                      invoice?.voucher?.totalDiscount ?? 0
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="shipping-method">
                              <div className="col-12">
                                <div className="shipping-information">
                                  <div>
                                    Phí vận chuyển:{" "}
                                    {helper.moneyFormat(
                                      invoice.deliverFee ?? 0
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 text-right cart-total">
                              <div className="w-100">Tổng</div>
                              <div className="w-100 grand-total">
                                {helper.moneyFormat(invoice.finalPrice ?? 0)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 order-md-1">
                          <div className="row cart-promo-code">
                            <div className="col-12">
                              <div className="checkOut-cusInfo">
                                <div className="cusInfo-box">
                                  <h4
                                    style={{
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    Thông tin đơn hàng
                                  </h4>
                                  <div></div>
                                  <div style={{ marginBottom: 10 }}>
                                    Thông tin giao hàng
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${invoice?.customerName ?? ""}`}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${invoice?.phone ?? ""}`}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${invoice?.email ?? ""}`}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${invoice?.address ?? ""}`}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${district ?? ""}`}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: 15,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${city ?? ""}`}
                                  </div>
                                  <div style={{ marginBottom: 10 }}>
                                    Phương thức thanh toán
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {`${apis.getPaymentMethod(
                                      Number(invoice?.paymentMethod ?? 0)
                                    )}`}
                                  </div>
                                  <div
                                    style={{ marginBottom: 10 }}
                                  >{`Trạng thái thanh toán`}</div>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      color: "#808080",
                                    }}
                                  >
                                    {invoice.paymentState
                                      ? "Đã thanh toán"
                                      : "Chưa thanh toán"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default InvoiceInfo;
