import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import Image1 from "../assets/HomepageSlider/1.jpg";
import Image2 from "../assets/HomepageSlider/2.jpg";
import ATPage from "../assets/homepageFeatures/static-page-1.png";
import Feature1 from "../assets/homepageFeatures/ico-office-site.svg";
import Feature2 from "../assets/homepageFeatures/ico-delivery.svg";
import Feature3 from "../assets/homepageFeatures/ico-secure-payment.svg";
import Feature4 from "../assets/homepageFeatures/ico-full-collection.svg";
import { Autoplay } from "swiper";
import "../styles/Homepage/homepage.css";
import { Icon } from "@iconify/react";
import Api from "../components/api/apis";
import NewAndPopular from "../components/Product/NewAndPopular";
import InstaPost from "../components/Social/InstaPost";
import NewsFeed from "../components/Social/NewsFeed";
import PreloadImages from "../utils/PreloadImages";

SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination]);

const Homepage = ({channel, discountList}) => {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [newAndPopular, setNewAndPopular] = useState({ new: [], popular: [] });
  const [imageLoad, setImageLoad] = useState({})

  const [newsList, setNewsList] = useState([])

  useEffect(() => {
    let fetchData = async () => {
      try {
        const [newsRes, newAndPopularRes] = await Promise.all([
          Api.getNews(),
          Api.getNewAndPopular()
        ]);
        setNewsList(newsRes.newsList || []);
        setNewAndPopular(newAndPopularRes || {});
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
    };
  }, []);
  
  const imgArrDefault = [
    Image1,
    Image2,
  ];

  return (
    <React.Fragment>
      {(channel?.websiteImage?.mainSlide ?? []).length > 0 &&
      <React.Fragment>
      <PreloadImages imageUrls={((channel?.websiteImage?.mainSlide).length > 0
            ? [channel?.websiteImage?.mainSlide[0]]
            : []
          )} />
      <Swiper
        id="HomepageMain"
        tag="section"
        speed={1000}
        autoplay={{
          delay: 4001,
          disableOnInteraction: false,
        }}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        loop
        initialSlide={1}
      >
        {channel._id &&
          ((channel?.websiteImage?.mainSlide).length > 0
            ? channel?.websiteImage?.mainSlide
            : imgArrDefault
          ).map((ele, index) => {
            return (
              <SwiperSlide key={index} tag="li" style={{ cursor: "pointer" }}>
                <img
                  className="img-fluid ls-is-cached lazyloaded"
                  alt="img"
                  src={`${cloudFrontURL}/${ele}`}
                  onClick={(e) => {
                    window.open(
                      channel?.websiteImage?.mainSlideUrl[index] ?? "",
                      "_self"
                    );
                  }}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      </React.Fragment>
      }

      <section className="section section-home-content homepage">
        <div className="home-promotion" id="home-promotion">
          <div className="container-wide-screen">
            <div className="row promotion-content luggage-escapes has-animation load-animation">
              <div className="col col-12 col-md-7 luggage-show">
                <picture>
                  <img
                    alt="luggage car"
                    src={
                      channel._id
                        ? `${cloudFrontURL}/${channel.webIntro.url}`
                        : ""
                    }
                    className="lazy-initialized lazy-loaded"
                    onLoad={()=>{
                      setImageLoad({...imageLoad,'0':true})
                    }}
                    style={{opacity:`${imageLoad[0]===true?"1":"0"}`}}
                  />
                </picture>
              </div>
              <div className="col col-12 col-md-5 promotion-info luggage-escapes-info">
                <h2 className="title">{channel?.webIntro?.title ?? ""}</h2>
                <p>{channel?.webIntro?.content ?? ""}</p>
                <a className="white-button gtm-click-tile-1" href="/all/p/p">
                  Mua ngay
                </a>
              </div>
            </div>
          </div>
          <div className="container-wide-screen"style={{marginBottom:"45px"}}>
            <div className="row promotion-content luggage-escapes has-animation load-animation">
              <div className="col col-12 col-md-7 luggage-show">
                <picture>
                  <img
                    alt="luggage car"
                    src={
                      channel._id
                        ? `${cloudFrontURL}/${channel.webIntro2.url}`
                        : ""
                    }
                    className="lazy-initialized lazy-loaded"
                    onLoad={()=>{
                      setImageLoad({...imageLoad,'0':true})
                    }}
                    style={{opacity:`${imageLoad[0]===true?"1":"0"}`}}
                  />
                </picture>
              </div>
              <div className="col col-12 col-md-5 promotion-info luggage-escapes-info">
                <h2 className="title">{channel?.webIntro2?.title ?? ""}</h2>
                <p>{channel?.webIntro2?.content ?? ""}</p>
                <a className="white-button gtm-click-tile-1" href="/hot/p/p">
                  Mua ngay
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-category container-fluid has-animation load-animation" style={{
            marginBottom: "70px",
          }}>
      <div className="container">
        <div className="row category text-center">
          <div className="col col-6 item1">
            <div className="image">
              <a href="/vali/p/p">
                <picture> 
                  <img
                    className="gtm-click-tile-4 lazy-loaded"
                    src={`${cloudFrontURL}/1691118750946.png`} alt="luggage"
                  />
                </picture>
              </a>
            </div>
            <div className="category-info">
              <h3 className="category-title">
                <a className="gtm-click-tile-4" href="/vali/p/p" 
                      style={{ fontWeight: 900, fontSize : '1.4em' }}>
                  Vali
                </a>
              </h3>
              <a href="/vali/p/p" className="category-more gtm-click-tile-4">
                Xem thêm <i className="at-icon icon-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col col-6 item2">
            <div className="image">
              <a href="/balo/p/p">
                <picture>
                  <img
                    className="gtm-click-tile-4 lazy-loaded"
                    src={`${cloudFrontURL}/1691118899685.png`}
                    alt="backpacks"
                  />
                </picture>
              </a>
            </div>
            <div className="category-info">
              <h3 className="category-title">
                <a className="gtm-click-tile-4" href="/balo/p/p" 
                      style={{ fontWeight: 900, fontSize : '1.4em' }}>
                  Balo
                </a>
              </h3>
              <a href="/balo/p/p" className="category-more gtm-click-tile-4">
                Xem thêm <i className="at-icon icon-arrow-right"></i>
              </a>
            </div>
          </div> 
        </div>
      </div>
    </div>
      </section>
      <NewsFeed
        newsList = {newsList}
      ></NewsFeed>
      <InstaPost
        instaUrl = {channel?.instaUrl??[]} instaImageUrl = {channel?.instaImageUrl??[]}
        newAndPopular={newAndPopular}
        discountList={discountList}
      ></InstaPost>
      <NewAndPopular
        newAndPopular={newAndPopular}
        discountList={discountList}
      ></NewAndPopular>

      <section
        className="section section-home-footer homepage"
        style={{ marginBottom: 200 }}
      >
        <div className="home-why-shop-with-us">
          <div className="container-fluid">
            <div className="content">
              <div
                className="item-skew container has-animation load-animation"
                style={{ paddingRight: 15, paddingLeft: 15 }}
              >
                <ul>
                  <li>
                    <div className="image">
                      <img
                        alt="feature"
                        className="lazy-loaded"
                        src={Feature1}
                      ></img>
                    </div>
                    <h6>Website chính thức</h6>
                    <p>
                      Mua sắm trực tuyến vali, balo, túi xách và phụ kiện du
                      lịch chính hãng American Tourister.
                    </p>
                  </li>
                  <li>
                    <div className="image">
                      <img
                        alt="feature"
                        className="lazy-loaded"
                        src={Feature2}
                      ></img>
                    </div>
                    <h6>Giao hàng nhanh chóng</h6>
                    <p>
                      Đơn hàng trực tuyến được giao trong vòng 3-10 ngày làm
                      việc thông qua dịch vụ chuyển phát cao cấp.
                    </p>
                  </li>
                  <li>
                    <div className="image">
                      <img
                        alt="feature"
                        className="lazy-loaded"
                        src={Feature3}
                      ></img>
                    </div>
                    <h6>Thanh toán an toàn</h6>
                    <p>
                      Bảo mật giao dịch đáng tin cậy với hệ thống mã hóa Secure
                      Socket Layer (SSL).
                    </p>
                  </li>
                  <li>
                    <div className="image">
                      <img
                        alt="feature"
                        className="lazy-loaded"
                        src={Feature4}
                      ></img>
                    </div>
                    <h6>Bộ sưu tập đầy đủ</h6>
                    <p>
                      Khám phá toàn bộ bộ sưu tập American Tourister trực tuyến,
                      chỉ trong một điểm đến.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="brand-story container container-wide-screen has-animation load-animation">
          <a
            href="/about-us"
            style={{ color: "fff" }}
          >
            <picture>
              <img alt="logo" src={ATPage} className="lazy-loaded" />
            </picture>
            <div className="brand-info text-center">
              <div className="brand-info-content">
                <h3 className="brand-title">Thương hiệu của thời đại</h3>
                <p className="brand-text-details">
                Được nhiều thế hệ du khách tin cậy, chúng tôi tự hào về câu chuyện 90 năm của mình.
                </p>
              </div>
              <div className="white-button gtm-click-tile-6">Khám phá</div>
            </div>
          </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Homepage;
