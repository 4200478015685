import React, { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import "../styles/Product/productList.css";
import ProductContainer from "../components/Product/ProductContainer";
import Api from "../components/api/apis";
import helper1 from "../helper";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Icon } from '@iconify/react';

const ProductList = ({ setCartChange, title, discountList, cartEvent, setCartEvent, channel }) => {
  let { category, type, detail } = useParams();
  
  useEffect(()=>{
    if(title !== "Special Offers"){
      if(category && type && detail && channel._id){
        if(category === 'vali-size-cabin'){
          document.title = (channel?.menuTitle?.cabinTitle ?? "")!==""? (channel?.menuTitle?.cabinTitle ?? ""): "Vali size cabin" 
          document.querySelector('meta[name="description"]').setAttribute("content", (channel?.menuTitle?.cabinDesc ?? "")!==""? (channel?.menuTitle?.cabinDesc ?? ""): "");
        }else if(category === 'vali-size-trung'){
          document.title = (channel?.menuTitle?.mediumTitle ?? "")!==""? (channel?.menuTitle?.mediumTitle ?? ""): "Vali size trung" 
          document.querySelector('meta[name="description"]').setAttribute("content", (channel?.menuTitle?.mediumDesc ?? "")!==""? (channel?.menuTitle?.mediumDesc ?? ""): "");
        }else if(category === 'vali-size-dai'){
          document.title = (channel?.menuTitle?.largeTitle ?? "")!==""? (channel?.menuTitle?.largeTitle ?? ""): "Vali size đại" 
          document.querySelector('meta[name="description"]').setAttribute("content", (channel?.menuTitle?.largeDesc ?? "")!==""? (channel?.menuTitle?.largeDesc ?? ""): "");
        }else if(category === 'vali'){
          let menu = channel.luggageMenu
          let s = (channel?.menuTitle?.luggageTitle ?? "")!==""? (channel?.menuTitle?.luggageTitle ?? ""): "Vali" 
          let desc = (channel?.menuTitle?.luggageDesc ?? "")!==""? (channel?.menuTitle?.luggageDesc ?? ""): "" 
          for(let i in menu){
            if(menu[i].SEOLink === type){
              for(let j in menu[i].arr){
                if(menu[i].arr[j].SEOLink === detail){
                  s = menu[i].arr[j].title
                  desc = menu[i].arr[j].description
                }
              }
            }
          }
          if (desc !== "") {
            document.querySelector('meta[name="description"]').setAttribute("content", desc);
          }
          if(s!==""){
            document.title = s
          }else{
            document.title = title
          }
        }else if(category === 'balo'){
          let menu = channel.backpackMenu
          let s = (channel?.menuTitle?.backpackTitle ?? "")!==""? (channel?.menuTitle?.backpackTitle ?? ""): "Balo" 
          let desc = (channel?.menuTitle?.backpackDesc ?? "")!==""? (channel?.menuTitle?.backpackDesc ?? ""): "" 
          for(let i in menu){
            if(menu[i].SEOLink === type){
              for(let j in menu[i].arr){
                if(menu[i].arr[j].SEOLink === detail){
                  s = menu[i].arr[j].title
                  desc = menu[i].arr[j].description
                }
              }
            }
          }
          if (desc !== "") {
            document.querySelector('meta[name="description"]').setAttribute("content", desc);
          }
          if(s!==""){
            document.title = s
          }else{
            document.title = title
          }
        }else if(category === 'Tui-xach'){
          let menu = channel.bagMenu
          let s = (channel?.menuTitle?.bagTitle ?? "")!==""? (channel?.menuTitle?.bagTitle ?? ""): "Túi xách" 
          let desc = (channel?.menuTitle?.bagDesc ?? "")!==""? (channel?.menuTitle?.bagDesc ?? ""): "" 
          for(let i in menu){
            if(menu[i].SEOLink === type){
              for(let j in menu[i].arr){
                if(menu[i].arr[j].SEOLink === detail){
                  s = menu[i].arr[j].title
                  desc = menu[i].arr[j].description
                }
              }
            }
          }
          if (desc !== "") {
            document.querySelector('meta[name="description"]').setAttribute("content", desc);
          }
          if(s!==""){
            document.title = s
          }else{
            document.title = title
          }
        }else if(category === 'Phu-kien'){
          let menu = channel.accessoryMenu
          let s = (channel?.menuTitle?.accessoryTitle ?? "")!==""? (channel?.menuTitle?.accessoryTitle ?? ""): "Phụ kiện" 
          let desc = (channel?.menuTitle?.accessoryDesc ?? "")!==""? (channel?.menuTitle?.accessoryDesc ?? ""): "" 
          for(let i in menu){
            if(menu[i].SEOLink === type){
              for(let j in menu[i].arr){
                if(menu[i].arr[j].SEOLink === detail){
                  s = menu[i].arr[j].title
                  desc = menu[i].arr[j].description
                }
              }
            }
          }
          if (desc !== "") {
            document.querySelector('meta[name="description"]').setAttribute("content", desc);
          }
          if(s!==""){
            document.title = s
          }else{
            document.title = title
          }
        }
      }else{
        document.title = title  
      }
    }else{
      document.title = title
    }
  },[title, channel])
  const [initColor] = useState(0);
  const [initSize, setInitSize] = useState(0);
  const [searchParams] = useSearchParams();
  const [productList, setProductList] = useState([]);
  const [discount, setDiscount] = useState(false);
  const [sortC, setSortC] = useState(5);
  const [productListFiltered, setProductListFiltered] = useState([]);
  const [productListShow, setProductListShow] = useState([]);
  //filter vars here
  const [colorFilter, setColorFilter] = useState([]);
  const [price, setPrice] = useState([0, 20000000]);
  const minDistance = 100000;
  const [loadingMore, setLoadingMore] = useState(false);

  const [collectionList, setCollectionList] = useState([])
  const [collectionChooseList, setCollectionChooseList] = useState([])
  const [productType, setProductType] = useState(5)

  const limit = 8;
  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPrice([Math.min(newValue[0], price[1] - minDistance), price[1]]);
    } else {
      setPrice([price[0], Math.max(newValue[1], price[0] + minDistance)]);
    }
  };

  
  const [filterTab, setFilterTab] = useState(false);

  // change list when filtered

  useEffect(() => {
    let newArray = [];
    productList.map((ele, index) => {
      if (
        colorFilter.length === 0 ||
        helper1.hasCommonElement(colorFilter, ele.colorArray)
      ) {
        if (
          discount === false ||
          (discount === true &&
            helper1.hasCommonElement(
              ele.SKUArrayActive,
              Object.keys(discountList)
            ))
        ) {
          if (ele.minPrice <= price[1] && ele.minPrice >= price[0]) {
            if(collectionChooseList.length === 0 || (collectionChooseList.some(element => (ele?.categoryIndex??[]).includes(element)))){
              newArray.push(ele);
            }
          }
        }
      }
    });
    if (sortC === 1) {
      newArray.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortC === 2) {
      newArray.sort((a, b) => b.name.localeCompare(a.name));
    } else if (sortC === 3) {
      newArray.sort((a, b) => a.minPrice - b.minPrice);
    } else if (sortC === 4) {
      newArray.sort((a, b) => b.minPrice - a.minPrice);
    } else if (sortC === 5) {
      newArray.sort((a, b) => {
        const dateA = new Date(a.sortDate);
        const dateB = new Date(b.sortDate);
        
        return dateB.getTime() - dateA.getTime();
      });
    }
    setProductListFiltered(newArray);
    if (newArray.length <= limit) {
      setProductListShow(newArray);
    } else {
      setProductListShow(newArray.slice(0, 8));
    }
  }, [colorFilter, productList, discount, sortC, price, discountList,collectionChooseList]);

  useEffect(() => {
    let set = true;
    let body = {isHotDeal : title === "Special Offers", isForHer : title === "For Her", isFestiveGifting : title === "Festive Gifting"};
    // if (searchParams.get("size") !== null) {
    //   setInitSize(searchParams.get("size"));
    //   body.size = Number(searchParams.get("size"));
    // }
    if(body.isHotDeal === false){
      if (searchParams.get("search") !== null) {
        body.search = searchParams.get("search");
      }
      if (type !== 'p' && detail !== 'p') {
        body.x = type;
        body.des = detail;
      }
      if (category !== "all") {
        if (category === "vali") {
          body.category = 1;
        } else if (category === "balo") {
          body.category = 2;
        } else if (category === "Tui-xach") {
          body.category = 3;
        } else if (category === "Phu-kien") {
          body.category = 4;
        } else if (category === "hot") {
          // lay cac san pham ban chay
          body.category = 5;
        } else if (category === "vali-size-cabin") {
          body.category = 1;
          body.size = 0
        } else if (category === "vali-size-trung") {
          body.category = 1;
          body.size = 1
        } else if (category === "vali-size-dai") {
          body.category = 1;
          body.size = 2
        }
      }
    }
    setProductType(body?.category??5)
    let list = [];
    const fetchData = async () => {
      try {
        list = [...(await Api.getProductList(body))];
        if (set) {
          setProductList(list);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, [searchParams]);

  useEffect(() => {
    let set = true;
    let group = []

    const fetchGroupData = async () => {
      try {
        group = [ ...(await Api.getProductGroupList()) ];
        if (set) {
          setCollectionList(
            group
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchGroupData()

    return () => {
      set = false;
    };
  }, []);

  const colorHandle = (color) => {
    if (colorFilter.includes(color)) {
      let arr = colorFilter;
      arr.splice(arr.indexOf(color), 1);
      setColorFilter([...arr]);
    } else {
      let arr = colorFilter;
      arr.push(color);
      setColorFilter((prev) => {
        return [...arr];
      });
    }
  };

  window.addEventListener("scroll", () => {
    handleScroll();
  });

  const handleScroll = () => {
    const finalProduct = document.getElementById(`product${productListShow.length-1}`);

  if (finalProduct) {
    const finalProductRect = finalProduct.getBoundingClientRect();
    const isAbElementVisible = finalProductRect.top <= window.innerHeight && finalProductRect.bottom >= 0;

    if (isAbElementVisible && !loadingMore) {
      loadMoreProducts();
    }
  }
  };

  const loadMoreProducts = async () => {
    setLoadingMore(true);

    try {
      const result = [...productListShow];

      for (let i = 0; i < limit; i++) {
        const indexInB = productListShow.length + i;
        if (indexInB < productListFiltered.length) {
          result.push(productListFiltered[indexInB]);
        }
      }
      setProductListShow([...result]);
    } catch (error) {
      console.error(error);
    }

    setLoadingMore(false);
  };

  return (
    <React.Fragment>
      <div className="search-results-wrapper">
        <div className="section search-results">
          <div className="container">
            <div className="row search-results-head">
              <div className="col-6 results-head-left breadcrumbs-wrapper">
                <div className="row">
                  <div className="col">
                    <ol className="breadcrumb"></ol>
                  </div>
                </div>
              </div>
              <div className="col-6 results-head-right">
                <div className="result-count">
                  <span>
                    <span className="total-products">
                      {productListFiltered.length}
                    </span>{" "}
                    sản phẩm
                  </span>
                </div>
              </div>
            </div>
            <div className="row search-results-head mobile-device">
              <div className="col-6 results-head-left breadcrumbs-wrapper">
                <div className="row">
                  <div className="col">
                    <ol className="breadcrumb">
                  <span>
                  </span></ol>
                  </div>
                </div>
              </div>
              <div className="col-6 results-head-right">
                <div className="result-count" style={{fontSize:"1.4em", color:"black"}}>
                  <div style = {{cursor:"pointer"}} 
                        onClick={(e) => {
                          e.preventDefault();
                          setFilterTab((prev) => {
                            return !prev;
                          });
                        }}>
                    <Icon icon="tabler:filter" style={{marginRight:10}}/>
                    Bộ lọc
                  </div>
                </div>
              </div>
            </div>
            <div className="row search-result-content">
              <div className="refinement-bar d-none d-xl-block col-xl-3">
                <div className="refinement-wrapper">
                  <div className="refinements">
                    <div className="block block-sort d-none d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">
                          <span>Sắp xếp</span>
                        </h2>
                        <div id="sort-collapse" className="collapse show">
                          <div className="card-body">
                            <ul>
                              {/* <li
                                className={
                                  sortC === 1
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(1);
                                }}
                              >
                                <span>Tên từ a-z</span>
                              </li>
                              <li
                                className={
                                  sortC === 2
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(2);
                                }}
                              >
                                <span>Tên từ z-a</span>
                              </li> */}
                              <li
                                className={
                                  sortC === 3
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(3);
                                }}
                              >
                                <span>Giá tăng dần</span>
                              </li>
                              <li
                                className={
                                  sortC === 4
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(4);
                                }}
                              >
                                <span>Giá giảm dần</span>
                              </li>
                              <li
                                className={
                                  sortC === 5
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(5);
                                }}
                              >
                                <span>Mới nhất</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block block-sort d-none d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">Bộ lọc</h2>
                        <div id="sort-collapse" className="collapse show">
                          <div className="card-body">
                            <ul>
                              <li
                                className="liNopadding"
                                style={{
                                  paddingTop: "0",
                                  paddingBottom: "0px",
                                }}
                                value="5"
                                onClick={(e) => {
                                  setDiscount((prev) => {
                                    return !prev;
                                  });
                                }}
                              >
                                <span
                                  style={{ paddingLeft: "20px" }}
                                  className={
                                    discount
                                      ? "checkboxSpec checked"
                                      : "checkboxSpec"
                                  }
                                >
                                  Đang giảm giá
                                  <input
                                    type="hidden"
                                    id="topping"
                                    name="topping"
                                    value="Paneer"
                                  />
                                </span>
                              </li>
                              <li
                                className="liNopadding"
                                style={{
                                  paddingTop: "0",
                                  paddingBottom: "0px",
                                }}
                                value="4"
                              ></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block block-sort d-none d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">Tính năng</h2>
                        <div id="sort-collapse" className="collapse show div-with-custom-scrollbar" style={{maxHeight:150, overflow:"auto"}}>
                          <div className="card-body">
                            <ul>
                              {collectionList?.length > 0 && ((collectionList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))).filter((col)=>{return col.type === 0 && ((col.ofProductType).includes(Number(productType))||Number(productType)===5)})).map((ele,index)=>{
                                return (
                                  <React.Fragment 
                                  key = {index}>
                                  <li
                                    className="liNopadding"
                                    style={{
                                      paddingTop: "0",
                                      paddingBottom: "10px",
                                    }}
                                    value="5"
                                    onClick={(e) => {
                                      let temp = [...collectionChooseList]
                                      if(temp.includes(ele._id)){
                                        setCollectionChooseList([...temp.filter(item=> item !== ele._id)])
                                      }else{
                                        setCollectionChooseList([...temp,ele._id])
                                      }
                                    }}
                                  >
                                    <span
                                      style={{ paddingLeft: "20px", display:"block" }}
                                      className={
                                        (collectionChooseList.includes(ele._id))
                                          ? "checkboxSpec checked"
                                          : "checkboxSpec"
                                      }
                                    >
                                        {ele.name}

                                      <input
                                        type="hidden"
                                        id="topping"
                                        name="topping"
                                        value="Paneer"
                                      />
                                    </span>
                                  </li>
                                  </React.Fragment>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block block-sort d-none d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">Bộ sưu tập</h2>
                        <div id="sort-collapse" className="collapse show div-with-custom-scrollbar" style={{maxHeight:150, overflow:"auto"}}>
                          <div className="card-body">
                            <ul>
                              {collectionList?.length > 0 && ((collectionList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))).filter((col)=>{return col.type === 1 && ((col.ofProductType).includes(Number(productType))||Number(productType)===5)})).map((ele,index)=>{
                                return (
                                  <React.Fragment 
                                  key = {index}>
                                  <li
                                    className="liNopadding"
                                    style={{
                                      paddingTop: "0",
                                      paddingBottom: "10px",
                                    }}
                                    value="5"
                                    onClick={(e) => {
                                      let temp = [...collectionChooseList]
                                      if(temp.includes(ele._id)){
                                        setCollectionChooseList([...temp.filter(item=> item !== ele._id)])
                                      }else{
                                        setCollectionChooseList([...temp,ele._id])
                                      }
                                    }}
                                  >
                                    <span
                                      style={{ paddingLeft: "20px", display:"block" }}
                                      className={
                                        (collectionChooseList.includes(ele._id))
                                          ? "checkboxSpec checked"
                                          : "checkboxSpec"
                                      }
                                    >
                                        {ele.name}

                                      <input
                                        type="hidden"
                                        id="topping"
                                        name="topping"
                                        value="Paneer"
                                      />
                                    </span>
                                  </li>
                                  </React.Fragment>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" block-sort d-none d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">
                          <span>Màu sắc</span>
                        </h2>
                        <ul className="attributes-refinement values content checkbox-selection filter-by-filterColor  refinement-colour">
                          <li title="Màu be" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Nude");
                              }}
                              className={`swatch-circle-0140 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Nude") ? " selected" : ""
                              }`}
                            >
                              Nude
                            </a>
                          </li>
                          <li title="Đen" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Black");
                              }}
                              className={`swatch-circle-0010 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Black") ? " selected" : ""
                              }`}
                            >
                              Black
                            </a>
                          </li>

                          <li title="Xanh dương" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Blue");
                              }}
                              className={`swatch-circle-0020 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Blue") ? " selected" : ""
                              }`}
                            >
                              Blue
                            </a>
                          </li>

                          <li title="Xanh lá" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Green");
                              }}
                              className={`swatch-circle-0030 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Green") ? " selected" : ""
                              }`}
                            >
                              Green
                            </a>
                          </li>

                          <li title="Xám" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Grey");
                              }}
                              className={`swatch-circle-0040 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Grey") ? " selected" : ""
                              }`}
                            >
                              Grey
                            </a>
                          </li>

                          <li title="Xanh nhạt" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Light Blue");
                              }}
                              className={`swatch-circle-0130 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Light Blue")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Light Blue
                            </a>
                          </li>

                          <li title="Xanh đen" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Navy");
                              }}
                              className={`swatch-circle-0150 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Navy") ? " selected" : ""
                              }`}
                            >
                              Navy
                            </a>
                          </li>
                          <li title="Cam" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Orange");
                              }}
                              className={`swatch-circle-0160 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Orange")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Orange
                            </a>
                          </li>
                          <li title="Hồng" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Pink");
                              }}
                              className={`swatch-circle-0170 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Pink") ? " selected" : ""
                              }`}
                            >
                              Pink
                            </a>
                          </li>
                          <li title="Tím" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Purple");
                              }}
                              className={`swatch-circle-0070 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Purple")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Purple
                            </a>
                          </li>

                          <li title="Đỏ" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Red");
                              }}
                              className={`swatch-circle-0090 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Red") ? " selected" : ""
                              }`}
                            >
                              Red
                            </a>
                          </li>

                          <li title="Bạc" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Silver");
                              }}
                              className={`swatch-circle-0180 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Silver")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Silver
                            </a>
                          </li>

                          <li title="Trắng" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("White");
                              }}
                              className={`swatch-circle-0120 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("White") ? " selected" : ""
                              }`}
                            >
                              White
                            </a>
                          </li>

                          <li title="Vàng" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Yellow");
                              }}
                              className={`swatch-circle-0110 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Yellow")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Yellow
                            </a>
                          </li>
                          <li title="Nâu" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Brown");
                              }}
                              className={`swatch-circle-0200 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Brown") ? " selected" : ""
                              }`}
                            >
                              Brown
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className=" block-sort d-none d-xl-block">
                    <div className="block-head sort-header">
                      <h2 className="block-title" style={{ paddingTop: 0 }}>
                        <span>Giá</span>
                      </h2>
                    </div>
                    <div>
                      <Box sx={{ width: "100%" }}>
                        <Slider
                          getAriaLabel={() => "Minimum distance"}
                          value={price}
                          onChange={handleChange1}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) => {
                            return helper1.moneyFormat(value);
                          }}
                          step={100000}
                          min={0}
                          max={25000000}
                          disableSwap
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-grid-wrapper col-12 col-xl-9">
                <div className="product-grid row" style={{minHeight:"80%"}}  itemScope itemType="http://schema.org/ItemList">
                  {/* //productcontainer */}
                  {productListShow &&
                    productListShow.map((ele, index) => {
                      return (
                        <ProductContainer
                          key={index}
                          discountList={discountList}
                          initColor={initColor}
                          initSize={initSize}
                          setCartChange={setCartChange}
                          cartEvent = {cartEvent}
                          setCartEvent = {setCartEvent}
                          product={ele}
                          id = {`product${index}`}
                        />
                      );
                    })}

                  <div className="col-12 grid-footer"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
        {/* search tab */}
        <div
          className={`site-nav style--sidebar ${
            filterTab ? `active` : ``
          } show-cart`}
        >
          <div id="site-cart" className="site-nav-container">
            <div className="site-nav-container-last">
              <p style={{ lineHeight: "21px", paddingBottom:20 }} className="title">
                Bộ lọc
              </p>
              <div className="search-box wpo-wrapper-search">
              <div className="refinement-wrapper non-border">
                  <div className="refinements">
                    <div className="block block-sort d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">
                          <span>Sắp xếp</span>
                        </h2>
                        <div id="sort-collapse" className="collapse show">
                          <div className="card-body">
                            <ul>
                              <li
                                className={
                                  sortC === 3
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(3);
                                }}
                              >
                                <span>Giá tăng dần</span>
                              </li>
                              <li
                                className={
                                  sortC === 4
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(4);
                                }}
                              >
                                <span>Giá giảm dần</span>
                              </li>
                              <li
                                className={
                                  sortC === 5
                                    ? "sort-item selected"
                                    : "sort-item"
                                }
                                onClick={(e) => {
                                  setSortC(5);
                                }}
                              >
                                <span>Mới nhất</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block block-sort d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">Bộ lọc</h2>
                        <div id="sort-collapse" className="collapse show">
                          <div className="card-body">
                            <ul>
                              <li
                                className="liNopadding"
                                style={{
                                  paddingTop: "0",
                                  paddingBottom: "0px",
                                }}
                                value="5"
                                onClick={(e) => {
                                  setDiscount((prev) => {
                                    return !prev;
                                  });
                                }}
                              >
                                <span
                                  style={{ paddingLeft: "20px" }}
                                  className={
                                    discount
                                      ? "checkboxSpec checked"
                                      : "checkboxSpec"
                                  }
                                >
                                  Đang giảm giá
                                  <input
                                    type="hidden"
                                    id="topping"
                                    name="topping"
                                    value="Paneer"
                                  />
                                </span>
                              </li>
                              <li
                                className="liNopadding"
                                style={{
                                  paddingTop: "0",
                                  paddingBottom: "0px",
                                }}
                                value="4"
                              ></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block block-sort d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">Tính năng</h2>
                        <div id="sort-collapse" className="collapse show div-with-custom-scrollbar" style={{maxHeight:150, overflow:"auto"}}>
                          <div className="card-body">
                            <ul>
                              {collectionList?.length > 0 && ((collectionList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))).filter((col)=>{return col.type === 0 && ((col.ofProductType).includes(Number(productType))||Number(productType)===5)})).map((ele,index)=>{
                                return (
                                  <React.Fragment 
                                  key = {index}>
                                  <li
                                    className="liNopadding"
                                    style={{
                                      paddingTop: "0",
                                      paddingBottom: "10px",
                                    }}
                                    value="5"
                                    onClick={(e) => {
                                      let temp = [...collectionChooseList]
                                      if(temp.includes(ele._id)){
                                        setCollectionChooseList([...temp.filter(item=> item !== ele._id)])
                                      }else{
                                        setCollectionChooseList([...temp,ele._id])
                                      }
                                    }}
                                  >
                                    <span
                                      style={{ paddingLeft: "20px", display:"block" }}
                                      className={
                                        (collectionChooseList.includes(ele._id))
                                          ? "checkboxSpec checked"
                                          : "checkboxSpec"
                                      }
                                    >
                                        {ele.name}

                                      <input
                                        type="hidden"
                                        id="topping"
                                        name="topping"
                                        value="Paneer"
                                      />
                                    </span>
                                  </li>
                                  </React.Fragment>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block block-sort d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">Bộ sưu tập</h2>
                        <div id="sort-collapse" className="collapse show div-with-custom-scrollbar" style={{maxHeight:150, overflow:"auto"}}>
                          <div className="card-body">
                            <ul>
                              {collectionList?.length > 0 && ((collectionList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))).filter((col)=>{return col.type === 1 && ((col.ofProductType).includes(Number(productType))||Number(productType)===5)})).map((ele,index)=>{
                                return (
                                  <React.Fragment 
                                  key = {index}>
                                  <li
                                    className="liNopadding"
                                    style={{
                                      paddingTop: "0",
                                      paddingBottom: "10px",
                                    }}
                                    value="5"
                                    onClick={(e) => {
                                      let temp = [...collectionChooseList]
                                      if(temp.includes(ele._id)){
                                        setCollectionChooseList([...temp.filter(item=> item !== ele._id)])
                                      }else{
                                        setCollectionChooseList([...temp,ele._id])
                                      }
                                    }}
                                  >
                                    <span
                                      style={{ paddingLeft: "20px", display:"block" }}
                                      className={
                                        (collectionChooseList.includes(ele._id))
                                          ? "checkboxSpec checked"
                                          : "checkboxSpec"
                                      }
                                    >
                                        {ele.name}

                                      <input
                                        type="hidden"
                                        id="topping"
                                        name="topping"
                                        value="Paneer"
                                      />
                                    </span>
                                  </li>
                                  </React.Fragment>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" block-sort d-xl-block">
                      <div className="block-head sort-header">
                        <h2 className="block-title">
                          <span>Màu sắc</span>
                        </h2>
                        <ul className="attributes-refinement values content checkbox-selection filter-by-filterColor  refinement-colour">
                          <li title="Màu be" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Nude");
                              }}
                              className={`swatch-circle-0140 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Nude") ? " selected" : ""
                              }`}
                            >
                              Nude
                            </a>
                          </li>
                          <li title="Đen" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Black");
                              }}
                              className={`swatch-circle-0010 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Black") ? " selected" : ""
                              }`}
                            >
                              Black
                            </a>
                          </li>

                          <li title="Xanh dương" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Blue");
                              }}
                              className={`swatch-circle-0020 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Blue") ? " selected" : ""
                              }`}
                            >
                              Blue
                            </a>
                          </li>

                          <li title="Xanh lá" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Green");
                              }}
                              className={`swatch-circle-0030 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Green") ? " selected" : ""
                              }`}
                            >
                              Green
                            </a>
                          </li>

                          <li title="Xám" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Grey");
                              }}
                              className={`swatch-circle-0040 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Grey") ? " selected" : ""
                              }`}
                            >
                              Grey
                            </a>
                          </li>

                          <li title="Xanh nhạt" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Light Blue");
                              }}
                              className={`swatch-circle-0130 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Light Blue")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Light Blue
                            </a>
                          </li>

                          <li title="Xanh đen" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Navy");
                              }}
                              className={`swatch-circle-0150 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Navy") ? " selected" : ""
                              }`}
                            >
                              Navy
                            </a>
                          </li>
                          <li title="Cam" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Orange");
                              }}
                              className={`swatch-circle-0160 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Orange")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Orange
                            </a>
                          </li>
                          <li title="Hồng" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Pink");
                              }}
                              className={`swatch-circle-0170 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Pink") ? " selected" : ""
                              }`}
                            >
                              Pink
                            </a>
                          </li>
                          <li title="Tím" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Purple");
                              }}
                              className={`swatch-circle-0070 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Purple")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Purple
                            </a>
                          </li>

                          <li title="Đỏ" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Red");
                              }}
                              className={`swatch-circle-0090 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Red") ? " selected" : ""
                              }`}
                            >
                              Red
                            </a>
                          </li>

                          <li title="Bạc" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Silver");
                              }}
                              className={`swatch-circle-0180 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Silver")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Silver
                            </a>
                          </li>

                          <li title="Trắng" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("White");
                              }}
                              className={`swatch-circle-0120 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("White") ? " selected" : ""
                              }`}
                            >
                              White
                            </a>
                          </li>

                          <li title="Vàng" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Yellow");
                              }}
                              className={`swatch-circle-0110 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Yellow")
                                  ? " selected"
                                  : ""
                              }`}
                            >
                              Yellow
                            </a>
                          </li>
                          <li title="Nâu" className="color-attribute ">
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                colorHandle("Brown");
                              }}
                              className={`swatch-circle-0200 swatch-circle refinement-selection color-value swatch-mark${
                                colorFilter.includes("Brown") ? " selected" : ""
                              }`}
                            >
                              Brown
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className=" block-sort d-xl-block">
                    <div className="block-head sort-header">
                      <h2 className="block-title" style={{ paddingTop: 0 }}>
                        <span>Giá</span>
                      </h2>
                    </div>
                    <div>
                      <Box sx={{ width: "100%" }}>
                        <Slider
                          getAriaLabel={() => "Minimum distance"}
                          value={price}
                          onChange={handleChange1}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) => {
                            return helper1.moneyFormat(value);
                          }}
                          step={100000}
                          min={0}
                          max={25000000}
                          disableSwap
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="site-close-handle"
            onClick={() => {
              setFilterTab(false);
            }}
          >
            <Icon icon="material-symbols:close" />
          </button>
        </div>
        <div
          className={`site-overlay ${filterTab ? `active` : ``}`}
          onClick={() => {
            setFilterTab(false);
          }}
        ></div>
    </React.Fragment>
  );
};

export default ProductList;
