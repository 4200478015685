const apiURL = process.env.REACT_APP_API_ENDPOINT;

const getProductList = async (body) => {
  let productList = [];

  try {
    const res = await fetch(`${apiURL}/product/product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        productList = [...result.productList];
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }

  return productList;
};

const getOneProduct = async (SEOLink) => {
  let rs = {};

  try {
    const res = await fetch(`${apiURL}/product/productdetailat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ SEOLink }),
    });
    if (res.ok) {
      const result = await res.json();
      rs = { ...result };
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }

  return rs;
};

const getOneInvoice = async (id) => {
  let rs = {};

  try {
    const res = await fetch(`${apiURL}/invoice`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    if (res.ok) {
      const result = await res.json();
      rs = { ...result };
      return rs;
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }

  return rs;
};

const updateCart = async (cart) => {
  let rs = { success: false };
  try {
    const res = await fetch(`${apiURL}/product/cart`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cart,
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        rs.success = true;
        rs.cartList = result.cartList;
        rs.gift = result.gift;
        rs.newCart = result.newCart
          ? JSON.stringify(result.newCart)
          : JSON.stringify({});
        rs.cartPrice = result.cartPrice;
        rs.quantityDiscountValue = result.quantityDiscountValue;
        rs.totalValueDiscountValue = result.totalValueDiscountValue;
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return rs;
};

const getNewAndPopular = async () => {
  let rs = { new: [], popular: [] };
  try {
    const res = await fetch(`${apiURL}/product/product`, {
      method: "GET",
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        if (result.new.length <= 4 && result.new.length > 0) {
          rs.new = Array(Math.ceil(5 / result.new.length))
            .fill(result.new)
            .flat();
        } else {
          rs.new = result.new;
        }
        if (result.popular.length <= 4 && result.popular.length > 0) {
          rs.popular = Array(Math.ceil(5 / result.popular.length))
            .fill(result.popular)
            .flat();
        } else {
          rs.popular = result.popular;
        }
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return rs;
};

const getRelatedProduct = async (product) => {
  let rs = [];
  try {
    const res = await fetch(`${apiURL}/product/relatedproduct`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SEOLink: product.SEOLink ?? "",
        productType: Number(product.productType ?? 0),
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        if (result.list.length <= 4 && result.list.length > 0) {
          rs = Array(Math.ceil(5 / result.list.length))
            .fill(result.list)
            .flat();
        } else {
          rs = result.list;
        }
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return rs;
};

const getCityList = async () => {
  let rs = [];
  try {
    const res = await fetch(`${apiURL}/city`, {
      method: "GET",
    });
    if (res.ok) {
      const result = await res.json();
      rs = [...(result.cityList ?? [])];
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return rs;
};

const getChannel = async () => {
  let channel = {};
  try {
    const res = await fetch(`${apiURL}/channel`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: "American Tourister",
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        channel = { ...(result.channel ?? {}) };
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return channel;
};
const getStoreList = async () => {
  let storeList = [];
  try {
    const res = await fetch(`${apiURL}/store/show`, {
      method: "GET",
    });
    if (res.ok) {
      const result = await res.json();
      storeList = [...(result.storeList ?? [])];
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return storeList;
};
const getDiscount = async () => {
  let priceInfo = {};

  try {
    const res = await fetch(`${apiURL}/discount/at`, {
      method: "GET",
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        priceInfo = { ...result.priceInfo };
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }

  return priceInfo;
};

const getProductGroupList = async () => {
  let list = [];

  try {
    const res = await fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        list = [...(result.list ?? [])];
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }

  return list;
};

const getNews = async (seoLink) => {
  let list = {};

  try {
    const res = await fetch(`${apiURL}/news/search`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        seoLink
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        list = {...result}
      }
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }

  return list;
};

const getPaymentMethod = (method) => {
  let methods = {
    1: "Tiền mặt",
    2: "Chuyển khoản ngân hàng",
    3: "Thanh toán online qua OnePay",
    4: "Thanh toán qua Payoo"
  };
  return methods[method] ?? "";
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDiscount,
  getProductList,
  getOneProduct,
  updateCart,
  getNewAndPopular,
  getCityList,
  getChannel,
  getStoreList,
  getRelatedProduct,
  getOneInvoice,
  getPaymentMethod,
  getProductGroupList,
  getNews
};
