import React from "react";
import "../../styles/Support/support.css";

function SecurityMethod({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultSecurity">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor: "black",
          }}
        >
          <h1>Chính sách bảo mật</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <h3>1. Mục đích và phạm vi thu thập</h3>
                      <ul>
                        <li>
                          Americantourister.vn không bán, chia sẻ hay trao đổi
                          thông tin cá nhân của khách hàng thu thập trên trang
                          web cho một bên thứ ba nào khác.
                        </li>
                        <li>
                          Thông tin cá nhân thu thập được sẽ chỉ được sử dụng
                          trong nội bộ công ty.
                        </li>
                        <li>
                          Khi bạn liên hệ đăng ký dịch vụ, thông tin cá nhân mà
                          Americantourister.vn thu thập bao gồm:
                        </li>
                        <ol>
                          <li>Họ và tên</li>
                          <li>Địa chỉ</li>
                          <li>Số điện thoại</li>
                          <li>Email</li>
                        </ol>
                        <li>
                          Ngoài thông tin cá nhân là các thông tin về dịch vụ:
                        </li>
                        <ol>
                          <li>Tên sản phẩm</li>
                          <li>Số lượng</li>
                          <li>Thời gian giao nhận sản phẩm</li>
                        </ol>
                      </ul>
                      <h3>2. Phạm vi sử dụng thông tin</h3>
                      <p>
                        Thông tin cá nhân thu thập được sẽ chỉ được
                        Americantourister.vn sử dụng trong nội bộ công ty và cho
                        một hoặc tất cả các mục đích sau đây:
                      </p>
                      <ul>
                        <li>Hỗ trợ khách hàng.</li>
                        <li>Cung cấp thông tin liên quan đến dịch vụ.</li>
                        <li>
                          Xử lý đơn đặt hàng và cung cấp dịch vụ và thông tin
                          qua trang web của chúng tôi theo yêu cầu của bạn.
                        </li>
                        <li>
                          Chúng tôi có thể sẽ gửi thông tin sản phẩm, dịch vụ
                          mới, thông tin về các sự kiện sắp tới hoặc thông tin
                          tuyển dụng nếu quý khách đăng kí nhận email thông báo.
                        </li>
                        <li>
                          Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp
                          để hỗ trợ quản lý tài khoản khách hàng, xác nhận và
                          thực hiện các giao dịch tài chính liên quan đến các
                          khoản thanh toán trực tuyến của bạn.
                        </li>
                      </ul>
                      <h3>3. Thời gian lưu trữ thông tin</h3>
                      <p>
                        Đối với thông tin cá nhân, Americantourister.vn chỉ xóa
                        đi dữ liệu này nếu khách hàng có yêu cầu, khách hàng yêu
                        cầu gửi mail về info@americantourister-vietnam.com
                      </p>

                      <h3>
                        4. Những người hoặc tổ chức có thể được tiếp cận với
                        thông tin cá nhân
                      </h3>
                      <p>
                        Đối tượng được tiếp cận với thông tin cá nhân của khách
                        hàng thuộc một trong những trường hợp sau:
                      </p>
                      <ul>
                        <li>CÔNG TY TNHH BANYAN VIỆT NAM.</li>
                        <li>
                          Các đối tác có ký hợp động thực hiện 1 phần dịch vụ do
                          CÔNG TY TNHH BANYAN VIỆT NAM. Các đối tác này sẽ nhận
                          được những thông tin theo thỏa thuận hợp đồng (có thể
                          1 phần hoặc toàn bộ thông tin tuy theo điều khoản hợp
                          đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ
                          do Công ty cung cấp.
                        </li>
                      </ul>
                      <h3>
                        5. Địa chỉ của đơn vị thu thập và quản lý thông tin:
                      </h3>
                      <p>
                        <strong>CÔNG TY TNHH BANYAN VIỆT NAM</strong>
                      </p>
                      <ul>
                        <li>
                          Địa chỉ: L17-11 Tầng 17 Tòa nhà Vincom Center, số 72
                          Lê Thánh Tôn, P. Bến Nghé, Q. 1, Tp. Hồ Chí Minh.
                        </li>
                        <li>Điện thoại: (028) 38247 886</li>
                        <li>Website: Americantourister.vn</li>
                        <li>Email: info@americantourister-vietnam.com</li>
                        <li>
                          Liên hệ:&nbsp;
                          <u>
                            <a href="/stores">Hệ thống cửa hàng</a>
                          </u>
                        </li>
                      </ul>
                      <h3>
                        6. Phương tiện và công cụ để người dùng tiếp cận và
                        chỉnh sửa dữ liệu cá nhân của mình
                      </h3>
                      <p>
                        Americantourister.vn không thu thập thông tin khách hàng
                        qua trang web, thông tin cá nhân khách hàng được thực
                        hiện thu thập qua email liên hệ đặt mua sản phẩm, dịch
                        vụ gửi về hộp mail của chúng tôi:
                        info@americantourister-vietnam.com hoặc số điện thoại
                        liên hệ đặt mua sản phẩm gọi về 18006360.
                      </p>
                      <p>
                        Bạn có thể liên hệ địa chỉ email cùng số điện thoại trên
                        để yêu cầu Americantourister.vn chỉnh sửa dữ liệu cá
                        nhân của mình.
                      </p>
                      <h3>
                        7. Cơ chế tiếp nhận và giải quyết khiếu nại của người
                        tiêu dùng liên quan đến việc thông tin cá nhân bị sử
                        dụng sai mục đích hoặc phạm vi đã thông báo.
                      </h3>
                      <p>
                        Tại Americantourister.vn , việc bảo vệ thông tin cá nhân
                        của bạn là rất quan trọng, bạn được đảm bảo rằng thông
                        tin cung cấp cho chúng tôi sẽ được mật
                        Americantourister.vn cam kết không chia sẻ, bán hoặc cho
                        thuê thông tin cá nhân của bạn cho bất kỳ người nào
                        khác. Americantourister.vn cam kết chỉ sử dụng các thông
                        tin của bạn vào các trường hợp sau:
                      </p>
                      <ul>
                        <li>
                          Nâng cao chất lượng dịch vụ dành cho khách hàng.
                        </li>
                        <li>Giải quyết các tranh chấp, khiếu nại.</li>
                        <li>Khi cơ quan pháp luật có yêu cầu.</li>
                      </ul>
                      <p>
                        Americantourister.vn hiểu rằng quyền lợi của bạn trong
                        việc bảo vệ thông tin cá nhân cũng chính là trách nhiệm
                        của chúng tôi nên trong bất kỳ trường hợp có thắc mắc,
                        góp ý nào liên quan đến chính sách bảo mật của
                        Americantourister.vn , và liên quan đến việc thông tin
                        cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông
                        báo vui lòng liên hệ qua số hotline 18006360 hoặc email:
                        info@americantourister-vietnam.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SecurityMethod;
