import React from "react";
import { Icon } from "@iconify/react";
import "../../styles/Footer/footer.css";
import BCTLogo from "../../assets/logo/logosalenoti_1dd61a690d1a4460a0ec582e0efa4673.webp"

const Footer = ({ setCartChange, cartChange }) => {
  return (
    <React.Fragment>
      <footer id="footer">
        <div className="container">
          <div className="footerMain">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footerMainAddress">
                  <h4>HOTLINE</h4>
                  <ul>
                    <li className="contact-2">
                      <Icon icon="ic:baseline-phone" />
                      <span style={{ color: "#bbb" }}>
                        1800 6360
                      </span>
                    </li>
                  </ul>
                  <hr></hr>
                </div>
                <div className="footerMainAddress">
                  <h4>BẢO HÀNH</h4>
                  <ul>
                    <li className="contact-2">
                      <Icon icon="ic:baseline-phone" />
                      <span style={{ color: "#bbb" }}>
                      1800 6360 (bấm số 3)
                      </span>
                    </li>
                  </ul>
                  <hr></hr>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footerMainList menuclickmb">
                  <h4>B2B (BÁN SỈ)</h4>
                  <ul>
                    <li className="contact-2">
                      <Icon icon="mdi:envelope" />
                      <span style={{ color: "#bbb" }}>
                        b2b@samsonite-vietnam.com
                      </span>
                    </li>
                    <li className="contact-2">
                      <Icon icon="ic:baseline-phone" />
                      <span style={{ color: "#bbb" }}>
                        Miền Bắc: 0909 557 719
                      </span>
                    </li>
                    <li className="contact-2">
                      <Icon icon="ic:baseline-phone" />
                      <span style={{ color: "#bbb" }}>
                        Miền Trung: 0909 880 710
                      </span>
                    </li>
                    <li className="contact-2">
                      <Icon icon="ic:baseline-phone" />
                      <span style={{ color: "#bbb" }}>
                        Miền Nam: 0938 917 108
                      </span>
                    </li>
                  </ul>
                  <hr></hr>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footerMainList  menuclickmb">
                  <h4 className="">HỖ TRỢ KHÁCH HÀNG</h4>
                  <div className="FooterMainListToggle">
                    <ul style={{ display: "block" }}>
                      <li className="item">
                        <a
                          href="/support/terms-and-conditions"
                          title="Điều khoản và điều kiện"
                        >
                          Điều khoản và điều kiện
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/oder-method"
                          title="Phương thức đặt hàng"
                        >
                          Phương thức đặt hàng
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/receive-method"
                          title="Phương thức giao nhận"
                        >
                          Phương thức giao nhận
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/payment-method"
                          title="Phương thức thanh toán"
                        >
                          Phương thức thanh toán
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/precheck-policy"
                          title="Chính sách kiểm hàng"
                        >
                          Chính sách kiểm hàng
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/exchange-and-return-policy"
                          title="Chính sách đổi trả hàng"
                        >
                          Chính sách đổi trả hàng
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/report-policy"
                          title="Chính sách xử lý khiếu nại"
                        >
                          Chính sách xử lý khiếu nại
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/security-policy"
                          title="Chính sách bảo mật"
                        >
                          Chính sách bảo mật
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/support/insurance-policy"
                          title="Chính sách bảo hành"
                        >
                          Chính sách bảo hành
                        </a>
                      </li>
                      <li className="item">
                        <a
                          href="/posts"
                          title="Bài viết"
                        >
                          Bài viết
                        </a>
                      </li>
                    </ul>
                    <hr></hr>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footerMainList">
                  <h4>THEO DÕI CHÚNG TÔI</h4>
                  <div className="FooterMainListToggle">
                    <div
                      className="fb-page fb_iframe_widget"
                      data-hide-cta="true"
                      data-href="https://www.facebook.com/AmericanTourister.Vietnam"
                      data-tabs="timeline"
                      data-width=""
                      data-height="100"
                      data-small-header="false"
                      data-adapt-container-width="true"
                      data-hide-cover="false"
                      data-show-facepile="true"
                      fb-xfbml-state="rendered"
                      fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=255&amp;height=100&amp;hide_cover=false&amp;hide_cta=true&amp;href=https%3A%2F%2Fwww.facebook.com%2FSamsonite.Vietnam&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width="
                    >
                      <span
                        style={{
                          verticalAlign: "bottom",
                          width: "255px",
                          height: "130px",
                        }}
                      >
                        <iframe
                          name="f39ba13df827b3"
                          width="1000px"
                          height="100px"
                          data-testid="fb:page Facebook Social Plugin"
                          title="fb:page Facebook Social Plugin"
                          frameBorder="0"
                          allowtransparency="true"
                          allowFullScreen={true}
                          scrolling="no"
                          allow="encrypted-media"
                          src="https://www.facebook.com/v13.0/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;container_width=255&amp;height=100&amp;hide_cover=false&amp;hide_cta=true&amp;href=https%3A%2F%2Fwww.facebook.com%2FAmericanTourister.Vietnam&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width="
                          style={{
                            border: "none",
                            visibility: "visible",
                            width: "255px",
                            height: "130px",
                          }}
                          className=""
                          data-dashlane-frameid="330"
                        ></iframe>
                      </span>
                    </div>
                    <div className="newletterSocial">
                      <ul className="is-showthis">
                        <li className="newletterSocialFacebook">
                          <a href="https://www.facebook.com/AmericanTourister.Vietnam">
                            <i className="lni lni-facebook"></i>
                          </a>
                        </li>
                        <li className="newletterSocialInstagram">
                          <a href="https://www.instagram.com/americantourister.vn/">
                            <i className="lni lni-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerBottom">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-10 col-md-10 col-sm-12 col-12"
                style={{ textAlign: "left", color: "#bbb" }}
              >
                <p>
                  <strong>CÔNG TY TNHH BANYAN VIỆT NAM</strong>
                  <br />
                  <strong>Giấy CNĐKDN:</strong> 0314386562
                  <br />
                  <strong>Cơ quan cấp:</strong> Sở kế hoạch và Đầu tư TP.HCM
                  <br />
                  <strong>Địa chỉ:</strong> L17-11, tầng 17, Vincom Center Đồng
                  Khởi, 72 Lê Thánh Tôn, Phường Bến Nghé, Quận 1, Tp. Hồ Chí
                  Minh, Việt Nam
                  <br />
                  <strong>Website:</strong> www.americantourister.vn -{" "}
                  <strong>Email:</strong> info@americantourister-vietnam.com -{" "}
                  <strong>Điện thoại:</strong> 028 3824 7886
                  <br />© 2022 Samsonite Vietnam. All rights reserved.
                </p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                <a href="http://online.gov.vn/Home/WebDetails/111696"><img alt ='logo' src={BCTLogo} width="200"/></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
