
const checkShowProduct =  (size, color, list, list2,sizeDiff) => {

    try {
        return list[size][color]['show'] 
    } catch (error) {
        return false
    }


};


const getNewProduct =  (size, color, list, list2,sizeDiff) => {

    try {
        for (let i in list[size]){
            if (list[size][i]['show'] === true ){
                return {newSize:size,newColor:i}
            }
        }
        for(let i in list){
            if(i!==size){
                for(let j in list[i]){
                    if (list[i][j]['show'] === true ){
                        return {newSize:i,newColor:j}
                    }
                }
            }
        }
        return {newSize:0,newColor:0}
    } catch (error) {
        return {size:0,color:0}
    }


};

export default {
  checkShowProduct,
  getNewProduct
};
