import React from "react";
import "../../styles/Support/support.css";

function ReturnMethod({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultReturn">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor: "black",
          }}
        >
          <h1>Chính sách đổi trả hàng</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <p>
                        Nhằm mang lại sự thuận tiện và hài lòng cho khách
                        hàng,&nbsp;<strong>americantourister.vn</strong>
                        &nbsp;có những chính sách phù hợp khi khách hàng có nhu
                        cầu đổi/ trả sản phẩm. Chúng tôi hy vọng những sản phẩm
                        khách hàng đã chọn là sản phẩm ưng ý nhất.
                      </p>
                      <p>
                        Việc đổi/ trả sản phẩm đi kèm theo các điều kiện cụ thể,
                        Quý khách vui lòng tham khảo thông tin chi tiết bên
                        dưới:
                      </p>
                      <h3>I. QUY ĐỊNH ĐỔI/ TRẢ HÀNG</h3>
                      <p>
                        <strong>1. Các trường hợp được đổi/ trả hàng</strong>
                      </p>
                      <p>1.1 - Hàng giao bị lỗi kĩ thuật:</p>
                      <p>
                        Sản phẩm chỉ được xem là lỗi kĩ thuật khi được xác nhận
                        từ chính Trung tâm kĩ thuật hoặc Trung tâm bảo hành của
                        sản phẩm.
                      </p>
                      <p>
                        Khi Quý khách gặp trục trặc với sản phẩm đặt mua
                        tại&nbsp;<strong>americantourister.vn</strong>, vui lòng
                        thực hiện các bước sau đây:
                      </p>
                      <ul>
                        <li>
                          Bước 1: Tự kiểm tra cách thức sử dụng sản phẩm, các
                          thao tác được hướng dẫn trong “Sách hướng dẫn sử dụng”
                          đi kèm với mỗi sản phẩm (nếu có).
                        </li>
                        <li>
                          Bước 2: Quý khách liên hệ với trung tâm kĩ thuật/ bảo
                          hành:&nbsp;<strong>HOTLINE: 1800 6360</strong>
                          &nbsp;nhấn phím 03 hoặc email:
                          info@americantourister-vietnam.com. Đối với một vài
                          dòng sản phẩm tiêu biểu, Quý khách có thể tìm thấy
                          thông tin bảo hành tại:&nbsp;
                          <a href="/stores">
                            <u>Hệ thống cửa hàng</u>
                          </a>
                          .
                        </li>
                        <li>
                          Bước 3: Trong vòng 07 ngày kể từ ngày nhận hàng, nếu
                          Quý khách được xác nhận từ Trung tâm kĩ thuật hoặc
                          Trung tâm bảo hành của sản phẩm rằng sản phẩm bị lỗi
                          kỹ thuật, quý khách vui lòng truy cập ngay Hướng dẫn
                          đổi trả hàng để bắt đầu quy trình đổi trả hàng.
                        </li>
                      </ul>
                      <p>
                        Nếu không thể liên hệ với trung tâm kĩ thuật/ bảo hành
                        của sản phẩm, hãy liên lạc ngay với
                        <strong>&nbsp;americantourister.vn</strong>&nbsp;
                        <strong>HOTLINE: 1800 6360</strong>
                      </p>
                      <p>
                        Theo kinh nghiệm của chúng tôi, hơn một nửa sản phẩm
                        hoàn về vì lí do lỗi kỹ thuật sau khi được bộ phận kĩ
                        thuật kiểm tra đều được đánh giá sản phẩm nằm trong điều
                        kiện sử dụng hoàn hảo. Vì vậy, để tiết kiệm thời gian và
                        thuận tiện cho Quý khách, vui lòng đọc kĩ hướng dẫn sử
                        dụng hoặc liên hệ tham vấn trực tiếp với bộ phận hỗ trợ
                        kĩ thuật của sản phẩm để chắc rằng sản phẩm đã được lắp
                        ráp, kết nối và vận hành chính xác trước khi gửi hàng về
                        lại americantourister.vn&nbsp;
                      </p>
                      <p>
                        1.2 - Hàng giao bị bể vỡ, sai nội dung hoặc bị thiếu:
                      </p>
                      <p>
                        <strong>
                          <strong>americantourister.vn</strong>
                        </strong>
                        &nbsp;khuyến khích Quý khách hàng phải kiểm tra tình
                        trạng bên ngoài của thùng hàng và sản phẩm trước khi
                        thanh toán để đảm bảo rằng hàng hóa được giao đúng chủng
                        loại, số lượng, màu sắc theo đơn đặt hàng và tình trạng
                        bên ngoài không bị tác động (bể vỡ/ trầy xước). Nếu gặp
                        trường hợp này, Quý khách vui lòng từ chối nhận hàng
                        và/hoặc báo ngay cho bộ phận hỗ trợ khách hàng&nbsp;
                        <strong>(HOTLINE: 1800 6360)</strong>&nbsp;để chúng tôi
                        có phương án xử lí kịp thời.
                      </p>
                      <p>
                        Trong trường hợp khách hàng đã thanh toán, nhận hàng và
                        sau đó phát hiện hàng hóa bị bể vỡ, sai nội dung hoặc
                        thiếu hàng, xin vui lòng chụp ảnh sản phẩm gửi về hộp
                        thư info@americantourister-vietnam.com để được chúng tôi
                        hỗ trợ các bước tiếp theo như đổi/trả hàng hoặc gửi sản
                        phẩm còn thiếu đến Quý khách.
                      </p>
                      <p>
                        Sau 48h kể từ khi Quý khách nhận hàng,&nbsp;
                        <strong>americantourister.vn</strong>&nbsp;có quyền từ
                        chối hỗ trợ cho những khiếu nại theo nội dung như trên.
                      </p>
                      <p>
                        <strong>
                          2. Danh mục miễn đổi/ trả (sản phẩm không áp dụng đổi
                          trả)
                        </strong>
                      </p>
                      <ul>
                        <li>
                          Sản phẩm khuyến mãi (có giá giảm từ 10% trở lên so với
                          giá gốc), hàng đồng giá.
                        </li>
                        <li>Sản phẩm đổi trả không do lỗi kỹ thuật</li>
                        <li>Phụ kiện</li>
                      </ul>
                      <strong>3. Điều kiện đổi hàng</strong>
                      <p>
                        Quý khách vui lòng đọc kỹ các quy định được nêu rõ trong
                        Chính sách đổi trả hàng của chúng tôi để đảm bảo rằng
                        sản phẩm/ hàng hóa yêu cầu đổi/ trả thoả mãn tất cả các
                        điều kiện sau đây:
                      </p>
                      <ul>
                        <li>
                          Sản phẩm thực hiện đổi trả phải được Quý khách đặt mua
                          online hoặc mua tại&nbsp;
                          <a href="/stores">
                            <u>hệ thống cửa hàng</u>&nbsp;
                          </a>
                        </li>
                        <li>
                          Còn nguyên đóng gói và bao bì không bị móp rách.
                        </li>
                        <li>
                          Tem/ phiếu bảo hành, tem thương hiệu, hướng dẫn kỹ
                          thuật và các quà tặng kèm theo (nếu có) v.v… phải còn
                          đầy đủ và nguyên vẹn.
                        </li>
                        <li>
                          Không có dữ liệu trong sản phẩm có thiết bị lưu trữ.
                        </li>
                        <li>
                          Không bị dơ bẩn, trầy xước, bể vỡ, hư hỏng, có mùi lạ
                          hoặc có dấu hiệu đã qua giặt tẩy hoặc qua sử dụng.
                        </li>
                        <li>
                          Hàng chỉ được chấp nhận đổi để lấy một sản phẩm tương
                          tự (cùng mẫu), một sản phẩm khác (cùng nhãn hàng) có
                          giá trị tương đương hoặc giá trị thấp hơn.
                        </li>
                        <li>
                          Yêu cầu Quý khách phải có hóa đơn mua hàng khi đổi
                          hàng. Công ty không chấp nhận việc đổi hàng nếu không
                          kèm chứng từ trên.
                        </li>
                        <li>
                          Các bộ phận, chi tiết, phụ kiện, tài liệu hướng dẫn sử
                          dụng, quà tặng kèm theo (nếu có), v.v… phải còn đầy đủ
                          và chưa có dấu hiệu sử dụng.
                        </li>
                        <li>Mỗi đơn hàng chỉ được hỗ trợ đổi 1 lần.</li>
                        <li>
                          Những sản phẩm đổi trả nếu không đáp ứng các điều kiện
                          nêu trên sẽ được tự động chuyển hoàn về địa chỉ đã
                          được Quý khách đăng ký trong đơn hàng
                        </li>
                      </ul>
                      <p>
                        <strong>4. Thời gian đổi trả hàng</strong>
                      </p>
                      <ul>
                        <li>
                          Thời gian áp dụng đổi trả hàng hóa mua tại hệ thống
                          cửa hàng thuộc hệ thống cửa hàng là 3-7 ngày.
                        </li>
                        <li>
                          Đối với khách hàng đặt hàng tại website&nbsp;
                          <strong>americantourister.vn</strong>, thời gian đổi
                          trả là 3-7 ngày tính từ ngày Quý khách nhận được sản
                          phẩm.
                        </li>
                      </ul>
                      <p>
                        <strong>5. Chi phí đổi trả hàng</strong>
                      </p>
                      <ul>
                        <li>
                          Đối với các sản phẩm đổi lại do lỗi kỹ thuật, khách
                          hàng sẽ được miễn phí đổi trả và được giao hàng miễn
                          phí tới địa điểm ghi trên phiếu yêu cầu đổi trả.
                        </li>
                        <li>
                          Đối với các sản phẩm đổi lại do ý chủ quan từ khách
                          hàng, Quý khách sẽ thanh toán phí vận chuyển 2
                          chiều.&nbsp;
                        </li>
                      </ul>
                      <h3>II. QUY ĐỊNH ĐỔI HÀNG VÀ HOÀN TIỀN</h3>
                      <p>
                        Tùy theo lý do hoàn trả sản phẩm và kết quả đánh giá
                        chất lượng, chúng tôi sẽ có những phương thức hoàn trả
                        phù hợp
                      </p>
                      <p>
                        <strong>1.&nbsp; Đổi sản phẩm mới</strong>
                      </p>
                      <p>
                        Hình thức này được áp dụng đối với các trường hợp sản
                        phẩm bị hỏng do lỗi của nhà sản xuất. Chúng tôi sẽ đổi
                        lại cho Quý khách sản phẩm mới có cùng mẫu mã (cùng mã
                        sản phẩm, cùng size, cùng nhãn hàng…).
                      </p>
                      <p>
                        Đối với trường hợp hệ thống của chúng tôi đã hết mã sản
                        phẩm như trong đơn hàng của Quý khách, chúng tôi sẽ đổi
                        cho Quý khách một sản phẩm khác (cùng nhãn hàng) có giá
                        trị tương đương.
                      </p>
                      <p>
                        <strong>2.&nbsp; Hoàn tiền</strong>
                      </p>
                      <p>
                        Việc hoàn tiền chỉ áp dụng đối với trường hợp sản phẩm
                        lỗi mà không có sản phẩm thay thế. Việc hoàn tiền trong
                        trường hợp này sẽ được thực hiện qua chuyển khoản ngân
                        hàng theo thông tin tài khoản mà Quý khách cung cấp.
                      </p>
                      <p>
                        Hiện tại, chúng tôi chưa áp dụng hình thức hoàn trả tiền
                        mặt tại văn phòng cho tất cả các trường hợp đổi/ trả sản
                        phẩm.
                      </p>
                      <p>
                        Thời gian xử lý: Nếu yêu cầu hoàn trả của khách hàng đáp
                        ứng đủ các điều kiện đổi/ trả hàng,&nbsp;
                        <strong>americantourister.vn</strong>&nbsp;sẽ thực hiện
                        thủ tục hoàn tiền cho khách hàng trong vòng 30 ngày kể
                        từ ngày nhận được thông tin tài khoản của Quý khách.
                      </p>
                      <h3>III. HƯỚNG DẪN ĐỔI TRẢ HÀNG HÓA</h3>
                      <ul>
                        <li>
                          Đối với khách hàng mua tại cửa hàng, vui lòng&nbsp;
                          <u>
                            <a target="_blank" href="/stores">
                              liên hệ trực tiếp cửa hàng
                            </a>
                          </u>
                          &nbsp;đã mua để đổi trả.
                        </li>
                        <li>
                          Đối với khách hàng mua hàng online, vui lòng xem thêm
                          các bước được hướng dẫn chi tiết bên dưới.
                        </li>
                      </ul>
                      <p>
                        Trong vòng 3-7 ngày sau khi nhận hàng, nếu có nhu cầu
                        đổi/ trả sản phẩm, Quý khách vui lòng thực hiện đầy đủ
                        các bước theo hướng dẫn dưới đây:
                      </p>
                      <p>
                        <strong>Bước 1:</strong>&nbsp;Kiểm tra điều kiện đổi trả
                        hàng
                      </p>
                      <p>
                        Vui lòng tham khảo chính sách đổi trả của chúng tôi ở
                        Mục I nhằm đảm bảo sản phẩm thoả mãn các điều kiện để
                        đổi/ trả hàng.
                      </p>
                      <p>
                        <strong>Bước 2:</strong>&nbsp;Đăng ký đổi trả
                      </p>
                      <p>
                        Quý khách vui lòng gửi yêu cầu đổi/ trả sản phẩm tới
                        email info@americantourister-vietnam.com với thông tin
                        như sau:
                      </p>
                      <p>Họ tên người đặt hàng.</p>
                      <ul>
                        <li>Số điện thoại người đặt hàng.</li>
                        <li>Mã đơn hàng</li>
                        <li>Đổi hay trả sản phẩm</li>
                        <li>Lý do Quý khách muốn đổi/ trả</li>
                        <li>
                          Mã sản phẩm + màu sắc sản phẩm + size sản phẩm mà Quý
                          khách có nhu cầu đổi
                        </li>
                      </ul>
                      <p>
                        Hoặc Quý khách có thể liên hệ Bộ phận&nbsp;
                        <strong>Chăm sóc khách hàng 18006360</strong>, cung cấp
                        mã đơn hàng để chúng tôi có hướng giải quyết phù hợp.
                      </p>
                      <p>
                        <strong>Bước 3:</strong>&nbsp;Đóng gói sản phẩm
                      </p>
                      <p>
                        Sản phẩm yêu cầu đổi/ trả cần được đóng gói cẩn thận
                        bằng bao bì hay hộp mà chúng tôi đã gửi đến trước đó,
                        bên trong bao gồm:
                      </p>
                      <ul>
                        <li>Sản phẩm muốn đổi/ trả.</li>
                        <li>Hóa đơn bán hàng.</li>
                      </ul>
                      <p>
                        Bên ngoài kiện hàng, Quý khách vui lòng cung cấp đầy đủ
                        thông tin:
                      </p>
                      <ul>
                        <li>Họ và tên</li>
                        <li>Số điện thoại</li>
                        <li>Địa chỉ</li>
                      </ul>
                      <p>
                        Lưu ý: Hàng không thể đổi mà không có hóa đơn của Công
                        ty TNHH Ban Yan Việt Nam đi kèm.
                      </p>
                      <p>
                        <strong>Bước 4:</strong>&nbsp;Gửi hàng về cho Công ty
                        TNHH Ban Yan Việt Nam
                      </p>
                      <p>
                        Quý khách liên hệ với các chi nhánh, văn phòng của đơn
                        vị chuyển DHL, VNPost, Viettel, Giao Hàng Nhanh,
                        Speedlink... hoặc bưu điện gần nhất để gửi kiện hàng
                        đổi/ trả về cho chúng tôi theo địa chỉ:
                      </p>

                          <ul>
                            <li>
                            <span style={{fontWeight:1100}}>
                              KHO TP.HCM: SỐ 424/11 NGUYỄN TẤT THÀNH, PHƯỜNG 18, QUẬN 4, TP.HCM
                              </span>
                            </li>
                            <li>
                            KHO ĐÀ NẴNG: KHO SANAKY, ĐƯỜNG SỐ 3, KHU CÔNG NGHIỆP HÒA KHÁNH, PHƯỜNG HÒA KHÁNH BẮC, QUẬN LIÊN CHIỂU, ĐÀ NẴNG
                            </li>
                          </ul>
                      <p>
                        <strong>Lưu ý:</strong>
                      </p>
                      <ul>
                        <li>
                          Quý khách vui lòng chuyển hàng đổi trả qua dịch vụ vận
                          chuyển. Công ty không áp dụng đổi trả trực tiếp tại
                          địa chỉ trên mong quý khách thông cảm.
                        </li>
                        <li>
                          Sau khi hoàn tất thủ tục gửi hàng, đơn vị vận chuyển
                          sẽ cung cấp mã số bưu gửi/ mã đơn hàng của kiện hàng
                          và biên nhận ghi rõ tình trạng sản phẩm cho Quý khách.
                        </li>
                        <li>
                          Chúng tôi không chịu trách nhiệm đối với các mất mát,
                          thất lạc hoặc bể vỡ v.v... của kiện hàng (nếu có)
                          trong quá trình vận chuyển.
                        </li>
                        <li>
                          Khi đóng gói sản phẩm đổi/ trả gửi về cho chúng tôi,
                          Quý khách sẽ chịu trách nhiệm về tính toàn vẹn của sản
                          phẩm. Chúng tôi khuyến nghị Quý khách đóng gói sản
                          phẩm kỹ càng và bằng cách thức phù hợp để đảm bảo sản
                          phẩm không bị ảnh hưởng xấu trong quá trình vận chuyển
                          và/hoặc do tác nhân bên ngoài (nếu có).
                        </li>
                      </ul>
                      <p>
                        <strong>Bước 5:</strong>&nbsp;Thực hiện đổi/ trả sản
                        phẩm
                      </p>
                      <p>
                        Ngay khi nhận được sản phẩm đổi/ trả của Quý khách,
                        chúng tôi sẽ tiến hành đánh giá sản phẩm và thông báo
                        kết quả cùng phương hướng giải quyết đổi/ trả đến Quý
                        khách trong vòng 05 - 07 ngày làm việc.
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReturnMethod;
