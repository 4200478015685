import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "../styles/Product/productInfo.css";
import helper from "../helper";
import DOMPurify from "dompurify";
import Api from "../components/api/apis";
import Helper from "../helper/helper";
import RelatedProduct from "../components/Product/RelatedProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Helmet } from "react-helmet";
import PreloadImages from "../utils/PreloadImages";
import { Icon } from "@iconify/react";

import { FacebookShareButton } from "react-share";

const FacebookPixelScript = ({ curProduct, discountList }) => {
  const scriptId = "facebook-pixel-script";
  useEffect(() => {
    if (curProduct && (curProduct?.sku ?? "") !== "") {
      // Tạo một ID mới cho script

      // Xóa script cũ nếu đã tồn tại

      const oldScript = document.getElementById(scriptId);
      if (oldScript) {
        oldScript.parentNode.removeChild(oldScript);
      }

      // Tạo một thẻ script mới
      const script = document.createElement("script");
      script.id = scriptId;
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;

      // Thiết lập nội dung của script
      script.innerHTML = `
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    try {
      fbq('init', '437041775344814');
      fbq('track', 'ViewContent', {
        content_ids: '${curProduct?.sku ?? ""}',
        value: ${discountList[curProduct.sku] ?? curProduct?.price ?? 0},
        currency: 'VND',
        content_type: 'product',
      });
    } catch (error) {
      console.error('fbq script error:', error);
    }
  `;
      // Thêm script mới vào body
      document.head.appendChild(script);
    }
  }, [curProduct]);

  return null;
};

const GoogleAnalyticsScript = ({ curProduct, discountList, product }) => {
  useEffect(() => {
    const scriptId = "google-analytics-script";

    if (
      curProduct &&
      (curProduct?.sku ?? "") !== "" &&
      (product?.name ?? "") !== ""
    ) {
      // Xóa script cũ nếu đã tồn tại
      const oldScript = document.getElementById(scriptId);
      if (oldScript) {
        oldScript.parentNode.removeChild(oldScript);
      }

      // Tạo một thẻ script mới
      const script = document.createElement("script");
      script.id = scriptId;
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;

      // Thiết lập nội dung của script
      script.innerHTML = `
        gtag('event', 'view_item', {
          currency: 'VND',
          value: ${discountList[curProduct.sku] ?? curProduct?.price ?? 0},
          items: [
            {
              item_id: '${curProduct?.sku ?? ""}',
              item_name: '${product?.name ?? ""}',
              price: ${discountList[curProduct.sku] ?? curProduct?.price ?? 0},
              quantity: 1
            }
          ]
        });
      `;

      // Thêm script mới vào body
      document.head.appendChild(script);
    }
  }, [curProduct]);

  return null;
};

// SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination]);

function ProductInfo({ setCartChange, discountList, cartEvent, setCartEvent }) {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [product, setProduct] = useState({});
  const [active, setActive] = useState(0);
  const [triggerScroll, setTriggerScroll] = useState(0);
  const [colorTheme, setColorTheme] = useState("");
  const [searchParams] = useSearchParams();
  const param = useParams();
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [shareTab, setShareTab] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [description, setDescription] = useState("");
  const [descriptionDisplay, setDescriptionDisplay] = useState("");
  const [color, setColor] = useState(() => {
    return searchParams.get("color") ? Number(searchParams.get("color")) : 0;
  });
  const [size, setSize] = useState(() => {
    return searchParams.get("size") ? Number(searchParams.get("size")) : 0;
  });

  const [curProduct, setCurProduct] = useState(() => {
    return product &&
      product.productList &&
      product.productList[Number(size)] &&
      product.productList[Number(size)][Number(color)]
      ? product.productList[Number(size)][Number(color)]
      : {};
  });

  const getCategory = () => {
    if (!product) {
      return [];
    } else {
      if (product.sizeDiff === false) {
        switch (product.productType) {
          case 1:
            return ["Vali"];

          case 2:
            return ["Balo"];

          case 3:
            return ["Túi xách"];

          case 4:
            return ["Phụ kiện"];

          default:
            return [];
        }
      } else if (product.sizeDiff === true) {
        let arr = [];
        product.sizeArray.map((ele, index) => {
          if (ele === true) {
            switch (index) {
              case 0:
                arr.push(product && product.sizeArrayLen[index]);
                break;

              case 1:
                arr.push(product && product.sizeArrayLen[index]);
                break;

              case 2:
                arr.push(product && product.sizeArrayLen[index]);
                break;

              default:
                break;
            }
          }
        });
        return arr;
      }
    }
  };

  const getDesc = () => {
    if (!product) {
      return [];
    } else {
      if (product.sizeDiff === false) {
        return [product.nonSizeDesc ?? ""];
      } else if (product.sizeDiff === true) {
        let arr = [];
        product.sizeArray.map((ele, index) => {
          if (ele === true) {
            switch (index) {
              case 0:
                arr.push(product && product.sizeArrayDesc[index]);
                break;

              case 1:
                arr.push(product && product.sizeArrayDesc[index]);
                break;

              case 2:
                arr.push(product && product.sizeArrayDesc[index]);
                break;

              default:
                break;
            }
          }
        });
        return arr;
      }
    }
  };

  const preloadImage = (url) => {
    const img = new Image();
    img.src = cloudFrontURL + `/` + url;
  };

  function getIndexOfTrue(array, i) {
    let count = 0;
    for (let index = 0; index < array.length; index++) {
      if (array[index] === true) {
        count++;
        if (count === i) {
          return index;
        }
      }
    }
    return 0;
  }

  useEffect(() => {
    // Hàm kiểm tra xem ảnh nào đang xuất hiện hoàn chỉnh
    const handleScroll = () => {
      const imageElements = document.querySelectorAll(
        ".owl-carousel-item img.pdp-primary-image"
      );
      for (let i = 0; i < imageElements.length; i++) {
        const imageElement = imageElements[i];
        const rect = imageElement.getBoundingClientRect();
        // Kiểm tra xem ảnh có nằm hoàn toàn trong màn hình không
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActive(i); // Cập nhật state currentImg
          break;
        }
      }
    };

    // Gán sự kiện scroll cho cửa sổ
    window.addEventListener("scroll", handleScroll);
    // Gọi hàm handleScroll một lần khi ban đầu tải trang
    handleScroll();

    // Hủy đăng ký sự kiện khi component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setDescription(product.description);
    for (let i in product?.productImgListUrl) {
      for (let j in product?.productImgListUrl[i]) {
        preloadImage(product?.productImgListUrl[i][j][0] ?? "");
        for (let k in product?.productImgListUrl[i][j]) {
          preloadImage(product?.productImgListUrl[i][j][k] ?? "");
        }
      }
    }
    for (let i in product?.colorArrayUrl) {
      preloadImage(product?.colorArrayUrl[i] ?? "");
    }
  }, [product]);

  useEffect(() => {
    const cleanHtml = DOMPurify.sanitize(description, {
      FORBID_ATTR: [],
    });

    setDescriptionDisplay(cleanHtml);
  }, [description]);

  useEffect(() => {
    setActive(0);

    if (product && product.productList) {
      setCurProduct(
        product &&
          product.productList &&
          product.productList[Number(size)] &&
          product.productList[Number(size)][Number(color)]
          ? product.productList[Number(size)][Number(color)]
          : {}
      );

      for (let i in product?.productImgListUrl) {
        preloadImage(
          product.productImgListUrl?.[Number(size)]?.[Number(color)][i] ?? ""
        );
      }
      if (Helper.checkShowProduct(size, color, product.productList)) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("color", color);
        searchParams.set("size", size); // Tạo URL mới với các tham số truy vấn đã thay đổi
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;

        window.history.replaceState({}, "", newUrl);
      } else {
        const { newSize, newColor } = Helper.getNewProduct(
          size,
          color,
          product.productList
        );
        setColor(Number(newColor));
        setSize(Number(newSize));
      }
    }
  }, [color, size, product]);

  useEffect(() => {
    setColorTheme(
      product && product.colorArray && product.colorArray[color]
        ? product.colorArray[color].toLowerCase().replace(/\s+/g, "")
        : ""
    );
  }, [color, product, curProduct]);

  useEffect(() => {
    //move to image active
    if (windowWidth > 986) {
      window.scrollTo(0, 0);
    }
  }, [color, size]);

  useEffect(() => {
    //move to image active
    const ele = document.getElementById(`imgPos${triggerScroll}`);
    if (windowWidth > 986) {
      if (triggerScroll !== 0) {
        ele &&
          ele.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "start",
          });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [triggerScroll]);

  useEffect(() => {
    let set = true;
    if (param.productline) {
      let rs = {};
      const fetchData = async () => {
        try {
          rs = { ...(await Api.getOneProduct(param.productline)) };
          if (set) {
            if (rs.success === false) {
              window.open("/", "_self");
            }
            setProduct(rs.product ?? {});
            document.title = `${
              rs.product?.name ? rs.product?.name + " - " : ""
            }American Tourister Việt Nam`;
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }

    return () => {
      set = false;
    };
  }, [param.productline]);

  useEffect(() => {
    let data = {};
    const fetchData = async () => {
      try {
        data = [...(await Api.getRelatedProduct(product))];
        setRelatedProduct(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (product._id) {
      fetchData();
    }
  }, [product]);

  try {
    return (
      <React.Fragment>
        {(product?._id ?? "") !== "" && (
          <PreloadImages
            imageUrls={(product?.combine ?? []).reduce((pre, cur) => {
              return (cur?.show ?? false) === true
                ? [...pre, ...(cur?.imgList ?? [])]
                : pre;
            }, [])}
            dependency={product}
          />
        )}
        <div>
          <Helmet>
            <meta
              name="description"
              content={`${product?.SEOLink ?? "American tourister"}`}
            />
            <meta name="keywords" content={`${curProduct?.sku ?? ""}`} />
          </Helmet>
          <Helmet>
            {/* Facebook tags */}
            <meta property="og:type" content="product" />
            <meta
              property="og:title"
              content={`${product.name} ${
                product &&
                (product.sizeDiff === false
                  ? getDesc()?.[0]
                  : getDesc()?.[Number(size)] ?? "")
              }`}
            />
            <meta
              property="og:image"
              content={`${cloudFrontURL}/${
                product?.productImgListUrl?.[size]?.[color]?.[0] ?? ""
              }`}
            />
            <meta
              property="og:image:secure_url"
              content={`${cloudFrontURL}/${
                product?.productImgListUrl?.[size]?.[color]?.[0] ?? ""
              }`}
            />
            <meta
              property="og:price:amount"
              content={discountList[curProduct.sku] ?? curProduct.price}
            />
            <meta property="og:price:currency" content="VND" />
            <meta
              property="og:description"
              content={descriptionDisplay
                .substring(0, 150)
                .replace(/<[^>]+>/g, "")
                .replace(/\n/g, "")}
            />
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:site_name"
              content="American Tourister Việt Nam"
            />
          </Helmet>
        </div>

        <FacebookPixelScript
          curProduct={curProduct}
          discountList={discountList}
        />
        <GoogleAnalyticsScript
          curProduct={curProduct}
          discountList={discountList}
          product={product}
        />
        <div
          className={`product-detail product-wrapper color-${colorTheme}`}
          style={{ marginBottom: "100px" }}
        >
          <div
            className="container product-detail-main"
            itemScope
            itemType="http://schema.org/Product"
            style={{ position: "relative" }}
          >
            <div className="row">
              <div className="col-12 col-lg-3 d-none d-lg-block block-sidebar">
                <div className="sticky-column">
                  <div className="thumbnail-image-section">
                    <div className="carousel slide">
                      <div className="owl-carousel">
                        {product &&
                          product.productImgListUrl &&
                          product.productImgListUrl[size] &&
                          product.productImgListUrl[size][color] &&
                          product.productImgListUrl[size][color].map(
                            (ele, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    index === active
                                      ? "thumbnail-item active"
                                      : "thumbnail-item"
                                  }
                                >
                                  <img
                                    alt="img"
                                    src={`${cloudFrontURL}/${ele}`}
                                    onClick={() => {
                                      setTriggerScroll(index);
                                    }}
                                  />
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9 block-main-detail">
                <div className="row">
                  <div className="col-12 col-lg-6 product-top-content">
                    <div
                      className="d-lg-none block-radius"
                      style={{ padding: "5px 4px 8px 4px" }}
                    >
                      <h2 className="product-collection" itemProp="name">
                        {product && product.name ? product.name : ""}
                      </h2>
                      <h3 className="product-name">
                        {product &&
                          (product.sizeDiff === false
                            ? getDesc()?.[0]
                            : getDesc()?.[Number(size)] ?? "")}
                      </h3>
                      <h3 className="product-name">
                        {curProduct && `SKU : ${curProduct.sku ?? ""}`}
                      </h3>
                    </div>
                    <div className="block-radius product-detail-images">
                      <div className="primary-images ">
                        <div className="carousel slide">
                          <div className="d-lg-none">
                            <Swiper
                              id="productSlide"
                              tag="section"
                              // speed={1000}
                              // autoplay={{
                              //   delay: 4001,
                              //   disableOnInteraction: false,
                              // }}
                              spaceBetween={0}
                              slidesPerView={1}
                              pagination={{
                                clickable: true,
                              }}
                              loop
                              initialSlide={1}
                            >
                              {product &&
                                product.productImgListUrl &&
                                product.productImgListUrl[size] &&
                                product.productImgListUrl[size][color] &&
                                product.productImgListUrl[size][color].map(
                                  (ele, index) => {
                                    return (
                                      <SwiperSlide
                                        key={index}
                                        tag="li"
                                        style={{
                                          cursor: "pointer",
                                          display: "flex", // Hiển thị slide bên trong theo kiểu flex để căn giữa
                                          alignItems: "center", // Căn giữa chiều dọc
                                          justifyContent: "center", // Căn giữa chiều ngang
                                          width: "auto",
                                          height: "auto",
                                        }}
                                      >
                                        <img
                                          itemProp="image"
                                          className="pdp-primary-image"
                                          data-position="0"
                                          src={`${cloudFrontURL}/${ele}`}
                                          alt="img"
                                          id={`img${index}`}
                                        />
                                      </SwiperSlide>
                                    );
                                  }
                                )}
                            </Swiper>
                          </div>
                          <div className="d-none d-lg-block owl-carousel">
                            {product &&
                              product.productImgListUrl &&
                              product.productImgListUrl[size] &&
                              product.productImgListUrl[size][color] &&
                              product.productImgListUrl[size][color].map(
                                (ele, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="owl-carousel-item "
                                    >
                                      <img
                                        itemProp="image"
                                        className="pdp-primary-image"
                                        data-position="0"
                                        src={`${cloudFrontURL}/${ele}`}
                                        alt="img"
                                        id={`imgPos${index}`}
                                      />
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 product-detail-informations"
                    id="product-informations"
                  >
                    <div className="sticky-column product-detail-information-content">
                      <div className="product-detail-info-top">
                        <div className="d-none d-lg-block product-right-info">
                          <h1 className="product-collection" itemProp="name">
                            {product && product.name ? product.name : ""}
                          </h1>
                          <div
                            style={{
                              fontWeight: 800,
                              marginTop: "10px",
                              fontSize: "20px",
                            }}
                          >
                            {product &&
                              (product.sizeDiff === false
                                ? getDesc()?.[0]
                                : getDesc()?.[Number(size)] ?? "")}
                          </div>
                          <div style={{ fontWeight: 600 }}>
                            {curProduct && `SKU : ${curProduct.sku ?? ""}`}
                          </div>
                        </div>
                      </div>
                      <div className="row no-gutters product-attribute-wrapper">
                        {product &&
                          !(
                            product.sizeDiff === false ||
                            (product.sizeDiff === true &&
                              product.sizeArray.reduce((pre, cur) => {
                                return pre + (cur === true ? 1 : 0);
                              }, 0) === 1)
                          ) && (
                            <div className="product-detail-section product-variant-size">
                              <div className="product-variant-size-list">
                                <h4>Chọn kích thước</h4>
                                <div className="row">
                                  {product &&
                                    product.sizeArrayDesc &&
                                    getCategory().map((ele, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-4 variant-item "
                                        >
                                          <div className="size-selection">
                                            <div className="attr-productSize">
                                              <div
                                                className={
                                                  index === size
                                                    ? `attr-item selected`
                                                    : `attr-item`
                                                }
                                              >
                                                <div className="variant-image-size">
                                                  <a
                                                    className="attr-selector"
                                                    href="/"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        product &&
                                                        product.productImgListUrl &&
                                                        product
                                                          .productImgListUrl[
                                                          index
                                                        ] &&
                                                        product
                                                          .productImgListUrl[
                                                          index
                                                        ][color]
                                                          ? `${cloudFrontURL}/${product.productImgListUrl[index][color][0]}`
                                                          : ""
                                                      }
                                                      alt="img"
                                                    />
                                                  </a>
                                                </div>
                                                <div className="size-item">
                                                  <a
                                                    href="/"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setSize(index);
                                                    }}
                                                    className="attr-selector"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <span>{`${ele}`}</span>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="product-attributes">
                          <div className="attr-color" data-attr="color">
                            <div className="attribute">
                              <div className="color-quantity">
                                <span>{`${(product?.combine ?? []).reduce(
                                  (acc, obj) =>
                                    (obj?.show ?? false) === true &&
                                    ((product.sizeDiff === true &&
                                      (obj?.size ?? 0) ===
                                        getIndexOfTrue(
                                          product.sizeArray ?? [],
                                          size + 1
                                        )) ||
                                      product.sizeDiff === false)
                                      ? acc + 1
                                      : acc,
                                  0
                                )} màu`}</span>
                              </div>
                              <div className="owl-carousel color-swatchs">
                                {product &&
                                  product.colorArrayUrl &&
                                  product.colorArrayUrl.length > 0 &&
                                  product.colorArrayUrl.map((ele, index) => {
                                    return (
                                      product &&
                                      product.productList &&
                                      product.productList[size] &&
                                      product.productList[size][color] &&
                                      product.productList[size][index][
                                        "show"
                                      ] === true && (
                                        <div
                                          key={index}
                                          className={
                                            color === index
                                              ? "attr-item watch-item selected"
                                              : "attr-item watch-item "
                                          }
                                        >
                                          <a
                                            className="attr-selector"
                                            href="/"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setColor(index);
                                            }}
                                          >
                                            <span
                                              className="color-value swatch-circle swatch-value selectable"
                                              style={{
                                                backgroundImage: `url(${cloudFrontURL}/${ele})`,
                                                backgroundSize: "100%",
                                              }}
                                            ></span>
                                          </a>
                                        </div>
                                      )
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product-detail-section prices-add-to-cart-actions revised-layout ">
                        <div className="prices">
                          <div className="price">
                            <span className="price-inner">
                              {product &&
                                curProduct &&
                                !discountList[curProduct.sku] && (
                                  <span className="sales">
                                    <span className="value">
                                      {helper.moneyFormat(
                                        curProduct && curProduct.price
                                      )}
                                    </span>
                                  </span>
                                )}
                              {product &&
                                curProduct &&
                                discountList[curProduct.sku] && (
                                  <div>
                                    <span className="sales promotion-price">
                                      <span className="value">
                                        {helper.moneyFormat(
                                          discountList &&
                                            curProduct &&
                                            discountList[curProduct.sku]
                                            ? discountList[curProduct.sku]
                                            : curProduct.price
                                        )}
                                      </span>
                                    </span>
                                    <span className="strike-through list">
                                      <span className="value">
                                        {helper.moneyFormat(
                                          curProduct ? curProduct.price ?? 0 : 0
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                )}
                            </span>
                          </div>
                        </div>
                        <div className="row cart-and-ipay">
                          <div className="col-sm-12">
                            <button
                              disabled={curProduct.remain === 0}
                              className="add-to-cart btn btn-primary "
                              data-pid="at-128188-0581"
                              style={{ fontWeight: 800 }}
                              onClick={(e) => {
                                e.preventDefault();
                                let sku = curProduct.sku ?? "";
                                let seo = product.SEOLink ?? "";
                                if (
                                  localStorage &&
                                  localStorage.cartAT &&
                                  sku !== "" &&
                                  seo !== ""
                                ) {
                                  if (
                                    !Object.keys(
                                      JSON.parse(localStorage.cartAT)
                                    ).includes(seo)
                                  ) {
                                    localStorage &&
                                      localStorage.setItem(
                                        "cartAT",
                                        JSON.stringify({
                                          ...JSON.parse(localStorage.cartAT),
                                          [seo]: { [sku]: 1 },
                                        })
                                      );
                                    setCartChange((prev) => {
                                      return prev + 1;
                                    });
                                    setCartEvent(false);
                                  } else {
                                    localStorage &&
                                      localStorage.setItem(
                                        "cartAT",
                                        JSON.stringify({
                                          ...JSON.parse(localStorage.cartAT),
                                          [seo]: {
                                            ...JSON.parse(localStorage.cartAT)[
                                              seo
                                            ],
                                            [sku]: JSON.parse(
                                              localStorage.cartAT
                                            )[seo][sku]
                                              ? JSON.parse(localStorage.cartAT)[
                                                  seo
                                                ][sku] + 1
                                              : 1,
                                          },
                                        })
                                      );
                                    setCartChange((prev) => {
                                      return prev + 1;
                                    });
                                    setCartEvent(false);
                                  }
                                } else {
                                  localStorage &&
                                    localStorage.setItem(
                                      "cartAT",
                                      JSON.stringify({ [seo]: { [sku]: 1 } })
                                    );
                                  setCartChange((prev) => {
                                    return prev + 1;
                                  });
                                  setCartEvent(false);
                                }
                              }}
                            >
                              Mua hàng
                            </button>
                            <div style={{cursor:"pointer", minHeight:24, minWidth:24, marginTop:10}}
                            onClick={()=>{
                              setShareTab(prev=>{return !prev})
                            }}
                            >

                            <Icon height={24} width={24} icon="material-symbols:share" />
                            </div>
                          </div>
                        </div>
                        <div className="row below-add-to-cart">
                          <div
                            className="availability col-md-10 col-12 product-availability"
                            data-ready-to-order="true"
                            data-available="true"
                          >
                            <div className="row">
                              <div className="col-12 col-sm-4">
                                {/* <label className="availability">Tồn kho:</label> */}
                              </div>
                              <div className="col-12 col-sm-8">
                                <ul className="list-unstyled availability-msg">
                                  <li>
                                    <div
                                      className={
                                        curProduct.remain ? "" : "notAvail"
                                      }
                                    >
                                      {curProduct.remain > 0
                                        ? "Còn hàng"
                                        : "Hết hàng"}
                                    </div>
                                    {/* <div className="notAvail">Out of Stock</div> */}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="product-information-inner">
                        <div className="card" style={{ alignItems: "center" }}>
                          <div
                            className="display_table"
                            dangerouslySetInnerHTML={{
                              __html: descriptionDisplay,
                            }}
                            style={{
                              border: "none",
                              padding: "10px",
                              overflow: "hidden",
                              width: "100%",
                              maxWidth: "500px",
                            }}
                          />
                          <div style={{ marginTop: 20, marginLeft: 10 }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RelatedProduct
          relatedProduct={relatedProduct}
          discountList={discountList}
          setWidth={setWindowWidth}
        ></RelatedProduct>
        
        {shareTab && <MyComponent url={window.location.href} setShareTab = {setShareTab}/>}
      </React.Fragment>
    );
  } catch (error) {
    console.error("Error occurred while rendering script:", error);
    return null; // Hoặc thực hiện một hành động khác tùy thuộc vào yêu cầu của bạn
  }
}

export default ProductInfo;

const MyComponent = ({ url, setShareTab }) => {
  const zalo_aoid = process.env.REACT_APP_ZALO_OAID;

  const buttonStyle = {
    display: "inline-block",
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    fontSize: "11px",
    height: "32px",
    lineHeight: "32px",
    margin: "16px",
    padding: "0 8px",
    position: "relative",
    textAlign: "center",
    top: "0",
    verticalAlign: "top",
    whiteSpace: "nowrap",
    width: "180px",
    transition: "opacity 0.2s ease-in, top 0.2s ease-in",
  };

  const buttonLabelStyle = {
    color: "#fff",
    display: "inline-block",
    fontWeight: "500",
    letterSpacing: "0.5px",
    minWidth: "60px",
    opacity: "1",
    padding: "0 6px",
    position: "relative",
    verticalAlign: "top",
    transition: "all 0.2s ease-in",
  };

  const imgStyle = {
    display: "inline-block",
    height: "16px",
    width: "16px",
    position: "relative",
    top: "8px",
    verticalAlign: "top",
    marginRight: "8px",
  };

  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopyButtonText('Đã copy');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="background-popup" style={{
      background: "rgba(0, 0, 0, 0.8)",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      padding:"10vh 10vw",
      zIndex: "7777",
      opacity: 1,
      visibility: "visible",
      transition: "all 200ms linear",
      display: "flex",
      alignItems: "center",
      justifyContent:"center"
    }} onClick={() => setShareTab(prev => !prev)}>
      <div
        id="st-1"
        onClick={(e) => e.stopPropagation()}
        style={{
          marginTop: "1em",
          marginBottom: "1em",
          textAlign: "center",
          background: "white",
          minWidth: "50vw",
          minHeight: "40vh",
          borderRadius: "6px",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Helmet>
          <script
            type="text/javascript"
            src="https://platform-api.sharethis.com/js/sharethis.js#property=66612c542b0bca00199fc094&product=custom-share-buttons&source=platform"
          ></script>
          <script src="https://sp.zalo.me/plugins/sdk.js"></script>
        </Helmet>

        <div style={{padding:"20px 0 0 0 ", textAlign:"center", display:"block", width : "100%"}}>
          <div style={{fontSize:"30px", fontWeight:"800"}}>Share to Friends</div>

          <div style={{minHeight:"1px", background:"#e6e6e6", marginTop:20}}></div>
        </div>

        <FacebookShareButton url={url} quote={"American Tourister Product"}>
          <div
            className="st-custom-button st-btn"
            data-network="facebook"
            style={{ ...buttonStyle, backgroundColor: "#4267B2" }}
          >
            <img
              alt="facebook sharing button"
              src="https://platform-cdn.sharethis.com/img/facebook.svg"
              style={imgStyle}
            />
            <span className="st-label" style={buttonLabelStyle}>
              Facebook
            </span>
          </div>
        </FacebookShareButton>

        <div
          className="st-custom-button st-btn"
          data-network="copy"
          style={{ ...buttonStyle, backgroundColor: "#14682B" }}
          onClick={handleCopy}
        >
          <img
            alt="copy sharing button"
            src="https://platform-cdn.sharethis.com/img/copy.svg"
            style={imgStyle}
          />
          <span className="st-label" style={buttonLabelStyle}>{copyButtonText}</span>
        </div>

        <div
          className="st-custom-button st-btn zalo-share-button"
          data-href={url}
          data-oaid={zalo_aoid}
          data-layout="1"
          data-color="blue"
          data-share-type="2"
          data-customize="true"
          style={{ ...buttonStyle, backgroundColor: "#03a5fa" }}
        >
          <img
            alt="zalo sharing button"
            src="https://sp-zp.zdn.vn/share/logo_white_s.png"
            style={imgStyle}
          />
          <span className="st-label" style={buttonLabelStyle}>
            Zalo
          </span>
        </div>
      </div>
    </div>
  );
};


