import React from "react";
import "../../styles/Support/support.css";
import InsuranceImg from '../../assets/others/insurance.jpg'

function InsuranceMethod({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultInsurance">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor:
              "black",
          }}
        >
          <h1>Chính sách bảo hành</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <p style={{ textAlign: "center" }}>
                        <img
                          style={{ maxWidth: "100%", height: "auto" }}
                          src={InsuranceImg}
                          alt="Chính sách bảo hành"
                        />
                      </p>
                      <p>&nbsp;</p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InsuranceMethod;
