import React from "react";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import "../styles/cart.css";
import helper from "../helper";
import Api from "../components/api/apis";
import { Helmet } from "react-helmet";

const Cart = ({ setCartChange, title, discountList }) => {
  document.title = title;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [cartList, setCartList] = useState([]);
  const [gift, setGift] = useState([]);
  const [trigger, setTrigger] = useState(1);

  const [cartPrice, setCartPrice] = useState(0);
  const [quantityDiscountValue, setQuantityDiscountValue] = useState(0);
  const [totalValueDiscountValue, setTotalValueDiscountValue] = useState(0);

  useEffect(() => {
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = {
          ...(await Api.updateCart(
            localStorage && localStorage.cartAT
              ? JSON.parse(localStorage.cartAT)
              : {}
          )),
        };
        if (set && rs.success) {
          setCartList(rs.cartList);
          setQuantityDiscountValue(rs.quantityDiscountValue);
          setCartPrice(rs.cartPrice);
          setGift(rs.gift);
          setTotalValueDiscountValue(rs.totalValueDiscountValue);
          localStorage &&
            localStorage.setItem(
              "cartAT",
              rs.newCart ? rs.newCart : JSON.stringify({})
            );
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, [trigger]);

  return (
    <React.Fragment>
      <div className="cart-content">
        <div className="container cart-container title">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title text-uppercase">Giỏ hàng</h1>
            </div>
          </div>
        </div>
        <div className="container cart-container cart cart-page">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  {cartList &&
                    cartList.length > 0 &&
                    cartList.map((ele, index) => {
                      return (
                        <div key={index} className="card product-info">
                          <div className="line-item-header">
                            <div>
                              <button
                                type="button"
                                className="remove-btn-lg remove-product btn btn-light"
                                onClick={(e) => {
                                  e.preventDefault();
                                  let newObj =
                                    localStorage && localStorage.cartAT
                                      ? JSON.parse(localStorage.cartAT)
                                      : {};
                                  let innerObj = {
                                    ...(newObj[ele.SEOLink] ?? {}),
                                  };
                                  delete innerObj[ele.sku];
                                  if (Object.keys(innerObj) === 0) {
                                    delete newObj[ele.SEOLink];
                                  } else {
                                    newObj[ele.SEOLink] = { ...innerObj };
                                  }
                                  localStorage &&
                                    localStorage.setItem(
                                      "cartAT",
                                      JSON.stringify(newObj)
                                    );
                                  setTrigger((prev) => {
                                    return prev + 1;
                                  });
                                  setCartChange((prev) => {
                                    return prev + 1;
                                  });
                                }}
                              >
                                <Icon icon="ci:close-big" />
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-12 d-flex flex-row">
                                  <div className="item-image">
                                    <a
                                      href="/"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img
                                        className="product-image"
                                        src={`${
                                          ele.img
                                            ? `${cloudFrontURL}/${ele.img}`
                                            : ""
                                        }`}
                                        alt="img"
                                        title=" "
                                      />
                                    </a>
                                  </div>
                                  <div className="item-attributes d-flex flex-column">
                                    <p className="line-item-attributes collection-name text-uppercase">
                                      <a
                                        href="/"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        {ele && ele.name ? ele.name : ""}
                                      </a>
                                    </p>
                                    <p className="line-item-attributes product-name font-weight-bold text-uppercase">
                                      <a
                                        href="/"
                                        onClick={(e) => e.preventDefault()}
                                      >{`${ele.size ?? ``} / ${
                                        ele?.colorArrayDesc ?? ``
                                      }`}</a>
                                    </p>

                                    <div>
                                      <div className="item-quan">
                                        <div className="qty quantity-partent qty-click clearfix">
                                          <button
                                            type="button"
                                            className="qtyminus qty-btn"
                                            onClick={() => {
                                              if (ele.quantity > 1) {
                                                let sku = ele.sku ?? "";
                                                let seo = ele.SEOLink ?? "";
                                                if (
                                                  localStorage &&
                                                  localStorage.cartAT &&
                                                  sku !== "" &&
                                                  seo !== ""
                                                ) {
                                                  if (
                                                    !Object.keys(
                                                      JSON.parse(
                                                        localStorage.cartAT
                                                      )
                                                    ).includes(seo)
                                                  ) {
                                                    setTrigger((prev) => {
                                                      return prev + 1;
                                                    });
                                                  } else {
                                                    localStorage &&
                                                      localStorage.setItem(
                                                        "cartAT",
                                                        JSON.stringify({
                                                          ...JSON.parse(
                                                            localStorage.cartAT
                                                          ),
                                                          [seo]: {
                                                            ...JSON.parse(
                                                              localStorage.cartAT
                                                            )[seo],
                                                            [sku]: JSON.parse(
                                                              localStorage.cartAT
                                                            )[seo][sku]
                                                              ? JSON.parse(
                                                                  localStorage.cartAT
                                                                )[seo][sku] - 1
                                                              : 1,
                                                          },
                                                        })
                                                      );
                                                    setTrigger((prev) => {
                                                      return prev + 1;
                                                    });
                                                    setCartChange(1);
                                                  }
                                                } else {
                                                  setTrigger((prev) => {
                                                    return prev + 1;
                                                  });
                                                  setCartChange(1);
                                                }
                                              }
                                            }}
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            size="4"
                                            name="updates[]"
                                            min="1"
                                            id="updates_1102718785"
                                            data-price="165000000"
                                            value={ele.quantity}
                                            className="tc line-item-qty item-quantity"
                                          ></input>
                                          <button
                                            type="button"
                                            className="qtyplus qty-btn"
                                            onClick={() => {
                                              let sku = ele.sku ?? "";
                                              let seo = ele.SEOLink ?? "";
                                              if (
                                                localStorage &&
                                                localStorage.cartAT &&
                                                sku !== "" &&
                                                seo !== ""
                                              ) {
                                                if (
                                                  !Object.keys(
                                                    JSON.parse(
                                                      localStorage.cartAT
                                                    )
                                                  ).includes(seo)
                                                ) {
                                                  setTrigger((prev) => {
                                                    return prev + 1;
                                                  });
                                                } else {
                                                  localStorage &&
                                                    localStorage.setItem(
                                                      "cartAT",
                                                      JSON.stringify({
                                                        ...JSON.parse(
                                                          localStorage.cartAT
                                                        ),
                                                        [seo]: {
                                                          ...JSON.parse(
                                                            localStorage.cartAT
                                                          )[seo],
                                                          [sku]: JSON.parse(
                                                            localStorage.cartAT
                                                          )[seo][sku]
                                                            ? JSON.parse(
                                                                localStorage.cartAT
                                                              )[seo][sku] + 1
                                                            : 2,
                                                        },
                                                      })
                                                    );
                                                  setTrigger((prev) => {
                                                    return prev + 1;
                                                  });
                                                  setCartChange(1);
                                                }
                                              } else {
                                                setTrigger((prev) => {
                                                  return prev + 1;
                                                });
                                                setCartChange(1);
                                              }
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                      {/* <button className="btn btn-primary"  onClick={()=>{
                                        if(ele.quantity < ele.maxQuantity){
                                          let temp = cartList
                                          temp[index].quantity = ele.quantity +1
                                          setCartList([...temp])
                                          let arr = localStorage&&localStorage.cartAT?JSON.parse(localStorage.cartAT):{}
                                          arr[ele.sku] = arr[ele.sku] + 1
                                          
                                          localStorage&&localStorage.setItem('cartAT', JSON.stringify(arr))
                                          setCartChange(prev=>{return prev+1})
                                        }
                                      }}>+</button> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="regular-retail-price col-lg-12 text-right">
                                  <span className="promotion-price">
                                    {ele && ele.price
                                      ? helper.moneyFormat(
                                          ele.price * ele.quantity
                                        )
                                      : helper.moneyFormat(0)}
                                  </span>
                                </div>
                                {discountList[ele.sku] && (
                                  <div className="col-lg-12 product-card-footer">
                                    <div className="container-applied-promotion">
                                      <div className="line-item-promo">
                                        <div className="product-card-promotion-detail callout tooltip-explanations">
                                          <span>Giảm giá</span>
                                          <span>{` - ${helper.moneyFormat(
                                            (ele.price -
                                              (discountList[ele.sku]
                                                ? discountList[ele.sku]
                                                : ele.price)) *
                                              ele.quantity
                                          )}`}</span>
                                        </div>
                                        <div className="line-item-final-price">
                                          {helper.moneyFormat(
                                            (discountList[ele.sku]
                                              ? discountList[ele.sku]
                                              : ele.price) * ele.quantity
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {gift.length > 0 &&
                    gift.map((ele, index) => {
                      return (
                        <div key={index} className="card product-info">
                          <div className="line-item-header">
                            <div></div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-12 d-flex flex-row">
                                  <div className="item-image">
                                    <a
                                      href="/"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img
                                        className="product-image"
                                        src={`${
                                          ele.img
                                            ? `${cloudFrontURL}/${ele.img}`
                                            : ""
                                        }`}
                                        alt="img"
                                        title=" "
                                      />
                                    </a>
                                  </div>
                                  <div className="item-attributes d-flex flex-column">
                                    <p className="line-item-attributes collection-name text-uppercase">
                                      <a
                                        href="/"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        {ele && ele.name ? ele.name : ""}
                                      </a>
                                    </p>
                                    <p className="line-item-attributes product-name font-weight-bold text-uppercase">
                                      <a
                                        href="/"
                                        onClick={(e) => e.preventDefault()}
                                      >{`${ele.size ?? ``} / ${
                                        ele?.colorArrayDesc ?? ``
                                      }`}</a>
                                    </p>
                                    <div>
                                      {`Số lượng: ${ele?.quantity ?? 0}`}
                                    </div>
                                    <div>Quà tặng</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {cartList.length === 0 && (
                    <div className="card product-info">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">Giỏ hàng trống</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {cartList && cartList.length > 0 && (
                  <div className="col-sm-12 col-md-12 totals">
                    <div className="sub-total-block">
                      <span>Tạm tính</span>
                      <span>{helper.moneyFormat(cartPrice)}</span>
                    </div>
                    <div className="sub-total-block">
                      <span>Giảm chương trình</span>
                      <span>
                        -
                        {helper.moneyFormat(
                          quantityDiscountValue + totalValueDiscountValue
                        )}
                      </span>
                    </div>
                    {/* <div className="shipping-method">
                      <div className="col-12">
                        <div className="shipping-information">
                          <div >
                            Standard Shipping - Speedpost Standard: {helper.moneyFormat(0)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shipping-promos">
                      <div className="shipping-discount-checkout">
                        <div className="promotion-information">
                          <span className="promotion-name">Free Standard Shipping Discount</span>
                          <span className="applied-promotion-discount">{` - ${helper.moneyFormat(0)}`}</span>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-6 order-md-2">
                        <div className="row">
                          <div className="col-lg-12 text-right cart-total">
                            <div className="w-100">Tổng</div>
                            <div className="w-100 grand-total">
                              {helper.moneyFormat(
                                cartPrice -
                                  (quantityDiscountValue +
                                    totalValueDiscountValue)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 order-md-1"></div>
                    </div>
                    <div className="row">
                      <div className="col-12 checkout-continue">
                        <div className="checkout-and-applepay row">
                          <a
                            href="/checkout"
                            className="btn btn-primary btn-block checkout-btn  col"
                          >
                            Thanh toán
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cart;
