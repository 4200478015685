import React from "react";
import "../../styles/Support/support.css";

function ReceiveMethod({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultReceive">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor:
              "black",
          }}
        >
          <h1>Phương thức giao nhận</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <ul>
                        <li>
                          Đơn hàng trong khu vực Hồ Chí Minh: Giao hàng bởi bộ
                          phận giao nhận và đơn vị Giao Hàng Nhanh.
                        </li>
                        <li>
                          Đơn hàng ngoài khu vực Hồ Chí Minh: Giao hàng thông
                          qua bưu điện VNPost, Viettel, Giao Hàng Nhanh, Giao
                          Hàng Tiết Kiệm…
                        </li>
                        <li>
                          <strong>Chi phí giao hàng:</strong>
                        </li>
                      </ul>
                      <table
                        className="support-table-receive"
                        border="1"
                        cellpadding="10"
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                backgroundColor: "#ececec",
                                textAlign: "center",
                                width: "40%",
                              }}
                            >
                              <strong>Khu vực nhận hàng</strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: "#ececec",
                                textAlign: "center",
                                width: "15%",
                              }}
                            >
                              <strong>Đại</strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: "#ececec",
                                textAlign: "center",
                                width: "15%",
                              }}
                            >
                              <strong>Trung</strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: "#ececec",
                                textAlign: "center",
                                width: "15%",
                              }}
                            >
                              <strong>Cabin</strong>
                            </td>
                            <td
                              style={{
                                backgroundColor: "#ececec",
                                textAlign: "center",
                                width: "15%",
                              }}
                            >
                              <strong>Phụ kiện</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Hà Nội, Đà Nẵng, TP. Hồ Chí Minh</td>
                            <td style={{ textAlign: "center" }}>100.000</td>
                            <td style={{ textAlign: "center" }}>70.000</td>
                            <td style={{ textAlign: "center" }}>40.000</td>
                            <td style={{ textAlign: "center" }}>30.000</td>
                          </tr>
                          <tr>
                            <td>Các tỉnh thành khác</td>
                            <td style={{ textAlign: "center" }}>120.000</td>
                            <td style={{ textAlign: "center" }}>90.000</td>
                            <td style={{ textAlign: "center" }}>60.000</td>
                            <td style={{ textAlign: "center" }}>35.000</td>
                          </tr>
                          <tr>
                            <td>Sản phẩm có giá trên 1.500.000đ</td>
                            <td
                              rowspan="1"
                              colspan="4"
                              style={{ textAlign: "center" }}
                            >
                              Miễn phí vận chuyển
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h3>&nbsp;</h3>
                      <h3>THỜI GIAN GIAO HÀNG:</h3>
                      <ul>
                        <li>3 - 4 ngày cho đơn hàng tại khu vực Hồ Chí Minh</li>
                        <li>4 – 5 ngày cho đơn hàng các tỉnh thành khác</li>
                        <li>
                          Trong trường hợp hàng hóa không có sẵn tại Hồ Chí
                          Minh, thời gian giao hàng sẽ linh động cộng thêm thời
                          gian điều hàng (tùy từng trường hợp cụ thể)
                        </li>
                        <li>
                          Thời gian giao hàng chỉ tính trong ngày làm việc,
                          không tính các ngày thứ 7 và chủ nhật.
                        </li>
                        <li>
                          Đối với những đơn hàng cần giao gấp, quý khách vui
                          lòng báo ngay cho nhân viên bán hàng (tại phần ghi chú
                          đơn hàng hoặc thông tin lên tổng đài 1800 6360) để
                          được tư vấn về thời gian và chi phí giao hàng.
                        </li>
                        <li>
                          Trường hợp quý khách thanh toán bằng chuyển khoản hoặc
                          thanh toán qua thẻ, đơn hàng sẽ được gửi thông báo
                          ngay sau khi có xác nhận thanh toán thành công (từ kế
                          toán công ty).
                        </li>
                      </ul>{" "}

                      
                      <h3 style={{textTransform:"uppercase"}}>Các giới hạn về mặt địa lý cho việc giao hàng:</h3>
                      <ul>
                        <li>
                        Riêng khách tỉnh có nhu cầu mua số lượng lớn hoặc khách buôn sỉ nếu có nhu cầu mua sản phẩm , chúng tôi sẽ nhờ dịch vụ giao nhận của các công ty vận chuyển và phí sẽ được tính theo phí của các đơn vị cung cấp dịch vụ vận chuyển hoặc theo thoản thuận hợp đồng giữa 2 bên.
                        </li>
                      </ul>
                      <h3 style={{textTransform:"uppercase"}}>Phân định trách nhiệm của thương nhân, tổ chức cung ứng dịch vụ logistics về cung cấp chứng từ hàng hóa trong quá trình giao nhận:</h3>
                      <ul>
                        <li>
                        Tất cả các đơn hàng đều được đóng gói sẵn sàng trước khi vận chuyển, được niêm phong bởi Americantourister.vn.
                        </li>
                        <li>
                        Đơn vị vận chuyển sẽ chỉ chịu trách nhiệm vận chuyển hàng hóa theo nguyên tắc “nguyên đai, nguyên kiện”.
                        </li>
                        <li>
                        Trên bao bì tất cả các đơn hàng đều có thông tin:
                        </li>
                        <ol> <li>Thông tin người nhận, bao gồm: tên người nhận, số điện thoại và địa chỉ người nhận.</li> 
                        <li>Mã vận đơn của đơn hàng.</li> 
                        </ol>
                        <li>Để đảm bảo an toàn cho hàng hóa, Americantourister.vn sẽ gửi kèm hóa đơn tài chính hoặc phiếu xuất kho hợp lệ của sản phẩm trong bưu kiện (nếu có).</li>
                        <li>Hóa đơn tài chính hoặc phiếu xuất kho là căn cứ hỗ trợ quá trình xử lý khiếu nại như: xác định giá trị thị trường của hàng hóa, đảm bảo hàng hóa lưu thông hợp lệ v.v..</li>
                      </ul>

                      <h3 style={{textTransform:"uppercase"}}>Trách nhiệm về trường hợp hàng bị hư hỏng do quá trình vận chuyển:</h3>
                      <ul>
                        <li>Về việc cung cấp chứng từ hàng hóa trong quá trình giao nhận.</li>
                        <li>Đối với hàng hóa bị hư hỏng do quá trình vận chuyển dù là đơn hàng do chính cửa hàng vận chuyển hay do bên thứ 3 vận chuyển thì chúng tôi sẽ là bên đứng ra chịu trách nhiệm giải quyết vấn đề cho khách hàng.
</li>
<li>Khách hàng có quyền từ chối nhận sản phẩm và yêu cầu đổi trả theo quy định <b>“đổi trả hoàn phí”</b> còn mọi vấn đề phát sinh chúng tôi sẽ làm việc lại với đối tác vận chuyển để giải quyết đền bù cho đơn hàng theo thỏa thuận hợp tác giữa công ty với đối tác thứ 3 cung cấp dịch vụ vận chuyển.</li>
                      </ul>

                      <br>
                      </br>
                      <p>
                      <b >Lưu ý:</b> Trường hợp phát sinh chậm trễ trong việc giao hàng chúng tôi sẽ thông tin kịp thời cho khách hàng và khách hàng có thể lựa chọn giữa việc Hủy hoặc tiếp tục chờ hàng.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReceiveMethod;
