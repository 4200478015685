import React from "react";
import "../../styles/Support/support.css";

function PaymentMethod({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultPayment">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor:
              "black",
          }}
        >
          <h1>Phương thức thanh toán</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <h3>CÁC HÌNH THỨC THANH TOÁN</h3>
                      <p>
                        - americantourister.vn cung cấp các phương thức thanh
                        toán nào?
                      </p>
                      <p>
                        - Nhằm mang đến cho Quý khách những trải nghiệm mua sắm
                        trực tuyến tuyệt vời nhất, tại americantourister.vn,
                        chúng tôi đưa ra 4 phương thức thanh toán để Quý khách
                        dễ dàng lựa chọn:
                      </p>
                      <p>
                        <strong>1. Thanh toán khi nhận hàng (COD)</strong>
                      </p>
                      <p>
                        <em>COD là gì?</em>
                      </p>
                      <p>
                        COD là viết tắt của Cash On Delivery, nghĩa là thanh
                        toán khi nhận hàng. Với phương thức thanh toán này, quý
                        khách trả tiền mặt cho nhân viên giao hàng ngay khi nhận
                        được đơn hàng của mình. Chúng tôi chấp nhận hình thức
                        thanh toán khi nhận hàng (COD) cho hầu hết đơn hàng trên
                        toàn quốc. Trừ những trường hợp sau:
                      </p>
                      <ul>
                        <li>
                          Chỉ áp dụng COD cho đơn hàng có tổng giá trị nhỏ hơn
                          10 triệu đồng. Nếu đơn hàng trên 10 triệu đồng, quý
                          khách vui lòng chọn hình thức thanh toán khác.
                        </li>
                        <li>
                          Không hỗ trợ phương thức Thanh toán bằng tiền mặt khi
                          nhận hàng (COD) đối với đơn hàng có sản phẩm bắt buộc
                          lưu chuyển trực tiếp từ cửa hàng để giao hàng, hoặc
                          đơn hàng có từ 3 kiện hàng cùng size. Quý khách vui
                          lòng chọn hình thức thanh toán trước bằng hình thức
                          chuyển khoản. Nhân viên hỗ trợ đơn hàng sẽ liên hệ xác
                          nhận thông tin đơn hàng cho quý khách.
                        </li>
                      </ul>
                      <p>
                        Đơn giản. An toàn. Không chút rắc rối mà lại an tâm
                        tuyệt đối khi quý khách chọn thanh toán COD tại{" "}
                        <strong>americantourister.vn</strong>.
                      </p>
                      <p>
                        <strong>2. Thanh toán bằng thẻ ATM nội địa:</strong>
                      </p>
                      <p>
                        Trong quá trình đặt hàng, quý khách chọn hình thức Thanh
                        toán bằng thẻ ATM nội địa, quý khách sẽ được chuyển đến
                        Cổng thanh toán trực tuyến bảo mật Onepay để thanh toán.
                        Thẻ ATM của quý khách bắt buộc phải sử dụng Internet
                        Banking để thực hiện phương thức thanh toán này.
                      </p>
                      <p>
                        <strong>3. Thanh toán bằng thẻ Visa/Master:</strong>
                      </p>
                      <p>
                        Trong quá trình đặt hàng, quý khách chọn hình thức Thanh
                        toán bằng thẻ Visa/Master card quý khách sẽ được chuyển
                        đến Cổng thanh toán trực tuyến bảo mật Onepay để thanh
                        toán.
                      </p>
                      <p>
                        <strong>4. Thanh toán chuyển khoản:</strong>
                      </p>
                      <p>
                        Trong quá trình đặt hàng, Quý khách vui lòng chọn hình
                        thức thanh toán chuyển khoản trong phần{" "}
                        <strong>Phương thức thanh toán</strong>.
                      </p>
                      <p>
                        Điều kiện để chọn hình thức thanh toán chuyển khoản là
                        thẻ ATM của Quý khách đã được đăng ký sử dụng internet
                        banking.&nbsp;
                      </p>
                      <p>
                        <em>
                          <strong>
                            * Quý&nbsp;khách chuyển khoản vào tài khoản:
                          </strong>
                        </em>
                      </p>
                      <blockquote>
                        <p>- TÊN TÀI KHOẢN: CÔNG TY TNHH BANYAN VIỆT NAM</p>
                        <p>- SỐ TÀI KHOẢN: 310.205.6497</p>
                        <p>
                          - NGÂN HÀNG TMCP ĐẦU TƯ VÀ PHÁT TRIỂN VIỆT NAM (BIDV)
                          - CHI NHÁNH: HỒ CHÍ MINH
                        </p>
                      </blockquote>

                      <p>

                      Hoặc
                      </p>
                      
                      <blockquote>
                        <p>- TÊN TÀI KHOẢN: CÔNG TY TNHH BANYAN VIỆT NAM</p>
                        <p>- SỐ TÀI KHOẢN: 0071001228757</p>
                        <p>
                          -  NGÂN HÀNG TMCP NGOẠI THƯƠNG VIỆT NAM (VIETCOMBANK) 
                          -  CHI NHÁNH: HỒ CHÍ MINH
                        </p>
                      </blockquote>
                      <p>
                        Cú pháp ghi chú: <strong>AT_[Mã đơn hàng]</strong>
                      </p>
                      <p>
                        Ví dụ: Quý khách thanh toán chuyển khoản cho đơn hàng
                        19xxxxxxx đặt hàng trên website americantourister.vn,
                        cú pháp ghi chú khi chuyển khoản sẽ là{" "}
                        <strong>AT_19xxxxxxx</strong>
                      </p>
                      <p>
                        <strong>
                          Giá trị đơn hàng là bao nhiêu thì tôi sẽ được xuất hóa
                          đơn?
                        </strong>
                      </p>
                      <p>
                        Trong mọi trường hợp, trừ khi có yêu cầu khác đi, Quý
                        khách hàng sẽ nhận được hoá đơn theo thông tin cá nhân
                        đã đăng ký khi mua hàng tại website{" "}
                        <strong>americantourister.vn</strong>.
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PaymentMethod;
