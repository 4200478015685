import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import Api from "../components/api/apis";
import ColorProduct from "../components/Product/ColorProduct";
import logo from "../assets/logo/icon-shop-by-color.svg"

const ShopByColor = ({discountList}) => {
  const circleRef = useRef(null);
  const [initialAngle, setInitialAngle] = useState(0);
  const [currentAngle, setCurrentAngle] = useState(15); // Quay trước 15 độ
  const [currentColor, setCurrentColor] = useState("");
  const [style, api] = useSpring(() => ({ rotateZ: 15 })); // Quay trước 15 độ
  const [category, setCategory] = useState([]);
  
  const [productList, setProductList] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const [filteredProductList, setFilteredProductList] = useState([]);

  useEffect(() => {
    let set = true;
    let list = [];
    const fetchData = async () => {
      try {
        list = [...(await Api.getProductList({}))];
        if (set) {
          setProductList(list);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, []);

  useEffect(() => {
    let newList = []
    productList.map(product => {
      // Kiểm tra nếu category rỗng hoặc chứa productType của sản phẩm
      if (category.length === 0 || category.includes(product.productType ?? 0)) {
        // Kiểm tra nếu colorArray chứa color
        if (product.colorArray.includes(currentColor)) {
          const colorIndex = product.colorArray.indexOf(currentColor);
          const sizeCount = product.productType === 1 ? product.sizeArray.filter(Boolean).length : 1;
          const colorCount = product.colorArray.length;
          const combineCount = colorCount * sizeCount;
  
          for (let t = 0; t < sizeCount; t++) {
            const index = t * colorCount + colorIndex;
            if (index < combineCount) {
              const item = product.combine[index];
              if (item.remain > 0 && item.show === true) {
                newList = [...newList, item]
                return true;
              }
            }
          }
        }
      }
      return false;
    });
  
    setFilteredProductList(newList);
  }, [productList, category, currentColor]);
  

  const colors = [
    "Light Blue",
    "Red",
    "Black",
    "Green",
    "Grey",
    "Orange",
    "Blue",
    "Purple",
    "Pink",
    "Yellow",
    "White",
    "Nude",
  ];

  const bind = useDrag(({ xy: [px, py], first }) => {
    const rect = circleRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const dx = px - centerX;
    const dy = py - centerY;

    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

    if (first) {
      setInitialAngle(angle - currentAngle);
    } else {
      // Cập nhật góc hiện tại và áp dụng vào vòng tròn
      const newAngle = angle - initialAngle;
      setCurrentAngle(newAngle);
      api.start({ rotateZ: newAngle });
    }
  });

  useEffect(() => {
    // Tính toán góc tương ứng với vị trí 6h (dưới cùng của hình tròn)
    const angleAt6H = (currentAngle + 180) % 360;

    // Đảo ngược góc để phản ánh đúng hướng xoay
    const adjustedAngle = (360 - angleAt6H) % 360;

    // Xác định màu sắc dựa trên góc này
    const colorIndex = Math.floor(adjustedAngle / 30);
    setCurrentColor(colors[colorIndex]);
  }, [currentAngle]);

  return (
    <div>
      <div className="shop-by-color-banner">
<div className="content-asset">
<div className="cat-banner-content">
<div className="image">
<img width="240" height="50" src={logo} alt="banner"/>
</div>
<div className="description">
<p>Xoay vòng quay để chọn màu</p>
</div>
</div>
<style>
</style>
</div> 
</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          marginTop: "-350px",
        }}
      >
        <animated.div
          {...bind()}
          ref={circleRef}
          style={{
            ...style,
            minWidth: 500,
            minHeight: 500,
            borderRadius: "50%",
            position: "relative",
            cursor: "grab",boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            marginBottom:8
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              background: `conic-gradient(
              #3393D0 0% 8.33%, #D11E47 8.33% 16.66%, #000000 16.66% 25%,
              #81C341 25% 33.33%, #8F979D 33.33% 41.66%, #EF7922 41.66% 50%,
              #04559F 50% 58.33%, #C11C8A 58.33% 66.66%, #FE249A 66.66% 75%,
              #FFC012 75% 83.33%, #FFFFFF 83.33% 91.66%, #F5F5DC 91.66% 100%
            )`,
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <div
            style={{
              width: 240,
              height: 240,
              borderRadius: "50%",
              background: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </animated.div>
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "12px solid transparent",
            borderRight: "12px solid transparent",
            borderBottom: "24px solid black",
            position: "absolute",
            top: "260px",
            left: "50%",
            transform: "translate(-50%, -50%) rotate(180deg)",
          }}
        />
      </div>
      {/* <div style={{ marginTop: 20, textAlign: "center", fontWeight: "bold" }}>
        Màu ở góc 6h hiện tại: {currentColor}
      </div> */}
      <div className="shop-by-color-product-type">
        <div className="row"  style={{marginTop:35}}>
          <div className="col-12 col-xl-3">
            <span className="product-type-title">Chọn loại sản phẩm</span>
          </div>
          <div className="col-12 col-xl-9">
            <div className="row">
              <div className="col-3">
                <a
                  href="/"
                  className={`${
                    category.includes(1) ? `selected ` : ``
                  }refinement-selection black-text `}
                  onClick={(e) => {
                    e.preventDefault()
                    let arr = [...category];
                    if (arr.includes(1)) {
                      arr = arr.filter((item) => item !== 1); 
                    } else {
                      arr.push(1); 
                    }
                    setCategory(arr); 
                  }}
                >
                  <span className="refiment-text">Vali</span>
                  <span
                    className="refiment-bg"
                  ></span>
                </a>
              </div>
              <div className="col-3">
                <a
                  href="/"
                  className={`${
                    category.includes(2) ? `selected ` : ``
                  }refinement-selection black-text `}
                  onClick={(e) => {
                    e.preventDefault()
                    let arr = [...category];
                    if (arr.includes(2)) {
                      arr = arr.filter((item) => item !== 2); 
                    } else {
                      arr.push(2); 
                    }
                    setCategory(arr); 
                  }}
                >
                  <span className="refiment-text">Balo</span>
                  <span
                    className="refiment-bg"
                  ></span>
                </a>
              </div>
              <div className="col-3">
                <a
                  href="/"
                  className={`${
                    category.includes(3) ? `selected ` : ``
                  }refinement-selection black-text `}
                  onClick={(e) => {
                    e.preventDefault()
                    let arr = [...category];
                    if (arr.includes(3)) {
                      arr = arr.filter((item) => item !== 3); 
                    } else {
                      arr.push(3); 
                    }
                    setCategory(arr); 
                  }}
                >
                  <span className="refiment-text">Túi xách</span>
                  <span
                    className="refiment-bg"
                  ></span>
                </a>
              </div>
              <div className="col-3">
                <a
                  href="/"
                  className={`${
                    category.includes(4) ? `selected ` : ``
                  }refinement-selection black-text `}
                  onClick={(e) => {
                    e.preventDefault()
                    let arr = [...category];
                    if (arr.includes(4)) {
                      arr = arr.filter((item) => item !== 4); 
                    } else {
                      arr.push(4); 
                    }
                    setCategory(arr); 
                  }}
                >
                  <span className="refiment-text">Phụ kiện</span>
                  <span
                    className="refiment-bg"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ColorProduct productList={filteredProductList} discountList={discountList} setWidth={setWindowWidth}/>
    </div>
  );
};

export default ShopByColor;
