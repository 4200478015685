import React from "react";
import "../../styles/Header/header.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import img1 from "../../assets/logo/logo_red.svg";
import img2 from "../../assets/logo/logo_orange.svg";
import img3 from "../../assets/logo/logo_blue.svg";
import img4 from "../../assets/logo/logo_green.svg";
import shopByColor from "../../assets/logo/icon-shop-by-color.svg";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import helper from "../../helper";
import Api from "../../components/api/apis";

SwiperCore.use([Navigation, Pagination, Autoplay]);


const FacebookPixelAndGoogleAnalyticsScript = ({ cartEvent, cartList, cartPrice, quantityDiscountValue, totalValueDiscountValue, discountList  }) => {
  useEffect(() => {
    if(cartEvent === true){
      const facebookScriptId = "facebook-pixel-script-cart-event";
      const googleScriptId = "google-analytics-script-cart-event";
  
      // Xóa script cũ của Facebook Pixel nếu đã tồn tại
      let oldFacebookScript = document.getElementById(facebookScriptId);
      if (oldFacebookScript) {
        oldFacebookScript.parentNode.removeChild(oldFacebookScript);
      }
  
      // Xóa script cũ của Google Analytics nếu đã tồn tại
      let oldGoogleScript = document.getElementById(googleScriptId);
      if (oldGoogleScript) {
        oldGoogleScript.parentNode.removeChild(oldGoogleScript);
      }
  
      // Tạo script mới cho Facebook Pixel
      const facebookScript = document.createElement("script");
      facebookScript.id = facebookScriptId;
      facebookScript.type = "text/javascript";
      facebookScript.defer = true;
      facebookScript.async = true;
      facebookScript.innerHTML = `
        try {
          !function(f,b,e,v,n,t,s) {
            if(f.fbq) return; 
            n = f.fbq = function() {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  
          fbq('init', '437041775344814');
          fbq('track', 'AddToCart', {
            content_ids: [${cartList.map(item => `'${item?.sku ?? "none"}'`).join(",")}],
            content_type: 'product',
            value: ${cartPrice - (quantityDiscountValue + totalValueDiscountValue)},
            currency: 'VND'
          });
        } catch (error) {
          console.error('Script error:', error);
        }
      `;
  
      // Thêm script của Facebook Pixel vào head
      document.head.appendChild(facebookScript);
  
      // Tạo script mới cho Google Analytics
      const googleScript = document.createElement("script");
      googleScript.id = googleScriptId;
      googleScript.type = "text/javascript";
      googleScript.defer = true;
      googleScript.async = true;
      googleScript.innerHTML = `
        gtag('event', 'add_to_cart', {
          currency: 'VND',
          value: ${cartPrice - (quantityDiscountValue + totalValueDiscountValue)},
          items: [
            ${cartList.map(item => `{
              item_id: '${item?.sku ?? "none"}',
              item_name: '${item?.name ?? "Unknown"}',
              price: ${discountList[item?.sku ?? "none"] ?? (item?.price??0)},
              quantity: ${item?.quantity ?? 1}
            }`).join(",")}
          ]
        });
      `;
  
      // Thêm script của Google Analytics vào head
      document.head.appendChild(googleScript);
    }

  }, [ cartEvent]);

  return null;
};


const Header = ({ setCartChange, cartChange, discountList, cartEvent, setCartEvent }) => {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  
  const themeTab = [
    ["red", img1],
    ["orange", img2],
    ["blue", img3],
    ["green", img4],
  ];
  const [trigger, setTrigger] = useState(0);
  const [theme] = useState(() => {
    return themeTab[Math.floor(Math.random() * 4)];
  });
  const [dropNum, setDropNum] = useState(-1);
  const [searchValue, setSearchValue] = useState("");
  const [drop, setDrop] = useState(false);
  const [cartTab, setCartTab] = useState(false);
  const [searchTab, setSearchTab] = useState(false);
  const [menuTab, setMenuTab] = useState(false);
  const [cartList, setCartList] = useState([]);
  const [gift, setGift] = useState([]);

  const [menu, setMenu] = useState([]);

  const [channel, setChannel] = useState({});

  const [sideMenu, setSideMenu] = useState([]);

  const [cartPrice, setCartPrice] = useState(0)
  const [quantityDiscountValue, setQuantityDiscountValue] = useState(0)
  const [totalValueDiscountValue, setTotalValueDiscountValue] = useState(0)

  useEffect(() => {
    if (dropNum === 0) {
      setMenu(channel["luggageMenu"] ?? []);
    } else if (dropNum === 1) {
      setMenu(channel["backpackMenu"] ?? []);
    } else if (dropNum === 2) {
      setMenu(channel["bagMenu"] ?? []);
    } else if (dropNum === 3) {
      setMenu(channel["accessoryMenu"] ?? []);
    } else {
      setMenu([]);
    }
  }, [drop, dropNum, channel]);

  useEffect(() => {
    let arr = [
      { menu: channel["luggageMenu"] ?? [], drop: false },
      { menu: channel["backpackMenu"] ?? [], drop: false },
      { menu: channel["bagMenu"] ?? [], drop: false },
      { menu: channel["accessoryMenu"] ?? [], drop: false },
    ];
    for (let i in arr) {
      for (let j in arr[i].menu) {
        arr[i].menu[j].drop = false;
      }
    }
    const sizeMenu = [...(arr[0].menu ?? [])];
    sizeMenu.unshift({
      typeOfDt: "Size",
      arr: [
        {
          active: true,
          field: "Cabin",
        },
        {
          active: true,
          field: "Trung",
        },
        {
          active: true,
          field: "Đại",
        },
      ],
      drop: false,
    });
    arr[0].menu = sizeMenu;
    setSideMenu(arr);
    // console.log(arr)
  }, [channel, menuTab]);

  useEffect(() => {
    fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "channel",
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setChannel(result.channel);
        }
      });
  }, [apiURL]);

  useEffect(() => {
    setCartTab(false);
    setCartEvent(false)
  }, [drop]); 

  useEffect(() => {
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = {
          ...(await Api.updateCart(
            localStorage && localStorage.cartAT
              ? JSON.parse(localStorage.cartAT)
              : {}
          )),
        };
        if (set && rs.success) {
          setCartList(rs.cartList);
          setGift(rs.gift)
          setQuantityDiscountValue(rs.quantityDiscountValue)
          setCartPrice(rs.cartPrice)
          setTotalValueDiscountValue(rs.totalValueDiscountValue)
          localStorage &&
            localStorage.setItem(
              "cartAT",
              rs.newCart ? rs.newCart : JSON.stringify({})
            );
          if (cartChange > 1) {
            setCartTab(true);
          }
        }else{
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    return () => {
      set = false;
    };
  }, [cartChange]);

  useEffect(() => {
    if (cartTab === true) {
      setCartChange((prev) => {
        return prev + 1;
      });
      setCartEvent(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTab]);

  useEffect(() => {
    if (cartEvent === true) {
      setCartEvent(false)
    }
  }, [cartEvent]);

  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          width: "100%",
          backgroundColor: "white",
          zIndex: "800",
        }}
      >
      {
        
        <FacebookPixelAndGoogleAnalyticsScript
          cartEvent={cartEvent}
          cartList={cartList}
          totalValueDiscountValue={totalValueDiscountValue}
          discountList = {discountList}
          quantityDiscountValue = {quantityDiscountValue}
          cartPrice = {cartPrice}
        />
      }
      <div className="global-noti">
        {channel?.inform??""}
      </div>
        <header
          id="header"
          className={`section section-header logo-${theme[0]}`}
          style={{height:"80px", overflow:"hidden"}}
        >
          <div className="header-content container-fluid" style={{minHeight:"80px", maxHeight:"80px"}}>
            <div className="row">
              <div className="col-12 header-content-inner">
                <div className="navbar-header row">
                  <div
                    className="col-6 col-lg-2 col-xxl-1 navbar-header-item main-logo"
                    style={{ paddingLeft: "40px" }}
                    itemScope itemType="http://schema.org/Organization"
                  >
                    <a href="/" title="American Tourister Home">
                      <i className="at-icon icon-logo">
                        <img
                          className="logoImg"
                          src={theme[1]}
                          alt="American Tourister"
                          itemProp="logo"
                        />
                      </i>
                    </a>
                  </div>
                  <div
                    className="col-12 col-lg-7 col-xxl-8 main-menu navbar-toggleable-sm menu-toggleable-left multilevel-dropdown"
                    id="sg-navbar-collapse"
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <nav className="navbar bg-inverse col-12" style={{marginLeft:"30px"}}>
                          <div
                            className="menu-group"
                            aria-label="Main menu"
                            role="navigation"
                          >
                            <ul className="nav navbar-nav" role="menu" itemScope itemType="http://schema.org/ItemList">
                              <li className="nav-item nav-top-item">
                                <div className="content-asset">
                                  <p>
                                    <a href="/shop-by-color"  style={{cursor:"auto"}}>
                                      <img
                                        style={{
                                          position: "relative",
                                          top: "5px",
                                          cursor:"pointer"
                                        }}
                                        width="166"
                                        height="30"
                                        alt="logo"
                                        src={shopByColor}
                                        title=""
                                      />
                                    </a>
                                  </p>
                                </div>
                              </li>
                              <li
                                className="nav-item dropdown sub-items-5"
                                role="menuitem"
                                itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"
                                onMouseEnter={() => {
                                  setDrop(true);
                                  setDropNum(0);
                                  setSearchTab(false);
                                }}
                                onMouseLeave={() => {
                                  setDrop((prev) => !prev);
                                }}
                              >
                                <a
                                  href="/vali/p/p"
                                  itemProp="url"
                                  style={{
                                    fontWeight: "800",
                                  }}
                                  className="nav-link dropdown-toggle"
                                >
                                  <span itemProp="name">Vali</span>
                                </a>
                              </li>
                              <li
                                className="nav-item dropdown sub-items-3"
                                role="menuitem"
                                itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"
                                onMouseEnter={() => {
                                  setDrop(true);
                                  setDropNum(1);
                                  setSearchTab(false);
                                }}
                                onMouseLeave={() => {
                                  setDrop((prev) => !prev);
                                }}
                              >
                                <a
                                  href="/balo/p/p"
                                  itemProp="url"
                                  style={{
                                    fontWeight: "800",
                                  }}
                                  className="nav-link dropdown-toggle"
                                >
                                  
                                  <span itemProp="name">Balo</span>
                                </a>
                              </li>
                              <li
                                className="nav-item dropdown sub-items-1"
                                role="menuitem"
                                itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"
                                onMouseEnter={() => {
                                  setDrop(true);
                                  setDropNum(2);
                                  setSearchTab(false);
                                }}
                                onMouseLeave={() => {
                                  setDrop((prev) => !prev);
                                }}
                              >
                                <a
                                  href="/Tui-xach/p/p"
                                  itemProp="url"
                                  style={{
                                    fontWeight: "800",
                                  }}
                                  className="nav-link dropdown-toggle"
                                >
                                  <span itemProp="name">Túi xách</span>
                                </a>
                              </li>
                              <li
                                className="nav-item dropdown sub-items-2"
                                role="menuitem"
                                itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"
                                onMouseEnter={() => {
                                  setDrop(true);
                                  setDropNum(3);
                                  setSearchTab(false);
                                }}
                                onMouseLeave={() => {
                                  setDrop((prev) => !prev);
                                }}
                              >
                                <a
                                  href="/Phu-kien/p/p"
                                  itemProp="url"
                                  style={{
                                    fontWeight: "800",
                                  }}
                                  className="nav-link dropdown-toggle"
                                >
                                  <span itemProp="name">Phụ kiện</span>
                                </a>
                              </li>
                              <li
                                className="nav-item dropdown sub-items-2"
                                role="menuitem"
                                onMouseEnter={() => {
                                  // setDrop(true);
                                  // setDropNum(4);
                                  // setSearchTab(false);
                                }}
                                onMouseLeave={() => {
                                  // setDrop((prev) => !prev);
                                }}
                              >
                                <a
                                  href="/special-offers"
                                  style={{
                                    fontWeight: "800",
                                  }}
                                  className="nav-link dropdown-toggle"
                                >
                                  Special Offers
                                </a>
                              </li>
                              <li
                                className="nav-item dropdown sub-items-2"
                                role="menuitem"
                                onMouseEnter={() => {
                                  // setDrop(true);
                                  // setDropNum(4);
                                  // setSearchTab(false);
                                }}
                                onMouseLeave={() => {
                                  // setDrop((prev) => !prev);
                                }}
                              >
                                <a
                                  href="/stores"
                                  style={{
                                    fontWeight: "800",
                                  }}
                                  className="nav-link dropdown-toggle"
                                >
                                  Hệ thống cửa hàng
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 navbar-header-item navigation-section">
                    <div className="d-none d-xl-block language-selection-block"></div>
                    {/* <div className="d-xl-block store-location-block">
                      <a href="/stores" title="Store Locator">
                        <Icon
                          className="fa at-icon icon-store-locator"
                          icon="gis:location-poi-o"
                        />
                      </a>
                    </div> */}
                    <button className="d-xl-inline-block btn btn-search">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setSearchTab((prev) => {
                            return !prev;
                          });
                          setCartTab(false);
                          setMenuTab(false);
                        }}
                      >
                        <Icon
                          className="fa at-icon search"
                          icon="eva:search-outline"
                        />
                      </a>
                    </button>
                    <div className="minicart">
                      <div className="minicart-total hide-link-med">
                        <a
                          className="minicart-link"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setCartTab((prev) => {
                              return !prev;
                            });
                            setSearchTab(false);
                            setMenuTab(false);
                          }}
                          title="View Cart"
                        >
                          <Icon
                            className="minicart-icon fa fa-shopping-bag"
                            icon="akar-icons:cart"
                          />
                          <span className="minicart-quantity">
                            {cartList.length}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="responsive-menu minicart">
                      <div className="minicart-total hide-link-med">
                        <a
                          className="minicart-link"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setMenuTab((prev) => {
                              return !prev;
                            });
                            setSearchTab(false);
                            setCartTab(false);
                          }}
                          title="View Cart"
                        >
                          <Icon
                            className="minicart-icon fa fa-shopping-bag"
                            icon="mingcute:menu-fill"
                          />
                        </a>
                      </div>
                    </div>
                    {/* <div style={{width:"30px"}}></div> */}
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          style={{
            minHeight: "80px",
          }}
        ></div>
        {drop && (
          <ul
            className="menu-collapse header"
            onMouseEnter={() => {
              setDrop(true);
            }}
            onMouseLeave={() => {
              setDrop((prev) => !prev);
            }}
          >
            {menu &&
              menu.map((ele, index) => {
                return (
                  <React.Fragment key={index}>
                    {dropNum === 0 && index === 0 && (
                      <li className="dropdown-item dropdown">
                        <h3>{"Kích thước"}</h3>
                        <ul className="collapse multi-collapse dropdown-collapse dropdown-luggage-size level-3 show">
                          <li className="dropdown-item">
                            <a
                              href={`/vali-size-cabin/p/p`}
                              className="dropdown-link"
                            >
                              Cabin
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href={`/vali-size-trung/p/p`}
                              className="dropdown-link"
                            >
                              Trung
                            </a>
                          </li>
                          <li className="dropdown-item">
                            <a
                              href={`/vali-size-dai/p/p`}
                              className="dropdown-link"
                            >
                              Đại
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}
                    <li className="dropdown-item dropdown">
                      <h3>{ele.typeOfDt ?? ""}</h3>
                      <ul className="collapse multi-collapse dropdown-collapse dropdown-luggage-size level-3 show">
                        {ele.arr &&
                          ele.arr.map((ele1, index1) => {
                            return (
                              <li key={index1} className="dropdown-item">
                                <a
                                  href={`/${
                                    ["vali", "balo", "Tui-xach", "Phu-kien"][dropNum]
                                  }/${ele["SEOLink"]??"none"}/${ele1["SEOLink"]??"none"}`}
                                  className="dropdown-link"
                                >
                                  {ele1["field"]}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </React.Fragment>
                );
              })}
          </ul>
        )}

        {/* cart tab */}
        <div
          className={`site-nav style--sidebar ${
            cartTab ? `active` : ``
          } show-cart`}
        >
          <div id="site-cart" className="site-nav-container">
            <div className="site-nav-container-last">
              <p style={{ lineHeight: "21px" }} className="title">
                Giỏ hàng
              </p>
              <div className="cart-view clearfix">
                <table id="cart-view">
                  <tbody>
                    {cartList.length === 0 && (
                      <tr className="item_2">
                        <td>Hiện chưa có sản phẩm</td>
                      </tr>
                    )}
                    {cartList.length !== 0 &&
                      cartList.map((ele, index) => {
                        return (
                          <tr className="item_2" key={index}>
                            <td className="img" style={{ width: "100px" }}>
                              <a href={`/p/${ele.SEOLink}`} title={ele.name ?? ""}>
                                <img
                                  className="side-cart"
                                  src={ele.img ? `${cloudFrontURL}/${ele.img}` :""}
                                  alt="cart"
                                />
                              </a>
                            </td>
                            <td>
                              <div style={{ width: "100%" }}>
                                <a
                                  className="pro-title-view"
                                  style={{ width: "auto" }}
                                  href={`/p/${ele.SEOLink}`}
                                  title=""
                                >
                                  {ele.name ?? ""}
                                </a>
                              </div>
                              <span className="variant">{`${ele.size??``} / ${ele?.colorArrayDesc??``}`}</span>
                              <span className="pro-quantity-view">
                                {ele.quantity ?? 0}
                              </span>
                              <span className ="pro-price-view">
                                {helper.moneyFormat(
                                  (discountList[ele.sku]
                                    ? discountList[ele.sku]
                                    : ele.price) * ele.quantity
                                )}
                              </span>
                              {discountList[ele.sku] && (
                                <div
                                  style={{
                                    display: "inline-block",
                                    lineHeight: "20px",
                                  }}
                                >
                                  <span
                                    className="pro-quantity-view"
                                    style={{ opacity: 0 }}
                                  >
                                    {ele.quantity ?? 0}
                                  </span>
                                  <span
                                    className ="pro-price-view"
                                    style={{
                                      fontSize: "11px",
                                      marginLeft: "5px",
                                      color: "#797979",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    <del>
                                      {helper.moneyFormat(ele.price * ele.quantity)}
                                    </del>
                                  </span>
                                </div>
                              )}
                              <span
                                className ="remove_link remove-cart"
                                style={{ cursor: "pointer" }}
                              >
                                <Icon
                                  icon="material-symbols:close"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let newObj =
                                      localStorage && localStorage.cartAT
                                        ? JSON.parse(localStorage.cartAT)
                                        : {};
                                    let innerObj = {
                                      ...(newObj[ele.SEOLink] ?? {}),
                                    };
                                    delete innerObj[ele.sku];
                                    if (Object.keys(innerObj) === 0) {
                                      delete newObj[ele.SEOLink];
                                    } else {
                                      newObj[ele.SEOLink] = { ...innerObj };
                                    }
                                    localStorage &&
                                      localStorage.setItem(
                                        "cartAT",
                                        JSON.stringify(newObj)
                                      );
                                    setTrigger((prev) => {
                                      return prev + 1;
                                    });
                                    setCartChange((prev) => {
                                      return prev + 1;
                                    });
                                    setCartEvent(true)
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    {
                      gift.length > 0 && 
                      gift.map((ele,index)=>{
                        return (

                          <tr key = {index} className="item_2">
                          <td className="img" style={{ width: "100px" }}>
                            <a href={`/p/${ele.SEOLink}`} title={ele.name ?? ""}>
                              <img
                                className="side-cart"
                                src={ele.img ? `${cloudFrontURL}/${ele.img}` :""}
                                alt="sidecart"
                              />
                            </a>
                          </td>
                          <td>
                            <div style={{ width: "100%" }}>
                              <a
                                className="pro-title-view"
                                style={{ width: "auto" }}
                                href={`/p/${ele.SEOLink}`}
                                title=""
                              >
                                {ele.name ?? ""}
                              </a>
                            </div>
                            <span className="variant">{`${ele.size??``} / ${ele?.colorArrayDesc??``}`}</span>
                            <span className="pro-quantity-view">
                              {ele.quantity ?? 0}
                            </span>
                            <span className ="pro-price-view">
                              {`Quà tặng`}
                            </span>
                          </td>
                        </tr> 
                        )
                      }) 
                    }
                  </tbody>
                </table>

                <span className="line"></span>

                <table className="table-total">
                  <tbody>
                    <tr>
                      <td className="text-left">TẠM TÍNH:</td>
                      <td className="text-right" id="total-view-cart">
                        {helper.moneyFormat(cartPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">CHƯƠNG TRÌNH:</td>
                      <td className="text-right" id="total-view-cart">
                        -{helper.moneyFormat(quantityDiscountValue+totalValueDiscountValue)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">TỔNG TIỀN:</td>
                      <td className="text-right" id="total-view-cart">
                        {helper.moneyFormat(cartPrice-(quantityDiscountValue+totalValueDiscountValue))}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="/cart" className ="linktocart buttonHol dark">
                          Xem giỏ hàng
                        </a>
                      </td>
                      <td>
                        <a
                          href="/checkout"
                          className ="linktocheckout buttonHol dark"
                        >
                          Thanh toán
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button
            className="site-close-handle"
            onClick={() => {
              setCartTab(false);
            }}
          >
            <Icon icon="material-symbols:close" />
          </button>
        </div>
        <div
          className={`site-overlay ${cartTab ? `active` : ``}`}
          onClick={() => {
            setCartTab(false);
          }}
        ></div>

        {/* search tab */}
        <div
          className={`site-nav style--sidebar ${
            searchTab ? `active` : ``
          } show-cart`}
        >
          <div id="site-cart" className="site-nav-container">
            <div className="site-nav-container-last">
              <p style={{ lineHeight: "21px" }} className="title">
                Tìm kiếm
              </p>
              <div className="search-box wpo-wrapper-search">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchValue !== "") {
                      window.location.replace(
                        `/all/p/p?search=${encodeURIComponent(searchValue)}`
                      );
                    }
                  }}
                  className="searchform searchform-categoris ultimate-search"
                >
                  <div className="wpo-search-inner">
                    <input
                      type="text"
                      required
                      id="inputSearchAuto"
                      name="search"
                      maxLength="40"
                      autoComplete="off"
                      className="searchinput input-search search-input"
                      size="20"
                      placeholder="Tìm kiếm sản phẩm..."
                      fdprocessedid="ayp92"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-search btn"
                    id="search-header-btn"
                  >
                    <Icon icon="iconamoon:search" />
                  </button>
                </form>
              </div>
            </div>
          </div>
          <button
            className="site-close-handle"
            onClick={() => {
              setSearchTab(false);
            }}
          >
            <Icon icon="material-symbols:close" />
          </button>
        </div>
        <div
          className={`site-overlay ${searchTab ? `active` : ``}`}
          onClick={() => {
            setSearchTab(false);
          }}
        ></div>

        {/* menu tab */}
        <div
          className={`site-nav style--sidebar ${
            menuTab ? `active` : ``
          } show-cart`}
        >
          <div id="site-cart" className="site-nav-container">
            <div id="site-cart" className="site-nav-container">
              <div
                className="site-nav-container-last"
                style={{ paddingLeft: "20px" }}
              >
                <p
                  style={{ lineHeight: "21px", paddingLeft: "50px" }}
                  className="title"
                >
                  Menu
                </p>
                <div className="cart-view clearfix">
                  <table id="cart-view">
                    <tbody>
                            <tr
                              className="item_2"
                              style={{ cursor: "pointer" }}
                            >
                            <ul className="nav navbar-nav" role="menu" itemScope itemType="http://schema.org/ItemList">
                              <li className="nav-item nav-top-item">
                                <div className="content-asset">
                                  <p>
                                    <a href="/shop-by-color"  style={{cursor:"auto"}}>
                                      <img
                                        style={{
                                          position: "relative",
                                          top: "5px",
                                          cursor:"pointer"
                                        }}
                                        width="246"
                                        height="50"
                                        alt="logo"
                                        src={shopByColor}
                                        title=""
                                      />
                                    </a>
                                  </p>
                                </div>
                              </li>
                            </ul>
                            </tr>
                      {sideMenu &&
                        sideMenu.map((ele, index) => {
                          return (
                            <tr
                              key={index}
                              className="item_2"
                              style={{ cursor: "pointer" }}
                            >

                              <div
                                className="small-menu-title1"
                                
                              >
                                <div
                                  onClick={()=>{ 
                                  }}
                                  >
                                    <a href = {`/${["vali", "balo", "Tui-xach", "Phu-kien"][index]}/p/p`}>

                                    {index === 0
                                    ? "Vali"
                                    : index === 1
                                    ? "Balo"
                                    : index === 2
                                    ? "Túi xách"
                                    : index === 3
                                    ? "Phụ kiện"
                                    : ""}
                                    </a>
                                </div>
                                <div
                                  onClick={() => {
                                    let newArray = [...sideMenu];
                                    newArray[index].drop = !newArray[index].drop;
                                    setSideMenu(newArray);
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "0",
                                    cursor: "pointer",
                                    transition: "transform 0.3s ease", // Thêm hiệu ứng chuyển động mượt mà
                                    transform:
                                      ele.drop === true
                                        ? "rotate(90deg)"
                                        : "rotate(0)", // Sử dụng CSS transform để xoay icon
                                  }}
                                >
                                  <Icon icon="ep:arrow-right" />
                                </div>
                              </div>
                              <div
                                className={`menu-1-show ${
                                  ele.drop === true ? "active" : ""
                                }`}
                              >
                                {ele.drop === true &&
                                  ele.menu &&
                                  ele.menu.map((ele1, index1) => {
                                    return (
                                      <div
                                        key={index1}
                                        style={{
                                          cursor: "pointer",
                                          paddingLeft: "20px",
                                        }}
                                        className={` item_2 inner1-menu`}
                                      >
                                        <div
                                          className="small-menu-title"
                                          onClick={() => {
                                            let newArray = [...sideMenu];
                                            newArray[index].menu[index1].drop =
                                              !newArray[index].menu[index1]
                                                .drop;
                                            setSideMenu(newArray);
                                          }}
                                        >
                                          <div>
                                            {ele.menu &&
                                              ele.menu[index1].typeOfDt}
                                          </div>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "0",
                                              cursor: "pointer",
                                              transition: "transform 0.3s ease", // Thêm hiệu ứng chuyển động mượt mà
                                              transform:
                                                ele.menu[index1].drop === true
                                                  ? "rotate(90deg)"
                                                  : "rotate(0)", // Sử dụng CSS transform để xoay icon
                                            }}
                                          >
                                            <Icon icon="ep:arrow-right" />
                                          </div>
                                          {ele.menu &&
                                            ele.menu[index1] &&
                                            ele.menu[index1].drop === true &&
                                            ele.menu[index1].arr.map(
                                              (ele2, index2) => {
                                                return (
                                                  ele2.active === true && (
                                                    <div
                                                      key={index2}
                                                      style={{
                                                        cursor: "pointer",
                                                        paddingLeft: "20px",
                                                      }}
                                                      className={` item_2 inner2-menu`}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <div
                                                        className="small-menu-title2"
                                                      >
                                                        <div>
                                                          <a
                                                            href={`/${["vali", "balo", "Tui-xach", "Phu-kien"][index]}${
                                                              Number(index) !==
                                                                0 ||
                                                              Number(index1) !==
                                                                0
                                                                ? `/${
                                                                    ele.menu[
                                                                      index1
                                                                    ].SEOLink
                                                                  }/${
                                                                    ele2.SEOLink
                                                                  }`
                                                                : `/vali/p/p&size=${index2}`
                                                            }`}
                                                          >
                                                            {ele2.field ?? ""}
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </tr>
                          );
                        })}
                        <tr className="item_2" style={{ cursor: "pointer" }}>
                          <div className="small-menu-title1" onClick={() => {}}>
                            <div>
                              <a
                                href={`/special-offers`}
                              >
                                Special Offers
                              </a>
                            </div>
                          </div>
                        </tr>
                      <tr className="item_2" style={{ cursor: "pointer" }}>
                        <div className="small-menu-title1" onClick={() => {}}>
                          <div>
                            <a
                              href={`/stores`}
                            >
                              Hệ thống cửa hàng
                            </a>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <button
            className="site-close-handle"
            onClick={() => {
              setMenuTab(false);
            }}
          >
            <Icon icon="material-symbols:close" />
          </button>
        </div>
        <div
          className={`site-overlay ${menuTab ? `active` : ``}`}
          onClick={() => {
            setMenuTab(false);
          }}
        ></div>
      </div>
      {drop && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "black",
            opacity: "0.3",
            zIndex: 200,
          }}
          onClick={() => {
            setCartTab(false);
            setMenuTab(false);
            setSearchTab(false);
          }}
          // onMouseEnter={()=>{
          //   setSearchTab(false)
          // }}
        ></div>
      )}

      <div
        style={{
          minHeight: "80px",
        }}
      ></div>

      <div
        className="global-noti"
        style={{
          minHeight: "25px",
        }}
      ></div>

    </React.Fragment>
  );
};

export default Header;
