import React, { useState, useEffect } from "react";

const Sitemap = ({ title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [xmlContent, setXmlContent] = useState(null);
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await fetch(`${apiURL}/${title}`);
        const data = await response.text();
        const contentType = response.headers.get("content-type");
        if (response.ok && contentType.includes("xml")) {
          // Lưu nội dung XML vào state
          setXmlContent(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sitemap:", error);
        setIsLoading(false);
      }
    };

    fetchSitemap();
  }, [apiURL, title]);

  useEffect(() => {
    // Xóa bỏ các phần tử HTML bên ngoài component này khi loading
    if (isLoading) {
      document.documentElement.innerHTML = "";
    } else {
      // Khi loading hoàn thành, hiển thị nội dung XML với các style
      document.documentElement.innerHTML = xmlContent;
    }
  }, [isLoading, xmlContent]);

  return null;
};

export default Sitemap;
