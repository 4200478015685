import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Autoplay } from "swiper";

//https://api.americantourister.vn/image/1706163828967.jpeg

SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation, Pagination]);

const NewsFeed = ({newsList}) => {
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [realIndex, setRealIndex] = useState(0)
  const newFeedArr = newsList.length > 0 ? (newsList
    ? newsList.length < 19
      ? Array(Math.ceil(19 / newsList.length))
          .fill(newsList)
          .flat()
      : newsList
    : []
  ) : []

  return (
    <React.Fragment>
      {newsList && newsList.length > 0 && newFeedArr.length > 0 && (
        <section
          className="section  section-product-list homepage  editorial-story"
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "50px",
            marginBottom : "40px", 
            overflow:"hidden" 
          }}
        >
          <div
            className="container-fluid post"
            style={{ display: "block"}}
          >
            {
              <div className="tab-content section-content">
                <Swiper
                  id="sliderHomeRec"
                  tag="section"
                  speed={1000}
                  onSlideChange={(swiper) => {
                    setRealIndex(swiper.realIndex);
                  }}
                  // autoplay={{
                  //   delay: 4001,
                  //   disableOnInteraction: false,
                  // }}
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  spaceBetween={0}
                  slidesPerView={19}
                  loop
                  initialSlide={0}
                >
                  {newFeedArr.map((ele, index) => {
                    const numberOfElements = newFeedArr.length;
                    var condition = index !== ((realIndex + 9) % numberOfElements)
                    return (
                      <SwiperSlide key={index} tag="li" className={`recSlider1 ${condition?`owl-item active center`:`owl-item`}`}>
                        <div
                          style={{
                            margin: 10,
                            cursor: "pointer",
                            overflow: "hidden", // Tránh hiển thị phần bị vượt ra nếu có
                          }}
                        >
                          {
                            <div
                              className={`dPush${
                                condition
                                  ? ""
                                  : "hide"
                              }`}
                            ></div>
                          }
                          <div
                            className={`dPush ${condition ? "" : "collapsed"}`}
                          ></div>
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                cursor: "pointer",
                                padding: 0,
                                width: "100%", // 100% chiều rộng của div cha
                                height: "100%", // 100% chiều cao của div cha
                                objectFit: "cover", // Đảm bảo ảnh không bị méo khi hiển thị
                                transition: "transform 0.3s",
                              }}
                              onClick={() => window.open(`/post/${ele?.seoLink ?? ""}`, "_self")}
                              className="recSliderImg"
                              alt="img"
                              src={
                                ele.mainImage
                                  ? `${cloudFrontURL}/${ele.mainImage}`
                                  : ""
                              }
                            />
                            {!condition && <div
                              className="fade_news_object"
                              style={{
                                position: "absolute",
                                top: "1.286rem",
                                left: "2.571rem",
                                fontSize : "2.8571rem", 
                                color : "#fff",
                                fontWeight : "800"
                              }}
                            >
                              {`#${ele?.count ?? 0}`}
                            </div>}
                            {!condition && <div
                              className="fade_news_object editory-info bottom active-none"
                              onClick={() => window.open(`/post/${ele?.seoLink ?? ""}`, "_self")}
                              style={{
                                position: "absolute",
                                color : "#fff",
                                fontWeight : "800"
                              }}
                            >
                              {`${ele?.name ?? ""}`}
                            </div>}
                            {!condition && <div
                              className="fade_news_object editory-info bottom-right active-none"
                              style={{
                                position: "absolute",
                                fontWeight : "900"
                              }}
                            >
                              <a href={`/post/${ele?.seoLink ?? ""}`} className="white-button gtm-click-tile-5">
                                More
                              </a>
                            </div>}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            }
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default NewsFeed;
