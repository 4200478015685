import React from "react";
import "../../styles/Support/support.css";

function PrecheckPolicy({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultReturn">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor: "black",
          }}
        >
          <h1>Chính sách kiểm hàng</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <p>
                      Trước khi nhận hàng và thanh toán, Quý Khách được quyền kiểm tra sản phẩm. Không hỗ trợ thử hàng.
                      </p>
                      <p>
                      Quý Khách vui lòng mở gói hàng kiểm tra để đảm bảo đơn hàng được giao đúng mẫu mã, số lượng như đơn hàng đã đặt. Không thử hay dùng thử sản phẩm.
                      </p>
                      <p>
                      Sau khi đồng ý với món hàng được giao đến, Quý Khách thanh toán với nhân viên giao hàng (trường hợp đơn hàng được ship COD) và nhận hàng.
                      </p>
                      <p>
                      Trường hợp Quý Khách không ưng ý với sản phẩm, Quý Khách có thể từ chối nhận hàng. Tại đây, chúng tôi sẽ thu thêm chi phí hoàn hàng, tương đương với phí ship của đơn hàng Quý khách đã đặt.
                      </p>
                      <p>Lưu ý:</p>
                      <ul>
                        <li>
                        Khi Quý Khách kiểm tra đơn hàng, nhân viên giao nhận buộc phải đợi Quý Khách kiểm tra hàng hóa bên trong gói hàng. Trường hợp nhân viên từ chối cho Quý Khách kiểm tra hàng hóa, Quý Khách vui lòng liên hệ với Americantourister.vn qua hotline: 18006360 để được hỗ trợ.
                        </li>
                        <li>
                        Americantourister.vn sẽ không chịu trách nhiệm về số lượng, mẫu mã cũng như lỗi của sản phẩm, sau khi đơn hàng đã được giao hàng thành công.
                        </li>
                        <li>
                        Quý Khách tránh dùng vật sắc nhọn để mở gói hàng để tránh gây hư hỏng cho sản phẩm bên trong. Đối với những trường hợp sản phẩm bị hư hỏng do lỗi từ phía Khách hàng,  americantourister.vn rất tiếc không thể hỗ trợ Quý Khách đổi/trả/bảo hành sản phẩm.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PrecheckPolicy;
