import React from "react";
import "../../styles/Support/support.css";

function ReportPolicy({title}) {
  document.title = title
  return (
    <React.Fragment>
      <div id="pageDefaultReturn">
        <section
          className="intro-section intro-block"
          style={{
            backgroundColor: "black",
          }}
        >
          <h1>Chính sách xử lý khiếu nại</h1>
        </section>
        <div
          className="pageAbout-us page-layout"
          style={{
            marginTop: "60px",
            marginBottom: "60px",
            fontSize: "15px",
            lineHeight: "1.6",
          }}
        >
          <div className="container">
            <div
              className="row wrapper-row pd-page"
              style={{ justifyContent: "center" }}
            >
              <div className="col-md-8 col-sm-12 col-12">
                <div className="page-wrapper">
                  <div className="wrapbox-content-page">
                    <div className="content-page ">
                      <p>
                        Tiếp nhận mọi khiếu nại của khách hàng liên quan đến
                        việc sử dụng dịch vụ của công ty.
                      </p>
                      <p>
                        Tất cả mọi trường hơp bảo hành, quý khách có thể liên hệ
                        với chúng tôi để làm thủ tục bảo hành.
                      </p>
                      <p>
                        Thời gian giải quyết khiếu nại trong thời hạn tối đa là
                        03 (ba) ngày làm việc kể từ khi nhận được khiếu nại của
                        của khách hàng. Trong trường hợp bất khả kháng 2 bên sẽ
                        tự thương lượng.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReportPolicy;
