


const moneyFormat = ((money)=>{
  if (money === 0) {
    return '0';
  } else {
    // Sử dụng Intl.NumberFormat để định dạng số và loại bỏ dấu cách không gian nở
    const formattedMoney = Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(money);
    
    return formattedMoney.trim().slice(0,-2)+'đ';
  } 
})
  
  const dateFormat = ((date)=>{
    // return new Date(date.toLocaleString("en-US", {timeZone: "Asia/Jakarta"})); 
    return new Date(date).toLocaleDateString("en-GB", "Asia/Jakarta") + ' ' + new Date(date).toLocaleTimeString("en-GB", "Asia/Jakarta")
  })

  const exportToExcel = ((file)=>{
    console.log(file)
  })

  function hasCommonElement(arr1, arr2) {
    // Sử dụng Set để lưu trữ các phần tử của mảng 1
    const set1 = new Set(arr1);
  
    // Lặp qua mảng 2 và kiểm tra xem có phần tử nào thuộc vào set1 hay không
    for (const element of arr2) {
      if (set1.has(element)) {
        return true; // Nếu tìm thấy phần tử chung, trả về true
      }
    }
  
    return false; // Nếu không tìm thấy phần tử chung, trả về false
  }
  
export default {
    moneyFormat,
    dateFormat,
    exportToExcel,
    hasCommonElement
  }